// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Stack$Pos from "../layout-and-structure/Stack.bs.js";
import * as Button$Pos from "../actions/Button.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as RoundButton$Pos from "../actions/RoundButton.bs.js";
import * as ShortIconButton$Pos from "../actions/ShortIconButton.bs.js";

function Menu(Props) {
  var children = Props.children;
  var variationOpt = Props.variation;
  var buttonTextOpt = Props.buttonText;
  var buttonVariationOpt = Props.buttonVariation;
  var buttonSizeOpt = Props.buttonSize;
  var buttonBorderedOpt = Props.buttonBordered;
  var disabledOpt = Props.disabled;
  var overlayPriorityOpt = Props.overlayPriority;
  var variation = variationOpt !== undefined ? variationOpt : "normal";
  var buttonText = buttonTextOpt !== undefined ? buttonTextOpt : Intl$Pos.t("Actions");
  var buttonVariation = buttonVariationOpt !== undefined ? buttonVariationOpt : "neutral";
  var buttonSize = buttonSizeOpt !== undefined ? buttonSizeOpt : "normal";
  var buttonBordered = buttonBorderedOpt !== undefined ? buttonBorderedOpt : true;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var overlayPriority = overlayPriorityOpt !== undefined ? overlayPriorityOpt : true;
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var triggerHovered = match$1[1];
  var onRequestClose = popover.close;
  var popover_isOpen = popover.isOpen;
  var popover_close = function (param) {
    if (!triggerHovered) {
      return Curry._1(onRequestClose, undefined);
    }
    
  };
  var popover_toggle = popover.toggle;
  var popover$1 = {
    isOpen: popover_isOpen,
    close: popover_close,
    toggle: popover_toggle
  };
  return React.createElement(React.Fragment, undefined, variation === "more_round" ? React.createElement(RoundButton$Pos.make, RoundButton$Pos.makeProps(popoverAriaProps.triggerProps, "more_x_bold", undefined, disabled, popover_isOpen, (function (param) {
                          Curry._1(popover_toggle, undefined);
                        }), undefined, popoverTriggerRef, undefined)) : (
                variation === "normal" ? React.createElement(Button$Pos.make, Button$Pos.makeProps(buttonText, buttonSize, buttonVariation, undefined, undefined, undefined, disabled, popover_isOpen, popover_isOpen ? "arrow_up_light" : "arrow_down_light", popoverAriaProps.triggerProps, (function (param) {
                              Curry._1(popover_toggle, undefined);
                            }), undefined, popoverTriggerRef, undefined)) : React.createElement(ShortIconButton$Pos.make, ShortIconButton$Pos.makeProps("more_y_light", undefined, popover_isOpen, buttonBordered, undefined, popoverAriaProps.triggerProps, {
                            TAG: /* Callback */0,
                            _0: popover_toggle
                          }, undefined, popoverTriggerRef, undefined))
              ), popover_isOpen ? React.createElement(Popover$Pos.make, {
                    children: React.createElement(Popover$Pos.Dialog.make, {
                          children: variation === "normal" ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                                              children: children,
                                              space: "none"
                                            })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                                              children: children,
                                              space: "xxsmall"
                                            })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                          ariaProps: popoverAriaProps.overlayProps
                        }),
                    triggerRef: popoverTriggerRef,
                    state: popover$1,
                    variation: variation === "more_round" ? "arrowed" : "normal",
                    placement: "bottom end",
                    overlayPriority: overlayPriority,
                    dismissable: overlayPriority,
                    modal: false,
                    offset: variation === "more_square" ? (
                        buttonBordered ? 4 : -4
                      ) : (
                        variation === "more_round" ? 10 : 4
                      ),
                    crossOffset: variation === "more_square" ? (
                        buttonBordered ? 0 : -12
                      ) : 0
                  }) : null);
}

var make = React.memo(Menu);

export {
  make ,
}
/* make Not a pure module */
