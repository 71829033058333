// Generated by ReScript, PLEASE EDIT WITH CARE


var values = [
  /* Excel */0,
  /* IsaCompta */1
];

function toLabel(value) {
  if (value) {
    return "IsaCompta";
  } else {
    return "Excel";
  }
}

function toLowercaseString(value) {
  if (value) {
    return "isacompta";
  } else {
    return "excel";
  }
}

function fromString(value) {
  switch (value) {
    case "Excel" :
    case "excel" :
        return /* Excel */0;
    case "IsaCompta" :
    case "isacompta" :
        return /* IsaCompta */1;
    default:
      return ;
  }
}

function toSelectItem(value) {
  return {
          key: value ? "isacompta" : "excel",
          value: value,
          label: value ? "IsaCompta" : "Excel"
        };
}

function isExcel(value) {
  if (value) {
    return false;
  } else {
    return true;
  }
}

function isIsaCompta(value) {
  if (value) {
    return true;
  } else {
    return false;
  }
}

export {
  values ,
  toLabel ,
  toLowercaseString ,
  fromString ,
  toSelectItem ,
  isExcel ,
  isIsaCompta ,
}
/* No side effect */
