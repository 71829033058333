// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ButtonPhased$Pos from "../../resources/actions/ButtonPhased.bs.js";
import * as PriceRetailTableCell$Pos from "../Price/PriceRetailTableCell.bs.js";
import * as PriceRetailTableEditCell$Pos from "../Price/PriceRetailTableEditCell.bs.js";

function CatalogVariantPriceRetailTableEditCellWrapper(Props) {
  var disabledOpt = Props.disabled;
  var variantPrice = Props.variantPrice;
  var dispatch = Props.onRequestDispatch;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var onFocus = function (param) {
    Curry._1(setFocused, (function (param) {
            return true;
          }));
  };
  var onBlur = function (param) {
    Curry._1(setFocused, (function (param) {
            return false;
          }));
  };
  var match$1 = Hover$Pos.use(undefined, undefined);
  var onChange = React.useCallback((function (price) {
          Curry._1(dispatch, {
                TAG: /* RetailPriceUpdated */0,
                _0: {
                  id: variantPrice.id,
                  variantId: variantPrice.variantId,
                  priceId: variantPrice.priceId
                },
                _1: price
              });
        }), [
        variantPrice.variantId,
        variantPrice.id
      ]);
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(match[0] ? React.createElement(PriceRetailTableEditCell$Pos.make, {
                                        value: variantPrice.value,
                                        secondaryValue: variantPrice.secondaryValue,
                                        purchasePrice: variantPrice.purchasePrice,
                                        bulkUnit: variantPrice.capacityUnit,
                                        autoFocused: true,
                                        shrinkInput: true,
                                        onChange: onChange,
                                        onFocus: onFocus,
                                        onBlur: onBlur
                                      }) : React.createElement(ButtonPhased$Pos.make, ButtonPhased$Pos.makeProps(React.createElement(PriceRetailTableCell$Pos.make, {
                                                value: variantPrice.value,
                                                secondaryValue: variantPrice.secondaryValue,
                                                capacityUnit: variantPrice.capacityUnit
                                              }), undefined, match$1[1], undefined, undefined, undefined, disabled, (function (param) {
                                              Curry._1(setFocused, (function (param) {
                                                      return true;
                                                    }));
                                            }), undefined, undefined, undefined))), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-13)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, match$1[0], undefined));
}

var make = React.memo(CatalogVariantPriceRetailTableEditCellWrapper, (function (oldProps, newProps) {
        if (Caml_obj.equal(oldProps.variantPrice.value, newProps.variantPrice.value) && oldProps.variantPrice.id === newProps.variantPrice.id && oldProps.variantPrice.purchasePrice === newProps.variantPrice.purchasePrice) {
          return oldProps.disabled === newProps.disabled;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
