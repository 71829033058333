// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextLink$Pos from "../../resources/navigation/TextLink.bs.js";
import * as InfoBlock$Pos from "../../resources/layout-and-structure/InfoBlock.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as OrderEditForm$Pos from "./OrderEditForm.bs.js";
import * as SupplierRouter$Pos from "../../app/Supplier/SupplierRouter.bs.js";
import * as SupplierSelect$Pos from "../Supplier/SupplierSelect.bs.js";
import * as OrderSupplierInformationCard__Config$Pos from "./OrderSupplierInformationCard__Config.bs.js";
import * as OrderSupplierInformationCard__Queries$Pos from "./OrderSupplierInformationCard__Queries.bs.js";

var SupplierNotFound = /* @__PURE__ */Caml_exceptions.create("OrderSupplierInformationCard-Pos.SupplierNotFound");

var make = React.memo(function (Props) {
      var dispatch = Curry._1(OrderEditForm$Pos.useFormDispatch, undefined);
      var match = Curry._1(OrderEditForm$Pos.useFormState, undefined);
      var values = match.values;
      var supplierName = values.supplierName;
      var supplierId = values.supplierId;
      var validation = match.validation;
      var archivedSupplierNotification = supplierId === "" && supplierName !== "";
      var errorMessage;
      if (validation.TAG === /* Ok */0) {
        errorMessage = undefined;
      } else {
        var tmp = match.submission;
        errorMessage = typeof tmp === "number" || tmp.TAG !== /* Failed */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                      if (param[0]._0 !== 2) {
                        return ;
                      } else {
                        return param[1];
                      }
                    })), 0);
      }
      var value = supplierId !== "" ? ({
            id: supplierId,
            name: supplierName
          }) : undefined;
      var onChange = React.useCallback((function (value) {
              if (value !== undefined) {
                Curry._1(dispatch, {
                      TAG: /* FieldValueChanged */2,
                      _0: /* SupplierId */2,
                      _1: (function (param) {
                          return value.id;
                        })
                    });
                return Curry._1(dispatch, {
                            TAG: /* FieldValueChanged */2,
                            _0: /* SupplierName */9,
                            _1: (function (param) {
                                return value.name;
                              })
                          });
              } else {
                return Curry._1(dispatch, {
                            TAG: /* FieldValueChanged */2,
                            _0: /* SupplierId */2,
                            _1: (function (param) {
                                return "";
                              })
                          });
              }
            }), []);
      return React.createElement(Stack$Pos.make, {
                  children: null,
                  space: "normal"
                }, React.createElement(SupplierSelect$Pos.make, {
                      label: Intl$Pos.t("Supplier"),
                      preset: {
                        NAME: "inputField",
                        VAL: {
                          required: true,
                          errorMessage: errorMessage
                        }
                      },
                      shopId: values.shopId,
                      value: value,
                      onChange: onChange
                    }), archivedSupplierNotification ? React.createElement(Banner$Pos.make, {
                        textStatus: {
                          TAG: /* Warning */2,
                          _0: Intl$Pos.t("The previously selected supplier cannot be found (archived).")
                        }
                      }) : null);
    });

var OrderSupplierFormInputSearch = {
  make: make
};

function OrderSupplierInformationCard(Props) {
  var editable = Props.editable;
  var match = Curry._1(OrderEditForm$Pos.useFormState, undefined).values;
  var supplierId = match.supplierId;
  var asyncQueryResult = ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(OrderSupplierInformationCard__Queries$Pos.SupplierQuery.use, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            supplierId === "",
            undefined,
            {
              id: supplierId
            }
          ]));
  var selectedSupplierId;
  if (typeof asyncQueryResult === "number" || asyncQueryResult.TAG !== /* Done */1) {
    selectedSupplierId = supplierId;
  } else {
    var match$1 = asyncQueryResult._0;
    if (match$1.TAG === /* Ok */0) {
      var match$2 = match$1._0.supplier;
      if (match$2 !== undefined) {
        var id = match$2.id;
        selectedSupplierId = id !== "" ? id : supplierId;
      } else {
        selectedSupplierId = supplierId;
      }
    } else {
      selectedSupplierId = supplierId;
    }
  }
  var tmp = selectedSupplierId === "" ? undefined : ({
        icon: "queue_arrow_right_light",
        title: Intl$Pos.t("See supplier"),
        handler: {
          TAG: /* OpenLinkNewTab */2,
          _0: {
            TAG: /* Route */0,
            _0: SupplierRouter$Pos.showRoute(selectedSupplierId)
          }
        }
      });
  var tmp$1 = {
    children: null,
    title: Intl$Pos.t("Supplier information")
  };
  if (tmp !== undefined) {
    tmp$1.action = Caml_option.valFromOption(tmp);
  }
  var tmp$2;
  var exit = 0;
  var data;
  if (match.shopId === "") {
    tmp$2 = React.createElement(Banner$Pos.make, {
          textStatus: {
            TAG: /* Info */3,
            _0: Intl$Pos.t("Please first select the shop below in order to pick a supplier.")
          }
        });
  } else if (typeof asyncQueryResult === "number") {
    if (asyncQueryResult === /* NotAsked */0) {
      if (editable) {
        tmp$2 = React.createElement(make, {});
      } else {
        exit = 1;
      }
    } else {
      tmp$2 = React.createElement(Placeholder$Pos.make, {
            status: /* Loading */0
          });
    }
  } else if (asyncQueryResult.TAG === /* Reloading */0) {
    var data$1 = asyncQueryResult._0;
    if (data$1.TAG === /* Ok */0) {
      data = data$1._0;
      exit = 2;
    } else {
      exit = 1;
    }
  } else {
    var data$2 = asyncQueryResult._0;
    if (data$2.TAG === /* Ok */0) {
      data = data$2._0;
      exit = 2;
    } else {
      exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        tmp$2 = React.createElement(Placeholder$Pos.make, {
              status: /* Error */2
            });
        break;
    case 2 :
        var supplier = data.supplier;
        if (supplier !== undefined) {
          var tmp$3 = {
            children: null
          };
          var tmp$4 = editable ? "xxsmall" : undefined;
          if (tmp$4 !== undefined) {
            tmp$3.space = Caml_option.valFromOption(tmp$4);
          }
          var match$3 = supplier.email;
          var match$4 = supplier.phoneNumber;
          var match$5 = supplier.mobileNumber;
          var tmp$5;
          var exit$1 = 0;
          if (match$3 !== undefined || match$4 !== undefined || match$5 !== undefined || !editable) {
            exit$1 = 3;
          } else {
            tmp$5 = null;
          }
          if (exit$1 === 3) {
            var email = supplier.email;
            var phoneNumber = supplier.phoneNumber;
            var mobileNumber = supplier.mobileNumber;
            tmp$5 = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                              children: null,
                              space: "xsmall"
                            }, editable ? null : React.createElement(TextLink$Pos.make, {
                                    text: supplier.companyName,
                                    to: {
                                      TAG: /* Route */0,
                                      _0: SupplierRouter$Pos.showRoute(supplier.id)
                                    },
                                    openNewTab: true
                                  }), email !== undefined ? React.createElement(TextStyle$Pos.make, {
                                    children: email
                                  }) : null, phoneNumber !== undefined ? React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.phoneNumberFormat(phoneNumber)
                                  }) : null, mobileNumber !== undefined ? React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.phoneNumberFormat(mobileNumber)
                                  }) : null)), undefined, undefined, editable ? "medium" : undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
          }
          tmp$2 = React.createElement(Stack$Pos.make, tmp$3, editable ? React.createElement(make, {}) : null, tmp$5);
        } else {
          throw {
                RE_EXN_ID: SupplierNotFound,
                Error: new Error()
              };
        }
        break;
    
  }
  var tmp$6;
  if (typeof asyncQueryResult === "number" || asyncQueryResult.TAG !== /* Done */1) {
    tmp$6 = null;
  } else {
    var match$6 = asyncQueryResult._0;
    if (match$6.TAG === /* Ok */0) {
      var supplier$1 = match$6._0.supplier;
      if (supplier$1 !== undefined) {
        var contact = OrderSupplierInformationCard__Config$Pos.supplierContactFromData(supplier$1);
        var tmp$7;
        if (contact !== undefined) {
          var email$1 = contact.email;
          var phoneNumber$1 = contact.phoneNumber;
          var mobileNumber$1 = contact.mobileNumber;
          tmp$7 = React.createElement(Stack$Pos.make, {
                children: null
              }, React.createElement(TextStyle$Pos.make, {
                    children: contact.formattedName
                  }), email$1 !== undefined ? React.createElement(TextStyle$Pos.make, {
                      children: email$1
                    }) : null, phoneNumber$1 !== undefined ? React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.phoneNumberFormat(phoneNumber$1)
                    }) : null, mobileNumber$1 !== undefined ? React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.phoneNumberFormat(mobileNumber$1)
                    }) : null);
        } else {
          tmp$7 = React.createElement(TextStyle$Pos.make, {
                children: Intl$Pos.t("Contact information not specified"),
                variation: "subdued"
              });
        }
        var $$location = OrderSupplierInformationCard__Config$Pos.supplierLocationFromData(supplier$1);
        var tmp$8;
        if ($$location !== undefined) {
          var recipient = $$location.recipient;
          var address = $$location.address;
          var formattedCity = $$location.formattedCity;
          tmp$8 = React.createElement(Stack$Pos.make, {
                children: null
              }, recipient !== undefined ? React.createElement(TextStyle$Pos.make, {
                      children: recipient
                    }) : null, address !== undefined ? React.createElement(TextStyle$Pos.make, {
                      children: address
                    }) : null, formattedCity !== undefined ? React.createElement(TextStyle$Pos.make, {
                      children: formattedCity
                    }) : null, React.createElement(TextStyle$Pos.make, {
                    children: $$location.country
                  }));
        } else {
          tmp$8 = React.createElement(TextStyle$Pos.make, {
                children: Intl$Pos.t("Address not specified"),
                variation: "subdued"
              });
        }
        tmp$6 = React.createElement(Stack$Pos.make, {
              children: null,
              space: "xlarge"
            }, React.createElement(InfoBlock$Pos.make, {
                  children: tmp$7,
                  title: Intl$Pos.t("CONTACT")
                }), React.createElement(InfoBlock$Pos.make, {
                  children: tmp$8,
                  title: Intl$Pos.t("ADDRESS")
                }));
      } else {
        tmp$6 = null;
      }
    } else {
      tmp$6 = null;
    }
  }
  return React.createElement(Card$Pos.make, tmp$1, tmp$2, tmp$6);
}

var make$1 = React.memo(OrderSupplierInformationCard);

var Config;

var SupplierQuery;

export {
  Config ,
  SupplierQuery ,
  SupplierNotFound ,
  OrderSupplierFormInputSearch ,
  make$1 as make,
}
/* make Not a pure module */
