// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Kbd$Pos from "./Kbd.bs.js";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "./TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as InlineText$Pos from "../layout-and-structure/InlineText.bs.js";

var $$window = window;

function KbdClipboard(Props) {
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var cropValueAtOpt = Props.cropValueAt;
  var value = Props.value;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var cropValueAt = cropValueAtOpt !== undefined ? cropValueAtOpt : 6;
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setCopy = match$1[1];
  var copy = match$1[0];
  React.useEffect((function () {
          if (copy === undefined) {
            return ;
          }
          var clipboard = $$window.navigator.clipboard;
          clipboard.writeText(copy);
          var timer = setTimeout((function (param) {
                  Curry._1(setCopy, (function (param) {
                          
                        }));
                }), 1400);
          return (function (param) {
                    clearTimeout(timer);
                  });
        }), [copy]);
  var tmp;
  tmp = label !== undefined && label !== "" ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                      children: label,
                      variation: "normal"
                    })), undefined, undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined)) : null;
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(InlineText$Pos.make, {
                                  children: null,
                                  linespace: "small"
                                }, tmp, cropValueAt > 0 ? React.createElement(Kbd$Pos.make, {
                                        children: Js_string.slice(0, cropValueAt, value) + "...",
                                        hovered: hovered
                                      }) : null, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(copy !== undefined ? React.createElement(Icon$Pos.make, {
                                                    name: "tick_bold",
                                                    fill: Colors$Pos.brandColor60,
                                                    size: 16
                                                  }) : React.createElement(Icon$Pos.make, {
                                                    name: "clipboard",
                                                    fill: hovered && !disabled ? Colors$Pos.brandColor60 : "#797885",
                                                    size: 16
                                                  })), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-2)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)))), undefined, undefined, match[0], undefined)), undefined, disabled, undefined, undefined, undefined, (function (param) {
                    Curry._1(setCopy, (function (param) {
                            return value;
                          }));
                  }), undefined, undefined, undefined));
}

var make = React.memo(KbdClipboard);

export {
  make ,
}
/* window Not a pure module */
