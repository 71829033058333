// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Tag$Pos from "../../resources/feedback-indicators/Tag.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Image$Pos from "../../primitives/Image.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as SessionTracker$Pos from "../../core/SessionTracker.bs.js";
import * as RequestOpenStorageUrlButton$Pos from "../../resources/actions/RequestOpenStorageUrlButton.bs.js";
import Customer_empty_placeholderPng from "./customer_empty_placeholder.png";

var imageUri = Customer_empty_placeholderPng;

var styles = StyleSheet$Pos.create({
      image: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(225)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(300)), undefined, undefined),
      tags: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.medium)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      action: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.medium)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

var endpoint = Env$Pos.sheetUrl(undefined) + "/customers";

function encodeRequestBodyJson(shopIds, param) {
  return Json$Pos.encodeDict(Js_dict.fromArray([[
                    "shopIds",
                    Json$Pos.encodeArray(Belt_Array.map(shopIds, Json$Pos.encodeString))
                  ]]));
}

function make(shopIds) {
  return Request$Pos.make("POST", Caml_option.some(encodeRequestBodyJson(shopIds, undefined)), undefined, undefined, endpoint);
}

var CustomersExportRequest = {
  endpoint: endpoint,
  encodeRequestBodyJson: encodeRequestBodyJson,
  make: make
};

function CustomerListPage$CustomersExportButton(Props) {
  var shopIds = Props.shopIds;
  var text = Props.text;
  var request = Props.request;
  var onNotification = Props.onNotification;
  var captureEvent = SessionTracker$Pos.useCaptureEvent(undefined);
  var request$1 = function (param) {
    Curry._1(captureEvent, "download_customer_export_file");
    return Curry._1(request, shopIds);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlButton$Pos.failureErrorToString(error, Intl$Pos.t("customers"));
    Curry._1(onNotification, {
          TAG: /* Danger */1,
          _0: errorMessage
        });
  };
  var onSuccess = function (param) {
    Curry._1(onNotification, {
          TAG: /* Success */0,
          _0: Intl$Pos.t("The file has been downloaded successfully.")
        });
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request$1
  };
  return React.createElement(RequestOpenStorageUrlButton$Pos.make, {
              text: text,
              operableRequest: operableRequest,
              onFailure: onFailure,
              onSuccess: onSuccess
            });
}

var CustomersExportButton = {
  make: CustomerListPage$CustomersExportButton
};

function CustomerListPage(Props) {
  var request = Props.request;
  var scope = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var tmp;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    tmp = shop !== undefined ? [shop] : scope.shops;
  } else {
    tmp = [scope._0];
  }
  var shopIds = Belt_Array.map(tmp, (function (shop) {
          return shop.id;
        }));
  var shopName;
  if (scope.TAG === /* Organisation */0) {
    var shop$1 = scope.activeShop;
    shopName = shop$1 !== undefined ? shop$1.name : undefined;
  } else {
    shopName = undefined;
  }
  var match = React.useState(function () {
        
      });
  var setNotfication = match[1];
  var notification = match[0];
  var onNotification = function (message) {
    Curry._1(setNotfication, (function (param) {
            return message;
          }));
  };
  var renderHeaderActions = function (param) {
    if (organisationAccount) {
      return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                              children: React.createElement(Auth$Pos.SelectShopFilter.make, {}),
                              space: "small"
                            })), undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    } else {
      return React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, undefined, undefined, "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    }
  };
  return React.createElement(Page$Pos.make, {
              children: null,
              variation: "compact",
              title: Intl$Pos.t("Customers"),
              renderHeaderActions: renderHeaderActions
            }, notification !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                textStatus: notification
                              })), undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Card$Pos.make, {
                  children: null,
                  stretch: true,
                  centerContent: true
                }, React.createElement(Image$Pos.make, {
                      style: styles.image,
                      source: Image$Pos.fromUriSource(Image$Pos.uriSource(imageUri))
                    }), React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "small"
                    }, React.createElement(Title$Pos.make, {
                          children: Intl$Pos.t("The display of customer contacts is not yet available."),
                          level: 2,
                          align: "center"
                        }), React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("Meanwhile, you can still export them."),
                          align: "center"
                        }), React.createElement(Stack$Pos.make, {
                          children: null,
                          space: "small"
                        }, shopName !== undefined ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                            children: React.createElement(Tag$Pos.make, {
                                                  children: shopName
                                                }),
                                            space: "small"
                                          })), Caml_option.some(styles.tags), undefined, undefined, undefined)) : null, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(CustomerListPage$CustomersExportButton, {
                                          shopIds: shopIds,
                                          text: Intl$Pos.t("Export customer file"),
                                          request: request,
                                          onNotification: onNotification
                                        })), Caml_option.some(styles.action), undefined, undefined, undefined))))));
}

var make$1 = CustomerListPage;

export {
  imageUri ,
  styles ,
  CustomersExportRequest ,
  CustomersExportButton ,
  make$1 as make,
}
/* imageUri Not a pure module */
