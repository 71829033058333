// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as TooltipIcon$Pos from "../overlays/TooltipIcon.bs.js";

var styles = StyleSheet$Pos.create({
      normalText: FontFaces$Pos.libreFranklinRegularStyle,
      importantText: FontFaces$Pos.archivoSemiBoldStyle,
      lightText: Style$Pos.style(undefined, undefined, undefined, "#d7d7e0", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      grayText: Style$Pos.style(undefined, undefined, undefined, "#07061e", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      darkText: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.brandColor60, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function inlineSpaceFromParams(size) {
  if (size === "small" || size === "xsmall" || size === "xxsmall" || size === "tiny") {
    return "xsmall";
  } else {
    return "small";
  }
}

function iconSizeFromParams(size) {
  if (size === "xsmall" || size === "xxsmall" || size === "tiny") {
    return 16;
  } else if (size === "small") {
    return 18;
  } else if (size === "normal") {
    return 20;
  } else {
    return 20;
  }
}

function iconColorFromParams(disabled, hovered) {
  if (disabled) {
    return "#d7d7e0";
  } else if (hovered) {
    return Colors$Pos.brandColor60;
  } else {
    return "#07061e";
  }
}

function textStyleFromParams(variation, size, disabled, hovered) {
  return Style$Pos.arrayStyle([
              variation === "normal" ? styles.normalText : styles.importantText,
              size === "xsmall" || size === "xxsmall" || size === "tiny" ? FontSizes$Pos.styleFromSize("xsmall") : (
                  size === "small" ? FontSizes$Pos.styleFromSize("small") : FontSizes$Pos.styleFromSize("large")
                ),
              disabled ? styles.lightText : (
                  hovered ? styles.darkText : styles.grayText
                )
            ]);
}

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var variationOpt = Props.variation;
      var sizeOpt = Props.size;
      var disabledOpt = Props.disabled;
      var icon = Props.icon;
      var textTooltip = Props.textTooltip;
      var onPress = Props.onPress;
      var variation = variationOpt !== undefined ? variationOpt : "important";
      var size = sizeOpt !== undefined ? sizeOpt : "normal";
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var match = Hover$Pos.use((ref == null) ? undefined : Caml_option.some(ref), undefined);
      var hovered = match[1];
      var ref$1 = match[0];
      return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(Inline$Pos.make, {
                          children: null,
                          space: inlineSpaceFromParams(size)
                        }, React.createElement(Icon$Pos.make, {
                              name: icon,
                              fill: iconColorFromParams(disabled, hovered),
                              size: iconSizeFromParams(size)
                            }), React.createElement(Text$Pos.make, Text$Pos.makeProps(children, Caml_option.some(textStyleFromParams(variation, size, disabled, hovered)), undefined, undefined, undefined)), textTooltip !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TooltipIcon$Pos.make, {
                                            children: textTooltip,
                                            variation: "info",
                                            altTriggerRef: ref$1
                                          })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null), undefined, disabled, undefined, undefined, undefined, onPress, undefined, ref$1, undefined));
    });

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  var tmp = {
    children: prim0,
    icon: prim4,
    onPress: prim6
  };
  if (prim1 !== undefined) {
    tmp.variation = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.size = Caml_option.valFromOption(prim2);
  }
  if (prim3 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim3);
  }
  if (prim5 !== undefined) {
    tmp.textTooltip = Caml_option.valFromOption(prim5);
  }
  if (prim7 !== undefined) {
    tmp.key = prim7;
  }
  if (prim8 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim8);
  }
  return tmp;
}

export {
  makeProps ,
  make ,
}
/* styles Not a pure module */
