// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Svg$Pos from "../../primitives/Svg.bs.js";

var error = React.createElement(Svg$Pos.make, {
      children: null,
      width: "32",
      height: "32",
      viewBox: "0 0 52 52"
    }, React.createElement(Svg$Pos.Path.make, {
          d: "M44 41a1 1 0 01.117 1.993L44 43H6a1 1 0 01-.117-1.993L6 41h38zm-.896-13a1 1 0 01.116 1.993l-.116.007H6a1 1 0 01-.117-1.993L6 28h37.104zM25.5 15a1 1 0 01.117 1.993L25.5 17H6a1 1 0 01-.117-1.993L6 15h19.5z",
          fill: "#25243a"
        }), React.createElement(Svg$Pos.Path.make, {
          d: "M31.799 9.777a1 1 0 011.414 0l4.598 4.597 4.598-4.597a1 1 0 011.32-.084l.094.084a1 1 0 010 1.414l-4.598 4.598 4.598 4.598a1 1 0 01.084 1.32l-.084.094a1 1 0 01-1.414 0l-4.598-4.598-4.598 4.598a1 1 0 01-1.32.083l-.094-.083a1 1 0 010-1.414l4.597-4.598-4.597-4.598a1 1 0 01-.083-1.32z",
          fill: "#647ccc"
        }));

var create = React.createElement(Svg$Pos.make, {
      children: null,
      width: "32",
      height: "32",
      viewBox: "0 0 52 52"
    }, React.createElement(Svg$Pos.Path.make, {
          d: "M28.75 16a1 1 0 01.117 1.993L28.75 18H17.5v7.025a1 1 0 01-.883.993l-.117.007h-7V48h24V25.011a1 1 0 01.883-.993l.117-.007a1 1 0 01.993.884l.007.116V49a1 1 0 01-.883.993L34.5 50h-26a1 1 0 01-.993-.883L7.5 49V25.011a1 1 0 01.206-.607l.086-.1 7.992-8.003c.032-.032.066-.062.102-.09l-.094.082a1 1 0 01.427-.253l.031-.008a.923.923 0 01.093-.02l.025-.003a.566.566 0 01.015-.002L16.5 16h12.25zm0 23a1 1 0 01.117 1.993L28.75 41H25a1 1 0 01-.117-1.993L25 39h3.75zm0-6.5a1 1 0 01.117 1.993l-.117.007h-14a1 1 0 01-.117-1.993l.117-.007h14zM15.5 19.415l-4.603 4.61H15.5v-4.61z",
          fill: "#25243a"
        }), React.createElement(Svg$Pos.Path.make, {
          d: "M36.5 3a1 1 0 01.993.883L37.5 4v6.5H44a1 1 0 01.117 1.993L44 12.5h-6.5V19a1 1 0 01-1.993.117L35.5 19v-6.5H29a1 1 0 01-.117-1.993L29 10.5h6.5V4a1 1 0 011-1z",
          fill: "#647ccc"
        }));

var notFound = React.createElement(Svg$Pos.make, {
      children: null,
      width: "32",
      height: "32",
      viewBox: "0 0 52 52"
    }, React.createElement(Svg$Pos.Path.make, {
          d: "M23.5 6C33.165 6 41 13.835 41 23.5c0 4.473-1.678 8.553-4.439 11.647l7.771 7.77a1 1 0 01-1.32 1.498l-.094-.083-7.77-7.77A17.435 17.435 0 0123.5 41C13.835 41 6 33.165 6 23.5S13.835 6 23.5 6zm0 2C14.94 8 8 14.94 8 23.5 8 32.06 14.94 39 23.5 39 32.06 39 39 32.06 39 23.5 39 14.94 32.06 8 23.5 8z",
          fill: "#25243a"
        }), React.createElement(Svg$Pos.Path.make, {
          d: "M17.299 17.277a1 1 0 011.414 0l4.598 4.597 4.598-4.597a1 1 0 011.32-.084l.094.084a1 1 0 010 1.414l-4.598 4.598 4.598 4.598a1 1 0 01.084 1.32l-.084.094a1 1 0 01-1.414 0l-4.598-4.598-4.598 4.598a1 1 0 01-1.32.083l-.094-.083a1 1 0 010-1.414l4.597-4.598-4.597-4.598a1 1 0 01-.083-1.32z",
          fill: "#647ccc"
        }));

var notAsked = React.createElement(Svg$Pos.make, {
      children: null,
      width: "95",
      height: "32",
      viewBox: "0 0 95 52"
    }, React.createElement(Svg$Pos.Path.make, {
          d: "M89 15a5 5 0 014.995 4.783L94 20v12a5 5 0 01-4.783 4.995L89 37h-8.103l2.723 2.433a1 1 0 01-.062 1.542l-.105.07-1.682.97 2.432 3.665a1 1 0 01-.146 1.28l-.092.077-.095.062-4.053 2.34a1 1 0 01-1.421-.478l-.04-.113-.08-.271-1.738-3.541-1.756 1.015a1 1 0 01-1.406-.441l-.042-.107-.03-.114L71.552 37H6a5 5 0 01-4.995-4.783L1 32V20a5 5 0 014.783-4.995L6 15h83zM72.612 32.28l2.367 11.348 1.486-.858a1 1 0 011.27.228l.071.097.056.1L79.8 47.14l2.148-1.24-2.431-3.666a1 1 0 01.146-1.28l.092-.077.095-.062 1.406-.812-8.644-7.722zM89 17H6a3 3 0 00-2.995 2.824L3 20v12a3 3 0 002.824 2.995L6 35h65.136l-1.101-5.285c-.19-.908.841-1.542 1.56-1.018l.085.068L78.658 35H89a3 3 0 002.995-2.824L92 32V20a3 3 0 00-2.824-2.995L89 17z",
          fill: "#25243a"
        }), React.createElement(Svg$Pos.Path.make, {
          d: "M47.5 25.5a1 1 0 01.117 1.993l-.117.007h-37a1 1 0 01-.117-1.993l.117-.007h37z",
          fill: "#647ccc"
        }));

var shopMissing = React.createElement(Svg$Pos.make, {
      children: null,
      width: "32",
      height: "32",
      viewBox: "0 0 52 52"
    }, React.createElement(Svg$Pos.Path.make, {
          d: "M39.133 13a2 2 0 011.98 1.717l1.711 11.972.048.185c.064.275.104.556.12.84L43 28a5.001 5.001 0 01-3 4.584V48a1 1 0 01-.883.993L39 49H10a1 1 0 01-.993-.883L9 48V32.9A5.002 5.002 0 015.2 26.592l-.024.092 1.71-11.967a2 2 0 011.824-1.711L8.867 13zm-4.634 18.569l-.123.12a4.987 4.987 0 01-3.137 1.305L31 33a4.992 4.992 0 01-3.376-1.311l-.125-.12-.123.12a4.987 4.987 0 01-3.137 1.305L24 33a4.992 4.992 0 01-3.376-1.311l-.125-.12-.123.12a4.987 4.987 0 01-3.137 1.305L17 33a4.992 4.992 0 01-3.376-1.311l-.125-.12-.123.12A4.988 4.988 0 0111 32.898L11 47h7v-5.5a6.5 6.5 0 016.267-6.496L24.5 35a6.5 6.5 0 016.5 6.5V47h7V33a4.992 4.992 0 01-3.376-1.311l-.125-.12zM23.5 37.111A4.502 4.502 0 0020 41.5V47h3.5zm2.001 0L25.5 47H29v-5.5a4.502 4.502 0 00-3.499-4.388zM13.458 15h-4.59L7.15 27.015l-.03.14a3 3 0 005.374 2.51l.963-14.665zm6.042 0h-4.038l-.964 14.656a2.997 2.997 0 002.296 1.337L17 31a2.99 2.99 0 002.5-1.345V15zm7 0h-5v14.658a2.997 2.997 0 002.294 1.335L24 31a2.99 2.99 0 002.5-1.345V15zm6.037 0H28.5v14.658a2.997 2.997 0 002.294 1.335L31 31a2.99 2.99 0 002.502-1.347L32.537 15zm6.595 0h-4.591l.964 14.668a2.997 2.997 0 002.29 1.325L38 31a3 3 0 002.881-3.84l-.03-.138L39.132 15z",
          fill: "#25243a"
        }), React.createElement(Svg$Pos.Path.make, {
          d: "M49.5 13.824a1 1 0 01.117 1.993l-.117.007h-4.125a1 1 0 01-.117-1.993l.117-.007H49.5zm-2.043-7.27a1 1 0 01.083 1.32l-.083.094-2.75 2.75a1 1 0 01-1.497-1.32l.083-.094 2.75-2.75a1 1 0 011.414 0zm-8.27-3.084a1 1 0 01.994.883l.007.117v4.165a1 1 0 01-1.994.117l-.007-.117V4.47a1 1 0 011-1z",
          fill: "#647ccc"
        }));

var warningTriangle = React.createElement(Svg$Pos.make, {
      children: React.createElement(Svg$Pos.Path.make, {
            d: "M20.3548744,0.3635416 C20.7669246,0.601473473 21.1091628,0.943690847 21.3468716,1.35573744 L37.6365451,29.57027 C38.3850468,30.8664624 37.9409147,32.5239745 36.6448876,33.2721097 C36.2329382,33.5100647 35.765458,33.6353286 35.2897096,33.6353286 L2.70999077,33.6353286 C1.2133036,33.6353286 0,32.4220417 0,30.9253378 C0,30.4495387 0.125219511,29.9822675 0.363079006,29.5703427 L16.6529106,1.35550678 C17.4011624,0.0592776046 19.0586122,-0.384837793 20.3548744,0.3635416 Z M18.3849981,2.35544318 L2.09509849,30.5703969 C2.03278534,30.6783108 2,30.8006531 2,30.9253378 C2,31.3174661 2.31786691,31.6353286 2.70999077,31.6353286 L35.2897096,31.6353286 C35.4143103,31.6353286 35.5367577,31.6025181 35.6447683,31.5401278 C35.9843151,31.3441235 36.1006505,30.9099568 35.9045363,30.5703425 L19.6146512,2.35544357 C19.5523798,2.24750182 19.4627641,2.15789159 19.3548289,2.09556613 C19.0152326,1.89950479 18.5810292,2.01585005 18.3849981,2.35544318 Z M19,25.0001557 C19.5522847,25.0001557 20,25.447871 20,26.0001557 C20,26.5524405 19.5522847,27.0001557 19,27.0001557 C18.4477153,27.0001557 18,26.5524405 18,26.0001557 C18,25.447871 18.4477153,25.0001557 19,25.0001557 Z M19,13.0001557 C19.5522847,13.0001557 20,13.447871 20,14.0001557 L20,22.0001557 C20,22.5524405 19.5522847,23.0001557 19,23.0001557 C18.4477153,23.0001557 18,22.5524405 18,22.0001557 L18,14.0001557 C18,13.447871 18.4477153,13.0001557 19,13.0001557 Z",
            fill: "#DD8700"
          }),
      width: "38",
      height: "38",
      viewBox: "0 0 38 38"
    });

function Illustration(Props) {
  return Props.element;
}

var make = React.memo(Illustration);

export {
  error ,
  create ,
  notFound ,
  notAsked ,
  shopMissing ,
  warningTriangle ,
  make ,
}
/* error Not a pure module */
