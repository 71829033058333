// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "./Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../typography/Title.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Tooltip$Pos from "../overlays/Tooltip.bs.js";
import * as IconLink$Pos from "../navigation/IconLink.bs.js";
import * as IconButton$Pos from "../actions/IconButton.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var Action = {};

function actionOpenLinkNewTab(icon, title, to) {
  return {
          icon: icon,
          title: title,
          handler: {
            TAG: /* OpenLinkNewTab */2,
            _0: to
          }
        };
}

function actionCallback(icon, title, callback) {
  return {
          icon: icon,
          title: title,
          handler: {
            TAG: /* Callback */0,
            _0: callback
          }
        };
}

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#fefefe", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.normal)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerShadow: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#797885", Caml_option.some(Style$Pos.shadowOffset(0, 3)), 0.1, 10, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerStretch: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      title: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flex", undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, "spaceBetween", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      centerContent: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function Card(Props) {
  var children = Props.children;
  var title = Props.title;
  var action = Props.action;
  var variationOpt = Props.variation;
  var shadowedOpt = Props.shadowed;
  var stretchOpt = Props.stretch;
  var centerContentOpt = Props.centerContent;
  var variation = variationOpt !== undefined ? variationOpt : "common";
  var shadowed = shadowedOpt !== undefined ? shadowedOpt : false;
  var stretch = stretchOpt !== undefined ? stretchOpt : false;
  var centerContent = centerContentOpt !== undefined ? centerContentOpt : false;
  var actionButton;
  if (action !== undefined) {
    var handler = action.handler;
    var icon = action.icon;
    var tmp;
    switch (handler.TAG | 0) {
      case /* Callback */0 :
          var onPress = handler._0;
          tmp = React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps(icon, undefined, undefined, "#07061e", Colors$Pos.brandColor60, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(onPress, undefined);
                    }), undefined, undefined, undefined));
          break;
      case /* OpenLink */1 :
          tmp = React.createElement(IconLink$Pos.make, IconLink$Pos.makeProps(handler._0, undefined, icon, undefined, "#07061e", Colors$Pos.brandColor60, undefined, undefined, undefined, undefined));
          break;
      case /* OpenLinkNewTab */2 :
          tmp = React.createElement(IconLink$Pos.make, IconLink$Pos.makeProps(handler._0, true, icon, undefined, "#07061e", Colors$Pos.brandColor60, undefined, undefined, undefined, undefined));
          break;
      
    }
    actionButton = React.createElement(Tooltip$Pos.make, {
          children: tmp,
          placement: "top",
          content: {
            TAG: /* Text */0,
            _0: action.title
          },
          delay: 250,
          immediateClose: true
        });
  } else {
    actionButton = null;
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(variation === "table" ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, "medium", "small", "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined), title !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.title), undefined, undefined, undefined), React.createElement(Title$Pos.make, {
                                                  children: title,
                                                  level: 3,
                                                  weight: "strong"
                                                }), actionButton)), "large", undefined, undefined, "xnormal", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Stack$Pos.make, {
                                children: children,
                                space: "none"
                              })) : (
                        variation === "unset" ? React.createElement(React.Fragment, undefined, title !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.title), undefined, undefined, undefined), React.createElement(Title$Pos.make, {
                                                      children: title,
                                                      level: 3,
                                                      weight: "strong"
                                                    }), actionButton)), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Stack$Pos.make, {
                                    children: children,
                                    space: "xlarge"
                                  })) : React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "large", undefined, "small", "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined), title !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.title), undefined, undefined, undefined), React.createElement(Title$Pos.make, {
                                                      children: title,
                                                      level: 3,
                                                      weight: "strong"
                                                    }), actionButton)), undefined, undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Stack$Pos.make, {
                                    children: children,
                                    space: "xlarge"
                                  }))
                      )), Caml_option.some(Style$Pos.arrayOptionStyle([
                          Caml_option.some(styles.container),
                          shadowed ? Caml_option.some(styles.containerShadow) : undefined,
                          stretch ? Caml_option.some(styles.containerStretch) : undefined,
                          centerContent ? Caml_option.some(styles.centerContent) : undefined
                        ])), undefined, undefined, undefined));
}

var make = React.memo(Card);

make.displayName = "Card";

var variationCommon = "common";

export {
  variationCommon ,
  Action ,
  actionCallback ,
  actionOpenLinkNewTab ,
  make ,
}
/* styles Not a pure module */
