// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      root: {
        backgroundColor: "#d7d7e0",
        height: "30px",
        width: "1px"
      }
    });

function styleProps(size) {
  var height = size === "medium" || size === "large" || size === "xlarge" ? "40px" : "30px";
  return Stylex.props(styles.root, {
              height: height
            });
}

function Separator(Props) {
  var spaceOpt = Props.space;
  var sizeOpt = Props.size;
  var space = spaceOpt !== undefined ? spaceOpt : "xsmall";
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  var match = styleProps(size);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement("div", tmp)), space, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(Separator);

export {
  make ,
}
/* styles Not a pure module */
