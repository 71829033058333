// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../../resources/overlays/Popover.bs.js";
import * as TooltipIcon$Pos from "../../resources/overlays/TooltipIcon.bs.js";
import * as OpeningButton$Pos from "../../resources/actions/OpeningButton.bs.js";
import * as ProductPriceTableCell$Pos from "../Product/ProductPriceTableCell.bs.js";
import * as CartProductFeesPopover$Pos from "./CartProductFeesPopover.bs.js";
import * as CartProductFeeFieldset__Utils$Pos from "./CartProductFeeFieldset__Utils.bs.js";

function CartProductFeesTableCell(Props) {
  var editableOpt = Props.editable;
  var product = Props.product;
  var decimalPrecision = Props.decimalPrecision;
  var dispatch = Props.onRequestDispatch;
  var editable = editableOpt !== undefined ? editableOpt : false;
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var triggerHovered = match$1[1];
  var match$2;
  if (product.TAG === /* Unit */0) {
    var match$3 = product._0;
    match$2 = [
      match$3.id,
      match$3.fees,
      match$3.formattedUnitFee
    ];
  } else {
    var match$4 = product._0;
    match$2 = [
      match$4.id,
      match$4.fees,
      match$4.formattedUnitFee
    ];
  }
  var fees = match$2[1];
  var productId = match$2[0];
  React.useEffect((function () {
          if (popover.isOpen && fees.length === 0) {
            Curry._1(dispatch, {
                  TAG: /* ProductFeeAdded */9,
                  _0: productId
                });
          }
          
        }), [popover.isOpen]);
  var renderFeesTooltip = React.useMemo((function () {
          var formattedNewlinedFees = Belt_Array.reduce(fees, "", (function (prevLines, param) {
                  if (param.amount <= 0) {
                    return prevLines;
                  }
                  var kind = param.kind;
                  return Belt_Option.mapWithDefault(param.formattedAmount, prevLines, (function (formattedAmount) {
                                return prevLines + ("" + CartProductFeeFieldset__Utils$Pos.optionToText(kind) + " : " + formattedAmount + "\n");
                              }));
                }));
          return React.createElement(TooltipIcon$Pos.make, {
                      children: React.createElement(Text$Pos.make, Text$Pos.makeProps(formattedNewlinedFees, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Spaces$Pos.large, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)),
                      variation: "info"
                    });
        }), [fees]);
  var match$5 = fees.length !== 0;
  var totalAmountElement = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, React.createElement(ProductPriceTableCell$Pos.make, {
            value: match$2[2]
          }), editable || !match$5 ? null : renderFeesTooltip);
  var popover_isOpen = popover.isOpen;
  var popover_close = function (param) {
    if (!triggerHovered) {
      return Curry._1(popover.close, undefined);
    }
    
  };
  var popover_toggle = popover.toggle;
  var popover$1 = {
    isOpen: popover_isOpen,
    close: popover_close,
    toggle: popover_toggle
  };
  return React.createElement(React.Fragment, undefined, editable ? React.createElement(OpeningButton$Pos.make, OpeningButton$Pos.makeProps(undefined, totalAmountElement, popoverAriaProps.triggerProps, popover_isOpen, undefined, (function (param) {
                          Curry._1(popover_toggle, undefined);
                        }), undefined, popoverTriggerRef, undefined)) : totalAmountElement, popover_isOpen ? React.createElement(CartProductFeesPopover$Pos.make, {
                    product: product,
                    popover: popover$1,
                    popoverAriaProps: popoverAriaProps,
                    popoverTriggerRef: popoverTriggerRef,
                    decimalPrecision: decimalPrecision,
                    onRequestProductFeeAdd: (function (param) {
                        Curry._1(dispatch, {
                              TAG: /* ProductFeeAdded */9,
                              _0: productId
                            });
                      }),
                    onRequestProductFeeUpdate: (function (feeId, updatedFee) {
                        Curry._1(dispatch, {
                              TAG: /* ProductFeeUpdated */11,
                              _0: productId,
                              _1: feeId,
                              _2: updatedFee
                            });
                      }),
                    onRequestProductFeeReplicate: (function (fee) {
                        Curry._1(dispatch, {
                              TAG: /* ProductFeeReplicated */12,
                              _0: fee
                            });
                      }),
                    onRequestProductFeeRemove: (function (feeId) {
                        Curry._1(dispatch, {
                              TAG: /* ProductFeeRemoved */10,
                              _0: productId,
                              _1: feeId
                            });
                      })
                  }) : null);
}

var make = React.memo(CartProductFeesTableCell, (function (oldProps, newProps) {
        if (oldProps.product === newProps.product) {
          return oldProps.editable === newProps.editable;
        } else {
          return false;
        }
      }));

var Utils;

export {
  Utils ,
  make ,
}
/* make Not a pure module */
