// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as ProductPriceTableCell$Pos from "../Product/ProductPriceTableCell.bs.js";
import * as ProductStockTableCell$Pos from "../Product/ProductStockTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as LegacyProductReferenceTableCell$Pos from "../Product/LegacyProductReferenceTableCell.bs.js";

var Raw = {};

var query = Client.gql(["query VariantsQuery($search: String, $filterBy: InputVariantsDistinctOnCkuQueryFilter, $before: String, $after: String, $first: Int, $last: Int)  {\nvariantsDistinctOnCku(search: $search, filterBy: $filterBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \ncku  \nid  \nbulk  \nstockKeepingUnit  \nformattedName  \nformattedDescription  \nformattedPurchasedPrice  \nstock  {\n__typename  \nrawQuantity  \nformattedQuantity  \nstate  \n}\n\nproduct  {\n__typename  \nid  \n}\n\n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$2.hasNextPage;
  var value$6 = value$1.edges;
  return {
          variantsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: !(value$5 == null) ? value$5 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.bulk;
                    var value$3 = value$1.stockKeepingUnit;
                    var value$4 = value$1.formattedPurchasedPrice;
                    var value$5 = value$1.stock;
                    var value$6 = value$5.rawQuantity;
                    var value$7 = value$5.formattedQuantity;
                    var value$8 = value$5.state;
                    var tmp;
                    if (value$8 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$8) {
                        case "ALERT" :
                            tmp$1 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$1 = "DANGER";
                            break;
                        case "OK" :
                            tmp$1 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    var value$9 = value$1.product;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              cku: Scalar$Pos.CKU.parse(value$1.cku),
                              id: value$1.id,
                              bulk: !(value$2 == null) ? value$2 : undefined,
                              stockKeepingUnit: !(value$3 == null) ? value$3 : undefined,
                              formattedName: value$1.formattedName,
                              formattedDescription: value$1.formattedDescription,
                              formattedPurchasedPrice: !(value$4 == null) ? value$4 : undefined,
                              stock: {
                                __typename: value$5.__typename,
                                rawQuantity: !(value$6 == null) ? value$6 : undefined,
                                formattedQuantity: !(value$7 == null) ? value$7 : undefined,
                                state: tmp
                              },
                              product: {
                                __typename: value$9.__typename,
                                id: value$9.id
                              }
                            }
                          };
                  }), value$6),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.product;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var product = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.stock;
          var value$6 = value$5.state;
          var state = value$6 !== undefined ? (
              value$6 === "OK" ? "OK" : (
                  value$6 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$7 = value$5.formattedQuantity;
          var formattedQuantity = value$7 !== undefined ? value$7 : null;
          var value$8 = value$5.rawQuantity;
          var rawQuantity = value$8 !== undefined ? value$8 : null;
          var value$9 = value$5.__typename;
          var stock = {
            __typename: value$9,
            rawQuantity: rawQuantity,
            formattedQuantity: formattedQuantity,
            state: state
          };
          var value$10 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.formattedDescription;
          var value$12 = value$1.formattedName;
          var value$13 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.bulk;
          var bulk = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.id;
          var value$16 = value$1.cku;
          var value$17 = Scalar$Pos.CKU.serialize(value$16);
          var value$18 = value$1.__typename;
          var node = {
            __typename: value$18,
            cku: value$17,
            id: value$15,
            bulk: bulk,
            stockKeepingUnit: stockKeepingUnit,
            formattedName: value$12,
            formattedDescription: value$11,
            formattedPurchasedPrice: formattedPurchasedPrice,
            stock: stock,
            product: product
          };
          var value$19 = value.__typename;
          return {
                  __typename: value$19,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.hasNextPage;
  var hasNextPage = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var value$8 = value$4.__typename;
  var pageInfo = {
    __typename: value$8,
    startCursor: startCursor,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$9 = value$1.__typename;
  var variantsDistinctOnCku = {
    __typename: value$9,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          variantsDistinctOnCku: variantsDistinctOnCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.active;
  var a$2 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          active: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          archived: a$2 !== undefined ? (
              a$2 === "ONLY" ? "ONLY" : (
                  a$2 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.before;
  var a$3 = inp.after;
  var a$4 = inp.first;
  var a$5 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(a$1) : undefined,
          before: a$2 !== undefined ? a$2 : undefined,
          after: a$3 !== undefined ? a$3 : undefined,
          first: a$4 !== undefined ? a$4 : undefined,
          last: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables(search, filterBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputVariantsDistinctOnCkuQueryFilter(shopIds, active, archived, param) {
  return {
          shopIds: shopIds,
          active: active,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function rowsFromData(data) {
  return Belt_Array.map(data.variantsDistinctOnCku.edges, (function (edge) {
                var description = edge.node.formattedDescription;
                var tmp = description === "" ? Intl$Pos.t("Description not filled in") : description;
                return {
                        id: edge.node.id,
                        cku: edge.node.cku,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        name: edge.node.formattedName,
                        description: tmp,
                        purchasePrice: edge.node.formattedPurchasedPrice,
                        stockRawQuantity: Belt_Option.getWithDefault(edge.node.stock.rawQuantity, 0),
                        stockFormattedQuantity: Belt_Option.getWithDefault(edge.node.stock.formattedQuantity, "-"),
                        stockState: edge.node.stock.state,
                        bulk: Belt_Option.getWithDefault(edge.node.bulk, false)
                      };
              }));
}

var columns = [
  {
    key: "reference",
    name: Intl$Pos.t("Product"),
    layout: {
      width: {
        NAME: "pct",
        VAL: 65
      }
    },
    render: (function (param) {
        var product = param.data;
        var tmp = {
          variation: "secondary",
          name: product.name,
          description: product.description,
          disabled: param.disabled,
          openNewTab: true
        };
        if (product.stockKeepingUnit !== undefined) {
          tmp.stockKeepingUnit = product.stockKeepingUnit;
        }
        return React.createElement(LegacyProductReferenceTableCell$Pos.make, tmp);
      })
  },
  {
    key: "stock",
    name: Intl$Pos.t("Stock exp."),
    render: (function (param) {
        var product = param.data;
        if (param.disabled) {
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t("Already added"),
                      variation: "subdued",
                      size: "small"
                    });
        }
        var tmp = {
          value: product.stockFormattedQuantity,
          size: "xsmall"
        };
        if (product.stockState !== undefined) {
          tmp.state = Caml_option.valFromOption(product.stockState);
        }
        return React.createElement(ProductStockTableCell$Pos.make, tmp);
      })
  },
  {
    key: "purchasedPrice",
    name: Intl$Pos.t("Purchase price"),
    layout: {
      width: {
        NAME: "px",
        VAL: 120
      }
    },
    render: (function (param) {
        if (param.disabled) {
          return null;
        } else {
          return React.createElement(ProductPriceTableCell$Pos.make, {
                      value: param.data.purchasePrice,
                      size: "xsmall"
                    });
        }
      })
  }
];

function setTransferredQuantityValue(product) {
  return {
          cku: product.cku,
          name: product.name,
          description: product.description,
          purchasePrice: product.purchasePrice,
          stockKeepingUnit: product.stockKeepingUnit,
          senderVariantId: product.senderVariantId,
          senderStockQuantity: product.senderStockQuantity,
          senderStockState: product.senderStockState,
          recipientVariantId: product.recipientVariantId,
          recipientStockQuantity: product.recipientStockQuantity,
          recipientStockState: product.recipientStockState,
          transferredQuantity: 1
        };
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + Intl$Pos.t(Intl$Pos.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(TextStyle$Pos.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, Belt_Array.map(Belt_Array.keep(selectedProducts, (function (row) {
                        return !row.bulk;
                      })), (function (row) {
                    return setTransferredQuantityValue({
                                cku: row.cku,
                                name: row.name,
                                description: row.description,
                                purchasePrice: row.purchasePrice,
                                stockKeepingUnit: row.stockKeepingUnit,
                                senderVariantId: row.id,
                                senderStockQuantity: row.stockRawQuantity,
                                senderStockState: row.stockState,
                                recipientVariantId: "",
                                recipientStockQuantity: undefined,
                                recipientStockState: undefined,
                                transferredQuantity: 0
                              });
                  })));
      };
      return React.createElement(Modal$Pos.make, {
                  children: children,
                  opened: opened,
                  title: Intl$Pos.t("Product append"),
                  variation: "secondary",
                  backgroundColor: "#fefefe",
                  renderStartText: renderStartText,
                  abortButtonText: Intl$Pos.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: Intl$Pos.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  setTransferredQuantityValue: setTransferredQuantityValue,
  make: make
};

function makeVariables$1(search, shopId, after, param) {
  return makeVariables(search, {
              shopIds: {
                _in: [shopId]
              },
              active: undefined,
              archived: undefined
            }, undefined, after, 10, undefined, undefined);
}

function StockTransferFormProductPickerModalTable(Props) {
  var opened = Props.opened;
  var senderShopId = Props.senderShopId;
  var recipientShopId = Props.recipientShopId;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var match = React.useState(function () {
        return [];
      });
  var setSelectedProducts = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$1[1];
  var searchQuery = match$1[0];
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        senderShopId === "" || recipientShopId === "",
        undefined,
        makeVariables$1(searchQuery, senderShopId, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers$Pos.useQueryResultToAsyncResultWithVariablesReloading2(query, searchQuery, senderShopId);
  var data = AsyncResult$Pos.mapOk(asyncResult, rowsFromData);
  React.useEffect((function () {
          if (!opened && searchQuery !== "") {
            Curry._1(setSearchQuery, (function (param) {
                    return "";
                  }));
          }
          
        }), [opened]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variantsDistinctOnCku.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(searchQuery, senderShopId, data$1.variantsDistinctOnCku.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variantsDistinctOnCku;
                    return {
                            variantsDistinctOnCku: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              edges: Belt_Array.concat(prevResult.variantsDistinctOnCku.edges, newVariants.edges),
                              totalCount: newVariants.totalCount
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          Curry._1(setSelectedProducts, (function (prev) {
                  var rowsData;
                  if (typeof data === "number") {
                    rowsData = data === /* NotAsked */0 ? [] : [];
                  } else if (data.TAG === /* Reloading */0) {
                    var data$1 = data._0;
                    rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
                  } else {
                    var data$2 = data._0;
                    rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
                  }
                  if (selection) {
                    return Belt_Array.keepMap(selection._0, (function (selectedRowId) {
                                  return Belt_Array.getBy(Belt_Array.concat(rowsData, prev), (function (row) {
                                                return row.id === selectedRowId;
                                              }));
                                }));
                  } else {
                    return prev;
                  }
                }));
        }), [
        data,
        disabledIds
      ]);
  var placeholderEmptyState = React.createElement(Placeholder$Pos.make, {
        status: senderShopId !== "" && recipientShopId !== "" ? /* NoDataAvailable */1 : /* Pending */({
              title: Intl$Pos.t("Beware !"),
              message: Intl$Pos.t("Please select the shops linked to the transfer to start adding products."),
              illustration: Caml_option.some(Illustration$Pos.shopMissing)
            })
      });
  return React.createElement(make, {
              children: React.createElement(AnimatedRender$Pos.make, {
                    children: React.createElement(TableView$Pos.make, {
                          data: data,
                          columns: columns,
                          keyExtractor: (function (row) {
                              return row.id;
                            }),
                          disabledRowsKeys: disabledIds,
                          selectionEnabled: true,
                          selectAllEnabled: false,
                          hideReloadingPlaceholder: true,
                          maxWidth: 580,
                          minHeight: 315,
                          maxHeight: 315,
                          placeholderEmptyState: placeholderEmptyState,
                          searchPlaceholder: Intl$Pos.t("Search product"),
                          onSearchQueryChange: (function (value) {
                              Curry._1(setSearchQuery, (function (param) {
                                      return value;
                                    }));
                            }),
                          onLoadMore: onLoadMore,
                          onSelectChange: onSelectChange
                        }),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedProducts: match[0],
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$1 = React.memo(StockTransferFormProductPickerModalTable, (function (oldProps, newProps) {
        return oldProps.opened === newProps.opened;
      }));

export {
  Query ,
  rowsFromData ,
  columns ,
  PickerModal ,
  makeVariables$1 as makeVariables,
  make$1 as make,
}
/* query Not a pure module */
