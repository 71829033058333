// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intercom$Pos from "../externals/Intercom.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

var ArticleID = {};

var install = Intercom$Pos.install;

function setup(appId) {
  window.Intercom("boot", {
        app_id: appId,
        hide_default_launcher: true
      });
}

function updateUserFields(name, userId, email, param) {
  window.Intercom("update", {
        name: name,
        email: Js_null_undefined.fromOption(email),
        user_id: Js_null_undefined.fromOption(userId)
      });
}

function show(param) {
  window.Intercom("show");
}

function showArticle(id) {
  window.Intercom("showArticle", id);
}

function showHome(param) {
  window.Intercom("showSpace", "home");
}

function showMessages(param) {
  window.Intercom("showSpace", "messages");
}

function openNewMessage(content) {
  window.Intercom("showNewMessage", content);
}

var howCentralizeCatalog = "8202106";

var prepareImportInventory = "8155377";

var automaticOrdering = "7068311";

var orderLabelSheets = "7118898";

var setupLabelPrinterAndStarPrinterOnline = "9113414";

var getLabelPrintFeature = "9204256";

var termsAndConditions = "8889741";

var setupAndExportAccountingEntries = "10244703";

var gettingStartedGuideLink = "https://quirky-amaranthus-c07.notion.site/Vos-premiers-pas-chez-Wino-9ea8719601494a748a4ee1a83a7d31b9";

export {
  ArticleID ,
  howCentralizeCatalog ,
  prepareImportInventory ,
  automaticOrdering ,
  orderLabelSheets ,
  setupLabelPrinterAndStarPrinterOnline ,
  getLabelPrintFeature ,
  termsAndConditions ,
  setupAndExportAccountingEntries ,
  gettingStartedGuideLink ,
  install ,
  setup ,
  updateUserFields ,
  show ,
  showArticle ,
  showHome ,
  showMessages ,
  openNewMessage ,
}
/* No side effect */
