// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../../resources/theme/FontSizes.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

function get(values, field) {
  switch (field) {
    case /* Username */0 :
        return values.username;
    case /* Password */1 :
        return values.password;
    case /* KeepSigned */2 :
        return values.keepSigned;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Username */0 :
        return {
                username: value,
                password: values.password,
                keepSigned: values.keepSigned
              };
    case /* Password */1 :
        return {
                username: values.username,
                password: value,
                keepSigned: values.keepSigned
              };
    case /* KeepSigned */2 :
        return {
                username: values.username,
                password: values.password,
                keepSigned: value
              };
    
  }
}

var LoginFormLenses = {
  get: get,
  set: set
};

var LoginForm = Form$Pos.Make(LoginFormLenses);

function encodeBody(username, password) {
  return Json$Pos.stringify(Json$Pos.encodeDict(Js_dict.fromArray([
                      [
                        "username",
                        Json$Pos.encodeString(username)
                      ],
                      [
                        "password",
                        Json$Pos.encodeString(password)
                      ]
                    ])));
}

function decodeResult(json) {
  return Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "access_token");
}

function make(username, password) {
  return Future.map(FuturePromise.fromPromise(fetch(Env$Pos.gatewayUrl(undefined) + "/sign-in", {
                          method: "POST",
                          body: Caml_option.some(encodeBody(username, password)),
                          headers: Caml_option.some(new Headers({
                                    "Content-Type": "application/json"
                                  }))
                        }).then(function (prim) {
                        return prim.json();
                      }).then(function (json) {
                      return Promise.resolve(Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "access_token"));
                    })), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var jwt = result._0;
                if (jwt !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: jwt
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

var SignInRequest = {
  encodeBody: encodeBody,
  decodeResult: decodeResult,
  make: make
};

var styles = StyleSheet$Pos.create({
      root: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, "#535165", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "underline", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            FontSizes$Pos.styleFromSize("xsmall")
          ])
    });

function AuthLoginPage$RecoveryLinkButton(Props) {
  var recoveryRoute = Props.recoveryRoute;
  return React.createElement(Navigation$Pos.Link.make, {
              children: React.createElement(Text$Pos.make, Text$Pos.makeProps(Intl$Pos.t("Forgot password ?"), Caml_option.some(styles.root), undefined, undefined, undefined)),
              to: {
                TAG: /* Route */0,
                _0: recoveryRoute
              }
            });
}

var RecoveryLinkButton = {
  styles: styles,
  make: AuthLoginPage$RecoveryLinkButton
};

function AuthLoginPage$NotificationBanner(Props) {
  var notification = Props.notification;
  var onRequestClose = Props.onRequestClose;
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                          textStatus: notification,
                          onRequestClose: onRequestClose
                        })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)), undefined, undefined, undefined));
}

var make$1 = React.memo(AuthLoginPage$NotificationBanner);

var NotificationBanner = {
  make: make$1
};

var styles$1 = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "stretch", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "column", undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(-10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(465)), undefined, undefined),
      title: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.large)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)
    });

function noop(param) {
  
}

var schema = [
  {
    TAG: /* Email */5,
    _0: /* Username */0
  },
  {
    TAG: /* StringMin */0,
    _0: /* Password */1,
    _1: 3
  }
];

function AuthLoginPage(Props) {
  var signInRequest = Props.signInRequest;
  var initialNotification = Props.initialNotification;
  var recoveryRoute = Props.recoveryRoute;
  var initialUsernameOpt = Props.initialUsername;
  var initialUsername = initialUsernameOpt !== undefined ? initialUsernameOpt : "";
  var authState = Auth$Pos.useState(undefined);
  var logUser = Auth$Pos.useLogUser(undefined, undefined);
  var match = React.useState(function () {
        return initialNotification;
      });
  var setNotfication = match[1];
  var notification = match[0];
  var onSubmitSuccess = function (jwt) {
    if (jwt !== undefined) {
      return Curry._1(logUser, jwt);
    } else {
      return Curry._1(setNotfication, (function (param) {
                    return {
                            TAG: /* Danger */1,
                            _0: Intl$Pos.t("Something went wrong")
                          };
                  }));
    }
  };
  var onSubmitFailure = function (error) {
    Curry._1(setNotfication, (function (param) {
            return {
                    TAG: /* Danger */1,
                    _0: error
                  };
          }));
  };
  var formPropState = Curry._1(LoginForm.useFormPropState, {
        initialValues: {
          username: initialUsername,
          password: "",
          keepSigned: true
        },
        schema: schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry._2(signInRequest, param$1.username, param$1.password), undefined, (function (jwt) {
                      return jwt;
                    })), undefined, (function (param) {
                  return Intl$Pos.t("Incorrect credentials.");
                }));
  };
  var onRequestCloseNotificationBanner = function (param) {
    Curry._1(setNotfication, (function (param) {
            
          }));
  };
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$1.container), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$1.title), undefined, undefined, undefined), React.createElement(Title$Pos.make, {
                      children: Intl$Pos.t("Good to see you again !"),
                      level: 1
                    }), notification !== undefined ? React.createElement(make$1, {
                        notification: notification,
                        onRequestClose: onRequestCloseNotificationBanner
                      }) : null), React.createElement(LoginForm.Core.Provider.make, {
                  children: null,
                  propState: formPropState
                }, React.createElement(LoginForm.ControlEnterKey.make, {
                      onSubmit: onSubmit
                    }), React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "large"
                    }, React.createElement(LoginForm.InputText.make, {
                          field: /* Username */0,
                          label: Intl$Pos.t("Email"),
                          placeholder: Intl$Pos.t("name@yourcompany.com"),
                          hideRequired: true
                        }), React.createElement(LoginForm.InputPassword.make, {
                          field: /* Password */1,
                          label: Intl$Pos.t("Password"),
                          placeholder: Intl$Pos.t("Password"),
                          hideError: true,
                          hideRequired: true
                        }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(typeof authState === "number" ? React.createElement(LoginForm.SubmitButton.make, {
                                        onSubmit: onSubmit,
                                        text: Intl$Pos.t("Sign in")
                                      }) : React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Sign in") + "...", "large", "primary", undefined, undefined, undefined, true, undefined, undefined, undefined, noop, undefined, undefined, undefined))), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(AuthLoginPage$RecoveryLinkButton, {
                      recoveryRoute: recoveryRoute
                    })));
}

var signInRequest = make;

var make$2 = AuthLoginPage;

export {
  LoginFormLenses ,
  LoginForm ,
  SignInRequest ,
  signInRequest ,
  RecoveryLinkButton ,
  NotificationBanner ,
  styles$1 as styles,
  noop ,
  schema ,
  make$2 as make,
}
/* LoginForm Not a pure module */
