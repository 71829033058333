// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Auth$Pos from "../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../primitives/Intl.bs.js";
import * as Json$Pos from "../primitives/Json.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AuthRoutes$Pos from "../app/Auth/AuthRoutes.bs.js";
import * as Navigation$Pos from "../primitives/Navigation.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var UnauthorizedRequestError = /* @__PURE__ */Caml_exceptions.create("Request-Pos.UnauthorizedRequestError");

var UnknownRequestError = /* @__PURE__ */Caml_exceptions.create("Request-Pos.UnknownRequestError");

var RequestError = /* @__PURE__ */Caml_exceptions.create("Request-Pos.RequestError");

var ClientError = /* @__PURE__ */Caml_exceptions.create("Request-Pos.ClientError");

var serverErrorMessage = Intl$Pos.t("Wino did not respond, please reiterate your request.");

function decodeInvalidRequestFailures(json) {
  var failures = Belt_Array.keepMap(Belt_Array.map(json, Json$Pos.decodeDict), (function (json) {
          var message = Json$Pos.flatDecodeDictFieldString(json, "message");
          var kind = Json$Pos.flatDecodeDictFieldString(json, "kind");
          var data = Belt_Option.map(Json$Pos.flatDecodeDictFieldDict(json, "data"), (function (prim) {
                  return prim;
                }));
          if (message !== undefined && kind !== undefined) {
            return {
                    message: message,
                    kind: kind,
                    data: data
                  };
          }
          
        }));
  if (failures.length === json.length) {
    return failures;
  }
  
}

function decodeResult(json) {
  var data = Belt_Option.orElse(Belt_Option.orElse(Belt_Option.map(Json$Pos.flatDecodeDictFieldDict(Json$Pos.decodeDict(json), "data"), (function (prim) {
                  return prim;
                })), Belt_Option.map(Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "data"), (function (prim) {
                  return prim;
                }))), Belt_Option.map(Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "data", Json$Pos.decodeNull), (function (param) {
              return Json$Pos.encodedNull;
            })));
  var errors = Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "errors");
  if (data !== undefined) {
    if (errors !== undefined) {
      return {
              TAG: /* Error */1,
              _0: undefined
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: Caml_option.some(Caml_option.valFromOption(data))
            };
    }
  } else if (errors !== undefined) {
    return {
            TAG: /* Error */1,
            _0: decodeInvalidRequestFailures(errors)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

function make(methodOpt, bodyJson, authTokenRequiredOpt, skipMalformedOkResultOpt, endpoint) {
  var method = methodOpt !== undefined ? methodOpt : "GET";
  var authTokenRequired = authTokenRequiredOpt !== undefined ? authTokenRequiredOpt : true;
  var skipMalformedOkResult = skipMalformedOkResultOpt !== undefined ? skipMalformedOkResultOpt : false;
  var jwt = Auth$Pos.getJwt(undefined);
  if (Belt_Option.isNone(jwt) && authTokenRequired) {
    Navigation$Pos.openRoute(AuthRoutes$Pos.logoutSessionExpiredRoute);
    return Future.value({
                TAG: /* Error */1,
                _0: /* JwtAuthenticationRedirection */2
              });
  }
  var abortController = new AbortController();
  var abortSignal = abortController.signal;
  var headers = jwt !== undefined ? (
      authTokenRequired ? new Headers({
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwt + ""
            }) : new Headers({
              "Content-Type": "application/json"
            })
    ) : new Headers({
          "Content-Type": "application/json"
        });
  var init_method = method;
  var init_body = Belt_Option.map(bodyJson, Json$Pos.stringify);
  var init_headers = Caml_option.some(headers);
  var init_signal = Caml_option.some(abortSignal);
  var init = {
    method: init_method,
    body: init_body,
    headers: init_headers,
    signal: init_signal
  };
  var fetchPromise = fetch(endpoint, init).then(function (response) {
        var status = response.status;
        var contentType = response.headers.get("content-type");
        if (status === 401) {
          return Promise.reject({
                      RE_EXN_ID: UnauthorizedRequestError
                    });
        } else if (contentType == null) {
          return Promise.reject({
                      RE_EXN_ID: UnknownRequestError
                    });
        } else if (contentType.startsWith("application/json")) {
          return response.json();
        } else if (contentType.startsWith("text/plain") && status >= 500) {
          return response.text().then(function (text) {
                      return Promise.reject({
                                  RE_EXN_ID: RequestError,
                                  _1: text
                                });
                    });
        } else {
          return Promise.reject({
                      RE_EXN_ID: UnknownRequestError
                    });
        }
      });
  return Future.map(Future.map(Future.mapError(Future.make(function (resolve) {
                          fetchPromise.then((function (ok) {
                                  Curry._1(resolve, {
                                        TAG: /* Ok */0,
                                        _0: ok
                                      });
                                }), (function (error) {
                                  Curry._1(resolve, {
                                        TAG: /* Error */1,
                                        _0: error
                                      });
                                }));
                          return (function (param) {
                                    abortController.abort();
                                  });
                        }), true, (function (error) {
                        var error$1 = Caml_js_exceptions.internalToOCamlException(error);
                        if (error$1.RE_EXN_ID !== Js_exn.$$Error) {
                          return error$1;
                        }
                        var message = error$1._1.message;
                        if (message !== undefined) {
                          return {
                                  RE_EXN_ID: ClientError,
                                  _1: message
                                };
                        } else {
                          return {
                                  RE_EXN_ID: UnknownRequestError
                                };
                        }
                      })), true, (function (result) {
                    if (result.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: result._0
                            };
                    }
                    var error = result._0;
                    if (error.RE_EXN_ID === UnauthorizedRequestError) {
                      return {
                              TAG: /* Error */1,
                              _0: /* JwtAuthenticationRedirection */2
                            };
                    } else if (error.RE_EXN_ID === ClientError) {
                      return {
                              TAG: /* Error */1,
                              _0: {
                                TAG: /* ClientError */1,
                                _0: error._1
                              }
                            };
                    } else if (error.RE_EXN_ID === RequestError) {
                      return {
                              TAG: /* Error */1,
                              _0: {
                                TAG: /* ServerError */0,
                                _0: error._1
                              }
                            };
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: /* UnexpectedServerError */0
                            };
                    }
                  })), true, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return result;
                }
                var ok = result._0;
                var match = decodeResult(ok);
                if (match.TAG === /* Ok */0) {
                  var data = match._0;
                  if (data !== undefined) {
                    return {
                            TAG: /* Ok */0,
                            _0: Caml_option.valFromOption(data)
                          };
                  }
                  
                } else {
                  var failures = match._0;
                  if (failures !== undefined) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              TAG: /* InvalidRequestFailures */2,
                              _0: failures
                            }
                          };
                  }
                  
                }
                if (skipMalformedOkResult === false) {
                  return {
                          TAG: /* Error */1,
                          _0: /* MalformedResponse */1
                        };
                } else {
                  return {
                          TAG: /* Ok */0,
                          _0: ok
                        };
                }
              }));
}

export {
  serverErrorMessage ,
  decodeInvalidRequestFailures ,
  decodeResult ,
  make ,
}
/* serverErrorMessage Not a pure module */
