// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as InputSearch$Pos from "./InputSearch.bs.js";
import * as ReactUpdateDebounced$Pos from "../../primitives/ReactUpdateDebounced.bs.js";

var make = React.memo(function (Props) {
      var placeholder = Props.placeholder;
      var valueOpt = Props.value;
      var autoFocusOpt = Props.autoFocus;
      var onChange = Props.onChange;
      var value = valueOpt !== undefined ? valueOpt : "";
      var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : true;
      var mounted = React.useRef(false);
      var match = React.useState(function () {
            return false;
          });
      var setFocused = match[1];
      var match$1 = React.useState(function () {
            return value;
          });
      var setSearchQuery = match$1[1];
      var searchQuery = match$1[0];
      var debouncedSearchQuery = ReactUpdateDebounced$Pos.use(searchQuery, 500);
      React.useEffect((function () {
              if (mounted.current) {
                Curry._1(onChange, debouncedSearchQuery);
              }
              
            }), [debouncedSearchQuery]);
      React.useEffect((function () {
              if (mounted.current) {
                Curry._1(setSearchQuery, (function (param) {
                        return value;
                      }));
              }
              
            }), [value]);
      React.useEffect((function () {
              mounted.current = true;
              return (function (param) {
                        mounted.current = false;
                      });
            }), []);
      return React.createElement(InputSearch$Pos.make, {
                  loading: false,
                  onRequestClear: (function (param) {
                      Curry._1(setSearchQuery, (function (param) {
                              return "";
                            }));
                    }),
                  focused: match[0],
                  placeholder: placeholder,
                  autoFocus: autoFocus,
                  value: searchQuery,
                  onChange: (function (value) {
                      Curry._1(setSearchQuery, (function (param) {
                              return value;
                            }));
                    }),
                  onFocus: (function (param) {
                      Curry._1(setFocused, (function (param) {
                              return true;
                            }));
                    }),
                  onBlur: (function (param) {
                      Curry._1(setFocused, (function (param) {
                              return false;
                            }));
                    })
                });
    });

export {
  make ,
}
/* make Not a pure module */
