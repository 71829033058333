// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      main: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e7e7ee", undefined, undefined, undefined, undefined, Style$Pos.hairlineWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function Divider(Props) {
  var spaceY = Props.spaceY;
  var spaceTop = Props.spaceTop;
  var spaceBottom = Props.spaceBottom;
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.main), undefined, undefined, undefined))), undefined, spaceY, spaceTop, spaceBottom, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(Divider);

export {
  make ,
}
/* styles Not a pure module */
