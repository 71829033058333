// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as SupplierSelect$Pos from "../Supplier/SupplierSelect.bs.js";
import * as InputNumberField$Pos from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as TableLayoutPanel$Pos from "../../resources/layout-and-structure/TableLayoutPanel.bs.js";
import * as CatalogVariantEditForm$Pos from "./CatalogVariantEditForm.bs.js";

function keyExtractor(row) {
  return row.supplierId + "-retail";
}

var Row = {
  keyExtractor: keyExtractor
};

function tableColumns(bulkUnit, onRequestSupplierUpdate, onRequestPurchasePriceUpdate) {
  return [
          {
            key: "supplier-id",
            name: Intl$Pos.t("Supplier"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 350
              }
            },
            render: (function (param) {
                var match = Auth$Pos.useActiveShop(undefined);
                if (match !== undefined) {
                  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(SupplierSelect$Pos.make, {
                                          preset: {
                                            NAME: "inputField",
                                            VAL: {
                                              required: false
                                            }
                                          },
                                          shopId: match.id,
                                          value: {
                                            id: param.data.supplierId,
                                            name: ""
                                          },
                                          onChange: onRequestSupplierUpdate
                                        })), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)), undefined, undefined, undefined));
                } else {
                  return null;
                }
              })
          },
          {
            key: "purchasedprice",
            name: Intl$Pos.t("Purchase price") + " " + Intl$Pos.t("HT"),
            render: (function (param) {
                return React.createElement(InputNumberField$Pos.make, {
                            value: param.data.purchasePrice,
                            minValue: 0,
                            precision: 3,
                            appender: {
                              TAG: /* Custom */1,
                              _0: Intl$Pos.toCurrencySymbol("EUR") + Belt_Option.mapWithDefault(bulkUnit, "", (function (unit) {
                                      return " / " + unit + "";
                                    }))
                            },
                            onChange: onRequestPurchasePriceUpdate
                          });
              })
          }
        ];
}

function CatalogVariantEditFormPurchasePriceTablePanel(Props) {
  var match = Curry._1(CatalogVariantEditForm$Pos.useFormState, undefined).values;
  var bulk = match.bulk;
  var capacityUnit = match.capacityUnit;
  var dispatch = Curry._1(CatalogVariantEditForm$Pos.useFormDispatch, undefined);
  var onRequestSupplierUpdate = React.useCallback((function (supplier) {
          if (supplier !== undefined) {
            return Curry._1(dispatch, {
                        TAG: /* FieldValueChanged */2,
                        _0: /* SupplierId */13,
                        _1: (function (param) {
                            return supplier.id;
                          })
                      });
          }
          
        }), []);
  var onRequestPurchasePriceUpdate = React.useCallback((function (purchasePrice) {
          Curry._1(dispatch, {
                TAG: /* FieldValueChanged */2,
                _0: /* PurchasePrice */12,
                _1: (function (param) {
                    return purchasePrice;
                  })
              });
        }), []);
  var columns = React.useMemo((function () {
          return tableColumns(capacityUnit !== undefined && bulk ? capacityUnit : undefined, onRequestSupplierUpdate, onRequestPurchasePriceUpdate);
        }), [
        capacityUnit,
        bulk
      ]);
  var rows = [{
      supplierId: match.supplierId,
      purchasePrice: match.purchasePrice
    }];
  var placeholderEmptyState = React.createElement(Placeholder$Pos.make, {
        status: /* NoDataAvailable */1,
        customText: Intl$Pos.t("No purchase price has been yet recorded")
      });
  var data = {
    TAG: /* Done */1,
    _0: {
      TAG: /* Ok */0,
      _0: rows
    }
  };
  return React.createElement(TableLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Purchase price"),
              description: Intl$Pos.t("Enter the product purchase price."),
              children: React.createElement(TableView$Pos.make, {
                    data: data,
                    columns: columns,
                    keyExtractor: keyExtractor,
                    placeholderEmptyState: placeholderEmptyState
                  })
            });
}

var make = React.memo(CatalogVariantEditFormPurchasePriceTablePanel);

export {
  Row ,
  tableColumns ,
  make ,
}
/* make Not a pure module */
