// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as AccountingPage$Pos from "./AccountingPage.bs.js";
import * as AccountingVendor$Pos from "../../modules/AccountingVendor.bs.js";
import * as AccountingConfiguration$Pos from "../../modules/AccountingConfiguration.bs.js";
import * as AccountingConfigurationPage$Pos from "./AccountingConfigurationPage.bs.js";

var baseRoute = "/accounting";

function configurationRoute(shopId, vendor) {
  return baseRoute + "/" + shopId + "/configuration/" + AccountingVendor$Pos.toLowercaseString(vendor);
}

function AccountingRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (!subUrlPath) {
    return React.createElement(AccountingPage$Pos.make, {
                configurationRoute: configurationRoute,
                getShopAccountingConfigurationRequest: AccountingConfiguration$Pos.request
              });
  }
  var match = subUrlPath.tl;
  if (match && match.hd === "configuration") {
    var match$1 = match.tl;
    if (match$1 && !match$1.tl) {
      var vendor = AccountingVendor$Pos.fromString(match$1.hd);
      if (vendor !== undefined) {
        return React.createElement(AccountingConfigurationPage$Pos.make, {
                    getAllShopTaxesRequest: AccountingConfigurationPage$Pos.getAllShopTaxesRequest,
                    getShopAccountingConfigurationRequest: AccountingConfiguration$Pos.request,
                    createOrUpdateShopAccountingConfigurationRequest: AccountingConfigurationPage$Pos.createOrUpdateShopAccountingConfigurationRequest,
                    shopId: subUrlPath.hd,
                    vendor: vendor
                  });
      } else {
        return React.createElement(Navigation$Pos.Redirect.make, {
                    route: baseRoute
                  });
      }
    }
    
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: baseRoute
            });
}

var getAllShopTaxesRequest = AccountingConfigurationPage$Pos.getAllShopTaxesRequest;

var createOrUpdateShopAccountingConfigurationRequest = AccountingConfigurationPage$Pos.createOrUpdateShopAccountingConfigurationRequest;

var getShopAccountingConfigurationRequest = AccountingConfiguration$Pos.request;

var make = AccountingRouter;

export {
  baseRoute ,
  configurationRoute ,
  getAllShopTaxesRequest ,
  createOrUpdateShopAccountingConfigurationRequest ,
  getShopAccountingConfigurationRequest ,
  make ,
}
/* react Not a pure module */
