// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MenuItem$Pos from "../../resources/navigation/MenuItem.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as OrderMutations$Pos from "./OrderMutations.bs.js";
import * as OrderArchiveModal$Pos from "./OrderArchiveModal.bs.js";

function OrderTableActions(Props) {
  var id = Props.id;
  var statuses = Props.statuses;
  var callbackUrlAfterArchiveAction = Props.callbackUrlAfterArchiveAction;
  var match = React.useState(function () {
        return false;
      });
  var setArchiveModalOpened = match[1];
  var unarchiveOrder = OrderMutations$Pos.useUnarchive(id);
  var match$1 = Belt_Array.getExn(statuses, 0);
  var tmp = {
    orderId: id,
    opened: match[0],
    onRequestClose: (function (param) {
        Curry._1(setArchiveModalOpened, (function (param) {
                return false;
              }));
      })
  };
  if (callbackUrlAfterArchiveAction !== undefined) {
    tmp.commitCallbackUrl = Caml_option.valFromOption(callbackUrlAfterArchiveAction);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Inline$Pos.make, {
                  children: React.createElement(Menu$Pos.make, {
                        children: null,
                        variation: "more_round"
                      }, React.createElement(MenuItem$Pos.make, {
                            content: {
                              TAG: /* Text */0,
                              _0: Intl$Pos.t("See")
                            },
                            action: {
                              TAG: /* OpenLink */1,
                              _0: {
                                TAG: /* Route */0,
                                _0: LegacyRouter$Pos.routeToPathname(/* Order */0) + "/" + id
                              }
                            }
                          }), match$1 === "ARCHIVED" ? React.createElement(MenuItem$Pos.make, {
                              content: {
                                TAG: /* Text */0,
                                _0: Intl$Pos.t("Unarchive")
                              },
                              action: {
                                TAG: /* Callback */0,
                                _0: (function (param) {
                                    Curry._1(unarchiveOrder, undefined);
                                  })
                              }
                            }) : React.createElement(MenuItem$Pos.make, {
                              content: {
                                TAG: /* Text */0,
                                _0: Intl$Pos.t("Archive")
                              },
                              action: {
                                TAG: /* Callback */0,
                                _0: (function (param) {
                                    Curry._1(setArchiveModalOpened, (function (param) {
                                            return true;
                                          }));
                                  })
                              }
                            })),
                  align: "end"
                }), React.createElement(OrderArchiveModal$Pos.make, tmp));
}

var make = React.memo(OrderTableActions);

export {
  make ,
}
/* make Not a pure module */
