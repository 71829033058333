// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Tag$Pos from "../../resources/feedback-indicators/Tag.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Image$Pos from "../../primitives/Image.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Separator$Pos from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as SessionTracker$Pos from "../../core/SessionTracker.bs.js";
import * as SelectDateRangeFilter$Pos from "../../resources/selection-and-input/SelectDateRangeFilter.bs.js";
import Sales_empty_placeholderPng from "./sales_empty_placeholder.png";
import * as RequestOpenStorageUrlButton$Pos from "../../resources/actions/RequestOpenStorageUrlButton.bs.js";

var mainPlaceholder = Sales_empty_placeholderPng;

var styles = StyleSheet$Pos.create({
      image: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(225)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(300)), undefined, undefined),
      tags: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.medium)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      action: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      tag: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e7e7ee", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      tagText: Style$Pos.style(undefined, undefined, undefined, "#07061e", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function toLabel(value) {
  switch (value) {
    case /* All */0 :
        return Intl$Pos.t("All");
    case /* InvoicesAndCreditNotes */1 :
        return Intl$Pos.t("Invoices & credit notes");
    case /* ReceiptsAndRefunds */2 :
        return Intl$Pos.t("Receipts & refunds");
    
  }
}

function toRawString(value) {
  switch (value) {
    case /* All */0 :
        return Intl$Pos.t("ALL");
    case /* InvoicesAndCreditNotes */1 :
        return "INVOICES_AND_CREDIT_NOTES";
    case /* ReceiptsAndRefunds */2 :
        return "RECEIPTS";
    
  }
}

var values = [
  /* InvoicesAndCreditNotes */1,
  /* ReceiptsAndRefunds */2
];

var ExportType = {
  toLabel: toLabel,
  toRawString: toRawString,
  values: values
};

var endpoint = Env$Pos.sheetUrl(undefined) + "/sales/sales-export";

function encodeRequestBodyJson(shopIds, startDate, endDate, exportType) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "shopIds",
                    Json$Pos.encodeArray(Belt_Array.map(shopIds, Json$Pos.encodeString))
                  ],
                  [
                    "startDate",
                    Json$Pos.encodeNumber(startDate)
                  ],
                  [
                    "endDate",
                    Json$Pos.encodeNumber(endDate)
                  ],
                  [
                    "timeZone",
                    Json$Pos.encodeString(Intl$Pos.timeZone)
                  ],
                  [
                    "exportType",
                    Json$Pos.encodeString(toRawString(exportType))
                  ]
                ]));
}

function make(shopIds, startDate, endDate, exportType) {
  return Request$Pos.make("POST", Caml_option.some(encodeRequestBodyJson(shopIds, startDate, endDate, exportType)), undefined, undefined, endpoint);
}

var SalesExportRequest = {
  endpoint: endpoint,
  encodeRequestBodyJson: encodeRequestBodyJson,
  make: make
};

function SalesListPage$SalesExportButton(Props) {
  var shopIds = Props.shopIds;
  var startDate = Props.startDate;
  var endDate = Props.endDate;
  var exportType = Props.exportType;
  var text = Props.text;
  var request = Props.request;
  var onNotification = Props.onNotification;
  var captureEvent = SessionTracker$Pos.useCaptureEvent(undefined);
  var request$1 = function (param) {
    Curry._1(captureEvent, "download_sales_export_file");
    return Curry._4(request, shopIds, startDate, endDate, exportType);
  };
  var onFailure = function (error) {
    var errorMessage;
    if (typeof error === "number") {
      errorMessage = RequestOpenStorageUrlButton$Pos.failureErrorToString(error, Intl$Pos.t("sales"));
    } else {
      var match = error._0;
      if (typeof match === "number" || match.TAG !== /* InvalidRequestFailures */2) {
        errorMessage = RequestOpenStorageUrlButton$Pos.failureErrorToString(error, Intl$Pos.t("sales"));
      } else {
        var match$1 = match._0;
        if (match$1.length !== 1) {
          errorMessage = RequestOpenStorageUrlButton$Pos.failureErrorToString(error, Intl$Pos.t("sales"));
        } else {
          var match$2 = match$1[0];
          errorMessage = match$2.kind === "SalesExportDateRangeFailure" ? Intl$Pos.t("The sales cannot be exported for a period exceeding one year.") : RequestOpenStorageUrlButton$Pos.failureErrorToString(error, Intl$Pos.t("sales"));
        }
      }
    }
    Curry._1(onNotification, {
          TAG: /* Danger */1,
          _0: errorMessage
        });
  };
  var onSuccess = function (param) {
    Curry._1(onNotification, {
          TAG: /* Success */0,
          _0: Intl$Pos.t("The file has been downloaded successfully.")
        });
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request$1
  };
  return React.createElement(RequestOpenStorageUrlButton$Pos.make, {
              text: text,
              operableRequest: operableRequest,
              onFailure: onFailure,
              onSuccess: onSuccess
            });
}

var SalesExportButton = {
  make: SalesListPage$SalesExportButton
};

function SalesListPage(Props) {
  var request = Props.request;
  var scope = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var tmp;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    tmp = shop !== undefined ? [shop] : scope.shops;
  } else {
    tmp = [scope._0];
  }
  var shopIds = Belt_Array.map(tmp, (function (shop) {
          return shop.id;
        }));
  var shopName;
  if (scope.TAG === /* Organisation */0) {
    var shop$1 = scope.activeShop;
    shopName = shop$1 !== undefined ? shop$1.name : undefined;
  } else {
    shopName = undefined;
  }
  var match = React.useState(function () {
        var now = new Date(Date.now());
        return [
                DateHelpers$Pos.startOfMonth(now),
                now
              ];
      });
  var setDateRange = match[1];
  var match$1 = match[0];
  var endDate = match$1[1];
  var startDate = match$1[0];
  var formattedStartDate = Intl$Pos.dateTimeFormat(undefined, "short", startDate);
  var formattedEndDate = Intl$Pos.dateTimeFormat(undefined, "short", endDate);
  var match$2 = React.useState(function () {
        return /* All */0;
      });
  var setExportType = match$2[1];
  var exportType = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setNotfication = match$3[1];
  var notification = match$3[0];
  var onNotification = function (message) {
    Curry._1(setNotfication, (function (param) {
            return message;
          }));
  };
  var renderHeaderActions = function (param) {
    var items = Belt_Array.map(values, (function (value) {
            return {
                    key: toRawString(value),
                    value: value,
                    label: toLabel(value)
                  };
          }));
    return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                            children: null,
                            space: "small"
                          }, organisationAccount ? React.createElement(Auth$Pos.SelectShopFilter.make, {}) : null, organisationAccount ? React.createElement(Separator$Pos.make, {}) : null, React.createElement(Select$Pos.make, {
                                label: Intl$Pos.t("Type"),
                                sections: [
                                  {
                                    items: [{
                                        key: "default",
                                        value: /* All */0,
                                        label: Intl$Pos.template(Intl$Pos.t("All"), undefined, undefined),
                                        sticky: true
                                      }]
                                  },
                                  {
                                    items: items,
                                    title: Intl$Pos.t("Type of sales")
                                  }
                                ],
                                preset: "filter",
                                value: exportType,
                                onChange: (function (value) {
                                    Curry._1(setExportType, (function (param) {
                                            return Belt_Option.getWithDefault(value, /* All */0);
                                          }));
                                  })
                              }), React.createElement(SelectDateRangeFilter$Pos.make, {
                                value: [
                                  startDate,
                                  endDate
                                ],
                                placeholder: Intl$Pos.t("Select a period"),
                                disabledResetButton: true,
                                onChange: (function (dateRange) {
                                    if (dateRange === undefined) {
                                      return ;
                                    }
                                    var end = dateRange[1];
                                    var start = dateRange[0];
                                    Curry._1(setDateRange, (function (param) {
                                            return [
                                                    start,
                                                    end
                                                  ];
                                          }));
                                  }),
                                triggerLabelDisplay: "showPreset"
                              }))), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  };
  var tmp$1;
  switch (exportType) {
    case /* All */0 :
        tmp$1 = null;
        break;
    case /* InvoicesAndCreditNotes */1 :
        tmp$1 = React.createElement(Tag$Pos.make, {
              children: Intl$Pos.t("Invoices & credit notes")
            });
        break;
    case /* ReceiptsAndRefunds */2 :
        tmp$1 = React.createElement(Tag$Pos.make, {
              children: Intl$Pos.t("Receipts & refunds")
            });
        break;
    
  }
  return React.createElement(Page$Pos.make, {
              children: null,
              variation: "compact",
              title: Intl$Pos.t("Receipts & Invoices"),
              renderHeaderActions: renderHeaderActions
            }, notification !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                textStatus: notification
                              })), undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Card$Pos.make, {
                  children: null,
                  stretch: true,
                  centerContent: true
                }, React.createElement(Image$Pos.make, {
                      style: styles.image,
                      source: Image$Pos.fromUriSource(Image$Pos.uriSource(mainPlaceholder))
                    }), React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "small"
                    }, React.createElement(Title$Pos.make, {
                          children: Intl$Pos.t("Displaying receipts and invoices is not yet available."),
                          level: 2,
                          align: "center"
                        }), React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("In the meantime, you can still export them. The file also includes refunds and credits."),
                          align: "center"
                        }), React.createElement(Stack$Pos.make, {
                          children: null,
                          space: "large"
                        }, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                          children: null,
                                          space: "small"
                                        }, shopName !== undefined ? React.createElement(Tag$Pos.make, {
                                                children: shopName
                                              }) : null, tmp$1, React.createElement(Tag$Pos.make, {
                                              children: formattedStartDate === formattedEndDate ? Intl$Pos.template(Intl$Pos.t("On {{date}}"), {
                                                      date: formattedStartDate
                                                    }, undefined) : Intl$Pos.template(Intl$Pos.t("From {{startDate}} to {{endDate}}"), {
                                                      startDate: formattedStartDate,
                                                      endDate: formattedEndDate
                                                    }, undefined)
                                            }))), Caml_option.some(styles.tags), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(SalesListPage$SalesExportButton, {
                                          shopIds: shopIds,
                                          startDate: startDate.getTime(),
                                          endDate: endDate.getTime(),
                                          exportType: exportType,
                                          text: Intl$Pos.t("Export the document"),
                                          request: request,
                                          onNotification: onNotification
                                        })), Caml_option.some(styles.action), undefined, undefined, undefined))))));
}

var make$1 = SalesListPage;

export {
  mainPlaceholder ,
  styles ,
  ExportType ,
  SalesExportRequest ,
  SalesExportButton ,
  make$1 as make,
}
/* mainPlaceholder Not a pure module */
