// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FontSizes$Pos from "../theme/FontSizes.bs.js";

function borderColor(focused, hovered, errored, disabled) {
  if (disabled) {
    return "#e7e7ee";
  } else if (errored && focused) {
    return "#c81c51";
  } else if (errored) {
    return "#e186a2";
  } else if (focused) {
    return "#a2a1b0";
  } else if (hovered) {
    return "#bdbdca";
  } else {
    return "#d7d7e0";
  }
}

function backgroundColor(disabled) {
  if (disabled) {
    return "#f8f8fb";
  } else {
    return "#fefefe";
  }
}

function color(disabled) {
  if (disabled) {
    return "#8c8B9b";
  } else {
    return "#25243a";
  }
}

var fontSizePx = String(FontSizes$Pos.normal) + "px";

var borderRadiusPx = "5px";

var fontSize = FontSizes$Pos.normal;

export {
  borderColor ,
  borderRadiusPx ,
  backgroundColor ,
  color ,
  fontSize ,
  fontSizePx ,
}
/* fontSizePx Not a pure module */
