// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Svg$Pos from "../primitives/Svg.bs.js";
import * as Intl$Pos from "../primitives/Intl.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Spinner$Pos from "../resources/feedback-indicators/Spinner.bs.js";
import * as Touchable$Pos from "../primitives/Touchable.bs.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      PaginationSummary_root: {
        color: "#a2a1b0",
        font: "normal 700 12px \"Libre Franklin\"",
        lineHeight: "17px",
        display: "flex",
        gap: "8px",
        minWidth: "160px"
      }
    });

function ResourceListPagination$ResourceListPaginationSummary(Props) {
  var currentPage = Props.currentPage;
  var totalCount = Props.totalCount;
  var loading = Props.loading;
  var itemsPerPage = Props.itemsPerPage;
  var match = Stylex.props(styles.PaginationSummary_root);
  var start = String(Math.imul(currentPage - 1 | 0, itemsPerPage) + 1 | 0);
  var end = String(Math.min(Math.imul(currentPage, itemsPerPage), totalCount));
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement("span", tmp, start + " — " + end + Intl$Pos.t(" of ") + String(totalCount), loading ? React.createElement(Spinner$Pos.make, {
                    size: 17
                  }) : null);
}

var styles$1 = Stylex.create({
      PaginationPageButton_root: {
        boxSizing: "border-box",
        border: "1px solid #d7d7e0",
        borderRadius: "5px",
        transition: "all .1s ease-out",
        display: "flex",
        height: "32px",
        justifyContent: "center",
        width: "32px",
        ":hover": {
          backgroundColor: "#f8f8fb",
          borderColor: "#a2a1b0"
        }
      },
      PaginationPageButton_disabled: {
        backgroundColor: "#e9e9ee",
        border: "1px solid #bdbdca",
        ":hover": {
          backgroundColor: "#e9e9ee",
          borderColor: "#bdbdca"
        }
      },
      PaginationPageButton_text: {
        color: "#797885",
        font: "normal 400 13px \"Libre Franklin\"",
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "32px"
      },
      PaginationPageButton_textDisabled: {
        color: "#25243a",
        font: "normal 600 13px \"Libre Franklin\""
      }
    });

function styleProps(disabled, pressed) {
  return Stylex.props(styles$1.PaginationPageButton_root, disabled ? styles$1.PaginationPageButton_disabled : ({}), {
              transform: pressed ? "scale3d(0.94, 0.94, 1)" : "initial"
            });
}

function textStyleProps(disabled) {
  return Stylex.props(styles$1.PaginationPageButton_text, disabled ? styles$1.PaginationPageButton_textDisabled : ({}));
}

function ResourceListPagination$ResourceListPaginationPageButton(Props) {
  var loading = Props.loading;
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var action = Props.action;
  var children = Props.children;
  var onPress = Props.onPress;
  var match = React.useState(function () {
        return false;
      });
  var setPressed = match[1];
  var buttonProps_elementType = "div";
  var buttonProps_onPressStart = (function (param) {
      Curry._1(setPressed, (function (param) {
              return true;
            }));
    });
  var buttonProps_onPressEnd = (function (param) {
      Curry._1(setPressed, (function (param) {
              return false;
            }));
    });
  var buttonProps = {
    elementType: buttonProps_elementType,
    onPressStart: buttonProps_onPressStart,
    onPressEnd: buttonProps_onPressEnd
  };
  var disabled = Belt_Option.mapWithDefault(action, true, (function (action) {
          switch (action) {
            case /* PrevPrev */1 :
                return (currentPage - 1 | 0) <= 1;
            case /* First */0 :
            case /* Prev */2 :
                return currentPage <= 1;
            case /* NextNext */4 :
                return (currentPage - 1 | 0) >= totalPages;
            case /* Next */3 :
            case /* Last */5 :
                return currentPage >= totalPages;
            
          }
        }));
  var match$1 = styleProps(disabled, match[0]);
  var match$2 = textStyleProps(disabled);
  var disabled$1 = loading || disabled;
  var tmp = {};
  if (match$1.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$1.style);
  }
  var tmp$1 = {};
  if (match$2.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(match$2.style);
  }
  var tmp$2;
  if (children !== undefined) {
    tmp$2 = Caml_option.valFromOption(children);
  } else if (action !== undefined) {
    switch (action) {
      case /* First */0 :
          tmp$2 = 1;
          break;
      case /* Prev */2 :
          tmp$2 = React.createElement(Svg$Pos.make, {
                children: React.createElement(Svg$Pos.Path.make, {
                      d: "M13.328 15.698a.5.5 0 1 1-.712.702l-5.972-6.049a.5.5 0 0 1 .002-.704l5.972-6a.5.5 0 1 1 .708.706L7.704 10l5.624 5.697z",
                      fill: "#797885"
                    }),
                width: "20",
                height: "20",
                viewBox: "0 0 20 20"
              });
          break;
      case /* Next */3 :
          tmp$2 = React.createElement(Svg$Pos.make, {
                children: React.createElement(Svg$Pos.Path.make, {
                      d: "M12.268 10.048 6.644 4.35a.5.5 0 1 1 .712-.702l5.972 6.049a.5.5 0 0 1-.002.704l-5.972 6a.5.5 0 0 1-.708-.706l5.622-5.648z",
                      fill: "#797885"
                    }),
                width: "20",
                height: "20",
                viewBox: "0 0 20 20"
              });
          break;
      case /* PrevPrev */1 :
      case /* NextNext */4 :
          tmp$2 = null;
          break;
      case /* Last */5 :
          tmp$2 = totalPages;
          break;
      
    }
  } else {
    tmp$2 = null;
  }
  return React.createElement("div", tmp, React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement("span", tmp$1, tmp$2), buttonProps, disabled$1, undefined, undefined, undefined, (function (param) {
                        if (action !== undefined) {
                          return Curry._1(onPress, action);
                        }
                        
                      }), undefined, undefined, undefined)));
}

var make = React.memo(ResourceListPagination$ResourceListPaginationPageButton);

var styles$2 = Stylex.create({
      PaginationScrollToTopButton_root: {
        boxSizing: "border-box",
        border: "1px solid #d7d7e0",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "all .1s ease-out",
        boxShadow: "0px 0px 5px 1px #e7e7ee",
        display: "flex",
        height: "32px",
        justifyContent: "center",
        width: "40px",
        ":hover": {
          backgroundColor: "#f8f8fb",
          borderColor: "#bdbdca"
        }
      }
    });

function styleProps$1(pressed, show) {
  return Stylex.props(styles$2.PaginationScrollToTopButton_root, {
              transform: pressed ? "scale3d(0.94, 0.94, 1)" : "initial"
            }, {
              opacity: show ? 1 : 0,
              transition: "opacity .15s ease-out"
            });
}

function ResourceListPagination$ResourceListPaginationScrollToTopButton(Props) {
  var match = React.useState(function () {
        return false;
      });
  var setPressed = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShow = match$1[1];
  React.useLayoutEffect((function () {
          var handler = function (param) {
            Curry._1(setShow, (function (param) {
                    return window.scrollY > 15;
                  }));
          };
          handler(undefined);
          window.addEventListener("scroll", handler);
          return (function (param) {
                    window.removeEventListener("scroll", handler);
                  });
        }), []);
  var buttonProps_elementType = "div";
  var buttonProps_onPressStart = (function (param) {
      Curry._1(setPressed, (function (param) {
              return true;
            }));
    });
  var buttonProps_onPressEnd = (function (param) {
      Curry._1(setPressed, (function (param) {
              return false;
            }));
    });
  var buttonProps = {
    elementType: buttonProps_elementType,
    onPressStart: buttonProps_onPressStart,
    onPressEnd: buttonProps_onPressEnd
  };
  var handleButtonPress = function (param) {
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
  };
  var match$2 = styleProps$1(match[0], match$1[0]);
  var tmp = {};
  if (match$2.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$2.style);
  }
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement("div", tmp, React.createElement(Svg$Pos.make, {
                          children: React.createElement(Svg$Pos.Path.make, {
                                d: "M 11.236 4.018 L 16.683 9.464 L 1.97 9.464 C 1.559 9.464 1.301 9.91 1.508 10.267 C 1.603 10.433 1.779 10.535 1.97 10.535 L 16.799 10.535 L 11.352 15.981 C 11.06 16.272 11.194 16.768 11.592 16.876 C 11.775 16.926 11.973 16.873 12.108 16.738 L 18.408 10.436 C 18.618 10.227 18.618 9.887 18.408 9.679 L 11.991 3.263 C 11.784 3.053 11.444 3.053 11.236 3.263 C 11.027 3.47 11.027 3.81 11.236 4.018 Z",
                                fill: "#25243a",
                                transform: "rotate(270 10.5 11.5)"
                              }),
                          width: "20",
                          height: "20",
                          viewBox: "0 0 18 16",
                          style: {
                            paddingTop: "2px"
                          }
                        })), buttonProps, undefined, undefined, undefined, undefined, handleButtonPress, undefined, undefined, undefined));
}

var make$1 = React.memo(ResourceListPagination$ResourceListPaginationScrollToTopButton);

var styles$3 = Stylex.create({
      Pagination_root: {
        backgroundColor: "#fefefe",
        borderTop: "1px solid #e7e7ee",
        alignItems: "center",
        bottom: "0px",
        display: "flex",
        flexDirection: "row",
        height: "60px",
        justifyContent: "center",
        paddingHorizontal: "20px",
        position: "sticky",
        zIndex: 2
      },
      Pagination_controls: {
        transform: "translateX(-80px)",
        alignItems: "center",
        display: "flex",
        gap: "4px",
        margin: "auto"
      }
    });

var separatorIconElement = React.createElement(Svg$Pos.make, {
      children: React.createElement(Svg$Pos.Path.make, {
            d: "M4,11 C4.55228475,11 5,10.5522847 5,10 C5,9.44771525 4.55228475,9 4,9 C3.44771525,9 3,9.44771525 3,10 C3,10.5522847 3.44771525,11 4,11 Z M10,11 C10.5522847,11 11,10.5522847 11,10 C11,9.44771525 10.5522847,9 10,9 C9.44771525,9 9,9.44771525 9,10 C9,10.5522847 9.44771525,11 10,11 Z M16,11 C16.5522847,11 17,10.5522847 17,10 C17,9.44771525 16.5522847,9 16,9 C15.4477153,9 15,9.44771525 15,10 C15,10.5522847 15.4477153,11 16,11 Z",
            fill: "#797885"
          }),
      width: "20",
      height: "20",
      viewBox: "0 0 20 20"
    });

function ResourceListPagination(Props) {
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var totalCount = Props.totalCount;
  var loading = Props.loading;
  var lastDirection = Props.lastDirection;
  var itemsPerPage = Props.itemsPerPage;
  var onPress = Props.onRequestPaginate;
  var match = Stylex.props(styles$3.Pagination_root);
  var match$1 = Stylex.props(styles$3.Pagination_controls);
  React.useEffect((function () {
          if (!loading) {
            window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
          }
          
        }), [
        loading,
        currentPage
      ]);
  var notFirstPages = currentPage > 1 && totalPages !== 2;
  var notLastPages = currentPage < (totalPages - 1 | 0);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  var tmp$1 = {};
  if (match$1.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(match$1.style);
  }
  return React.createElement("div", tmp, React.createElement(ResourceListPagination$ResourceListPaginationSummary, {
                  currentPage: currentPage,
                  totalCount: totalCount,
                  loading: loading,
                  itemsPerPage: itemsPerPage
                }), React.createElement("div", tmp$1, React.createElement(make, {
                      loading: loading,
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Prev */2,
                      onPress: onPress
                    }), React.createElement(make, {
                      loading: loading,
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* First */0,
                      onPress: onPress
                    }), (
                  lastDirection ? false : true
                ) && (notLastPages || totalPages === 1) || !(
                  lastDirection ? false : true
                ) && currentPage < 3 ? React.createElement(React.Fragment, undefined, currentPage > 2 ? separatorIconElement : null, notFirstPages ? React.createElement(make, {
                              loading: loading,
                              currentPage: currentPage,
                              totalPages: totalPages,
                              children: currentPage,
                              onPress: onPress
                            }) : null, totalPages !== 2 && notLastPages ? React.createElement(make, {
                              loading: loading,
                              currentPage: currentPage,
                              totalPages: totalPages,
                              action: /* Next */3,
                              children: currentPage + 1 | 0,
                              onPress: onPress
                            }) : null, currentPage !== 2 && currentPage < (totalPages - 2 | 0) ? React.createElement(make, {
                              loading: loading,
                              currentPage: currentPage,
                              totalPages: totalPages,
                              action: /* NextNext */4,
                              children: currentPage + 2 | 0,
                              onPress: onPress
                            }) : null, currentPage !== totalPages && currentPage < (totalPages - 3 | 0) ? separatorIconElement : null) : React.createElement(React.Fragment, undefined, currentPage > 4 && totalPages >= 4 ? separatorIconElement : null, currentPage !== 2 && currentPage > 3 && totalPages > 3 && (currentPage < (totalPages - 1 | 0) || currentPage === totalPages) ? React.createElement(make, {
                              loading: loading,
                              currentPage: currentPage,
                              totalPages: totalPages,
                              action: /* PrevPrev */1,
                              children: currentPage - 2 | 0,
                              onPress: onPress
                            }) : null, notFirstPages && (totalPages > 3 || currentPage === totalPages) ? React.createElement(make, {
                              loading: loading,
                              currentPage: currentPage,
                              totalPages: totalPages,
                              action: /* Prev */2,
                              children: currentPage - 1 | 0,
                              onPress: onPress
                            }) : null, notFirstPages && currentPage !== totalPages ? React.createElement(make, {
                              loading: loading,
                              currentPage: currentPage,
                              totalPages: totalPages,
                              children: currentPage,
                              onPress: onPress
                            }) : null, currentPage !== totalPages && currentPage < (totalPages - 1 | 0) ? separatorIconElement : null), totalPages !== 1 ? React.createElement(make, {
                        loading: loading,
                        currentPage: currentPage,
                        totalPages: totalPages,
                        action: /* Last */5,
                        onPress: onPress
                      }) : null, React.createElement(make, {
                      loading: loading,
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Next */3,
                      onPress: onPress
                    })), React.createElement(make$1, {}));
}

var make$2 = React.memo(ResourceListPagination);

export {
  make$2 as make,
}
/* styles Not a pure module */
