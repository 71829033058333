// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as InputSearch$Pos from "./InputSearch.bs.js";
import * as SearchListItem$Pos from "./SearchListItem.bs.js";
import * as ReactDomElement$Pos from "../../primitives/ReactDomElement.bs.js";
import * as ReactUpdateDebounced$Pos from "../../primitives/ReactUpdateDebounced.bs.js";

var styles = StyleSheet$Pos.create({
      wrapper: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined),
      append: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#FFF", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(40)), "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "absolute", Caml_option.some(Style$Pos.dp(0)), undefined, Caml_option.some(Style$Pos.dp(0)), Caml_option.some(Style$Pos.dp(40)), undefined, undefined),
      itemsList: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, 5, undefined, undefined, "#d7d7e0", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(210)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  overflow: "auto"
                })
          ])
    });

function wrapperStyleFromParams(expendableWidth) {
  return Style$Pos.arrayOptionStyle([expendableWidth ? Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)) : undefined]);
}

function SearchListPopover(Props) {
  var children = Props.children;
  var expendableWidth = Props.expendableWidth;
  var items = Props.items;
  var itemToValue = Props.itemToValue;
  var itemToOption = Props.itemToOption;
  var opened = Props.opened;
  var searchQueryDelayOpt = Props.searchQueryDelay;
  var onSearchQueryChange = Props.onSearchQueryChange;
  var onChange = Props.onChange;
  var onToggle = Props.onToggle;
  var onEndReached = Props.onEndReached;
  var renderEndItemOpt = Props.renderEndItem;
  var renderEndActions = Props.renderEndActions;
  var searchQueryDelay = searchQueryDelayOpt !== undefined ? searchQueryDelayOpt : 400;
  var renderEndItem = renderEndItemOpt !== undefined ? renderEndItemOpt : (function (param) {
        return null;
      });
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var triggerHovered = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$2[1];
  var searchQuery = match$2[0];
  var debouncedSearchQuery = ReactUpdateDebounced$Pos.use(searchQuery, searchQueryDelay);
  var textInputRef = React.useRef(null);
  var itemsListRef = React.useRef(null);
  React.useEffect((function () {
          if (opened !== undefined && !opened) {
            Curry._1(popover.close, undefined);
          }
          
        }), [opened]);
  React.useEffect((function () {
          var domElement = ReactDomElement$Pos.fromRef(itemsListRef);
          var handler = function (param) {
            Belt_Option.map(domElement, (function (domElement) {
                    var offsetY = domElement.scrollHeight;
                    var scrolledY = domElement.scrollTop + domElement.offsetHeight;
                    var offsetFromEndReached = offsetY - (scrolledY | 0) | 0;
                    if (offsetFromEndReached < 100) {
                      return Curry._1(onEndReached, offsetFromEndReached);
                    }
                    
                  }));
          };
          Belt_Option.map(domElement, (function (domElement) {
                  domElement.addEventListener("scroll", handler);
                }));
          return (function (param) {
                    Belt_Option.map(domElement, (function (domElement) {
                            domElement.removeEventListener("scroll", handler);
                          }));
                  });
        }), [itemsListRef.current]);
  React.useEffect((function () {
          Curry._1(onToggle, popover.isOpen);
        }), [
        popover.isOpen,
        textInputRef.current
      ]);
  React.useEffect((function () {
          if (onSearchQueryChange !== undefined) {
            Curry._1(onSearchQueryChange, debouncedSearchQuery);
          }
          
        }), [debouncedSearchQuery]);
  var renderedItems = React.useMemo((function () {
          if (items !== undefined) {
            return Belt_Array.mapWithIndex(items, (function (index, item) {
                          return React.createElement(SearchListItem$Pos.make, {
                                      name: Curry._1(itemToOption, item),
                                      onPress: (function (param) {
                                          Curry._1(onChange, item);
                                          Curry._1(popover.close, undefined);
                                        }),
                                      key: Curry._1(itemToValue, item) + String(index)
                                    });
                        }));
          } else {
            return [];
          }
        }), [items]);
  var popover_isOpen = popover.isOpen;
  var popover_close = function (param) {
    if (!triggerHovered) {
      return Curry._1(popover.close, undefined);
    }
    
  };
  var popover_toggle = popover.toggle;
  var popover$1 = {
    isOpen: popover_isOpen,
    close: popover_close,
    toggle: popover_toggle
  };
  return React.createElement(React.Fragment, undefined, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(children, popoverAriaProps.triggerProps, undefined, undefined, undefined, undefined, (function (param) {
                                    Curry._1(popover_toggle, undefined);
                                  }), undefined, popoverTriggerRef, undefined))), Caml_option.some(Style$Pos.arrayStyle([
                              styles.wrapper,
                              wrapperStyleFromParams(expendableWidth)
                            ])), undefined, undefined, undefined)), popover_isOpen ? React.createElement(Popover$Pos.make, {
                    children: React.createElement(Popover$Pos.Dialog.make, {
                          children: null,
                          ariaProps: popoverAriaProps.overlayProps
                        }, React.createElement(InputSearch$Pos.make, {
                              loading: Belt_Option.isNone(items),
                              onRequestClear: (function (param) {
                                  Curry._1(setSearchQuery, (function (param) {
                                          return "";
                                        }));
                                }),
                              bordered: false,
                              placeholder: Intl$Pos.t("Search"),
                              autoFocus: true,
                              value: searchQuery,
                              onChange: (function (searchQuery) {
                                  Curry._1(setSearchQuery, (function (param) {
                                          return searchQuery;
                                        }));
                                })
                            }), items !== undefined ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.itemsList), undefined, itemsListRef, undefined), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                children: Intl$Pos.t("Search results"),
                                                variation: "normal",
                                                size: "xxsmall"
                                              })), undefined, undefined, "small", "xxsmall", "xnormal", undefined, undefined, undefined, undefined, undefined, undefined)), Belt_Array.concat(renderedItems, [React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(Curry._1(renderEndItem, undefined)), undefined, "end-list-item", undefined, undefined))]), renderEndActions !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                      children: Intl$Pos.t("Other suggestions"),
                                                      variation: "normal",
                                                      size: "xxsmall"
                                                    })), undefined, undefined, "xsmall", "xxsmall", "xnormal", undefined, undefined, undefined, undefined, undefined, undefined)), Belt_Array.mapWithIndex(Curry._1(renderEndActions, undefined), (function (index, element) {
                                            return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(element), undefined, String(index), undefined, undefined));
                                          })), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined))) : null) : React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(SearchListItem$Pos.make, {
                                            children: Curry._1(renderEndItem, undefined),
                                            key: "end-list-item"
                                          })), Caml_option.some(styles.itemsList), undefined, undefined, undefined))),
                    triggerRef: popoverTriggerRef,
                    state: popover$1,
                    placement: "bottom start",
                    layout: expendableWidth ? "triggerMinWidth" : "auto",
                    modal: false
                  }) : null);
}

var make = SearchListPopover;

export {
  make ,
}
/* styles Not a pure module */
