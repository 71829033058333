// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Icon$Pos from "../../resources/images-and-icons/Icon.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Image$Pos from "../../primitives/Image.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../../resources/theme/FontSizes.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import Wino_logoPng from "./wino_logo.png";

var styles = StyleSheet$Pos.create({
      base: Style$Pos.merge([
            FontFaces$Pos.libreFranklinSemiBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, "#25243a", undefined, FontSizes$Pos.normal, undefined, undefined, undefined, undefined, 0.125, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      hovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.brandColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "underline", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function styleFromParams(hovered) {
  return Style$Pos.arrayStyle([
              styles.base,
              hovered ? styles.hovered : Style$Pos.emptyStyle
            ]);
}

function iconColorFromHovered(hovered) {
  if (hovered) {
    return Colors$Pos.brandColor60;
  } else {
    return "#07061e";
  }
}

function AuthSignupSuccessPage$GettingStartedTextIconLink(Props) {
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  return React.createElement(Navigation$Pos.Link.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                children: null,
                                space: "small"
                              }, React.createElement(Icon$Pos.make, {
                                    name: "book_bold",
                                    fill: hovered ? Colors$Pos.brandColor60 : "#07061e"
                                  }), React.createElement(Text$Pos.make, Text$Pos.makeProps(Intl$Pos.t("Getting started guide"), Caml_option.some(styleFromParams(hovered)), undefined, undefined, undefined)))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, match[0], undefined)),
              to: {
                TAG: /* Url */2,
                _0: new URL(HelpCenter$Pos.gettingStartedGuideLink)
              },
              openNewTab: true
            });
}

var GettingStartedTextIconLink = {
  styles: styles,
  styleFromParams: styleFromParams,
  iconColorFromHovered: iconColorFromHovered,
  make: AuthSignupSuccessPage$GettingStartedTextIconLink
};

var imageUri = Wino_logoPng;

var styles$1 = StyleSheet$Pos.create({
      image: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(40)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(40)), undefined, undefined)
    });

function AuthSignupSuccessPage$Header(Props) {
  return React.createElement(Inline$Pos.make, {
              children: null,
              align: "spaceBetween"
            }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                              children: null,
                              space: "medium"
                            }, React.createElement(Image$Pos.make, {
                                  style: styles$1.image,
                                  source: Image$Pos.fromUriSource(Image$Pos.uriSource(imageUri))
                                }), React.createElement(TextStyle$Pos.make, {
                                  children: Intl$Pos.t("How to contact us? By email at hello@wino.fr"),
                                  size: "normal"
                                }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(AuthSignupSuccessPage$GettingStartedTextIconLink, {})), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var Header = {
  imageUri: imageUri,
  styles: styles$1,
  make: AuthSignupSuccessPage$Header
};

var styles$2 = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(1480)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xxhuge)), undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xlarge)), undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xlarge)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined),
      content: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(75)), "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(724)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

var textConfirmation = Intl$Pos.t("A confirmation email will be sent to you in the next few minutes. Our team will contact you soon to set up the software. You can now close this window.");

function AuthSignupSuccessPage(Props) {
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$2.container), undefined, undefined, undefined), React.createElement(AuthSignupSuccessPage$Header, {}), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                              children: null,
                              space: "normal",
                              align: "center"
                            }, React.createElement(Title$Pos.make, {
                                  children: Intl$Pos.t("🎉 Your account has been created successfully!"),
                                  level: 1,
                                  align: "center"
                                }), React.createElement(TextStyle$Pos.make, {
                                  children: textConfirmation,
                                  align: "center",
                                  variation: "normal"
                                }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(styles$2.content), undefined, undefined, undefined)));
}

var make = React.memo(AuthSignupSuccessPage);

export {
  GettingStartedTextIconLink ,
  Header ,
  styles$2 as styles,
  textConfirmation ,
  make ,
}
/* styles Not a pure module */
