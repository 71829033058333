// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextLink$Pos from "../../resources/navigation/TextLink.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";

function CatalogProductNameTableCell(Props) {
  var cku = Props.cku;
  var name = Props.name;
  var shopName = Props.shopName;
  var openNewTabOpt = Props.openNewTab;
  var openNewTab = openNewTabOpt !== undefined ? openNewTabOpt : false;
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                          children: null,
                          space: Belt_Option.isSome(shopName) ? "xxsmall" : "none"
                        }, React.createElement(TextLink$Pos.make, {
                              text: Intl$Pos.t(name),
                              to: {
                                TAG: /* Route */0,
                                _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/" + cku
                              },
                              openNewTab: openNewTab
                            }), shopName !== undefined ? React.createElement(TextStyle$Pos.make, {
                                children: shopName,
                                variation: "normal",
                                size: "xxsmall"
                              }) : null)), undefined, "xsmall", undefined, undefined, undefined, "normal", undefined, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined));
}

var make = React.memo(CatalogProductNameTableCell);

export {
  make ,
}
/* make Not a pure module */
