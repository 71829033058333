// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as MenuItem$Pos from "../../resources/navigation/MenuItem.bs.js";
import * as PromotionEditFormMutations$Pos from "./PromotionEditFormMutations.bs.js";

function PromotionEditActions(Props) {
  var variation = Props.variation;
  var id = Props.id;
  var status = Props.status;
  var scope = Auth$Pos.useScope(undefined);
  var startCampaign = PromotionEditFormMutations$Pos.useStartCampaign(id);
  var stopCampaign = PromotionEditFormMutations$Pos.useStopCampaign(id);
  if (!(status === "ONGOING" || status === "NOT_PROGRAMMED" || status === "PROGRAMMED")) {
    return null;
  }
  if (variation === "actionBar") {
    if (!(status === "ONGOING" || status === "PROGRAMMED")) {
      if (status === "NOT_PROGRAMMED" && scope.TAG !== /* Organisation */0) {
        return React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Schedule"), "medium", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          Curry._1(startCampaign, status);
                        }), undefined, undefined, undefined));
      } else {
        return null;
      }
    }
    if (scope.TAG === /* Organisation */0) {
      return null;
    }
    var label = status === "PROGRAMMED" ? Intl$Pos.t("Unschedule") : Intl$Pos.t("Stop");
    return React.createElement(Button$Pos.make, Button$Pos.makeProps(label, "medium", "danger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(stopCampaign, status);
                    }), undefined, undefined, undefined));
  }
  var tmp;
  if (status === "ONGOING" || status === "PROGRAMMED") {
    var text = {
      TAG: /* Text */0,
      _0: status === "PROGRAMMED" ? Intl$Pos.t("Unschedule") : Intl$Pos.t("Stop")
    };
    tmp = React.createElement(MenuItem$Pos.make, {
          content: text,
          action: {
            TAG: /* Callback */0,
            _0: (function (param) {
                Curry._1(stopCampaign, status);
              })
          }
        });
  } else {
    tmp = status === "NOT_PROGRAMMED" ? React.createElement(MenuItem$Pos.make, {
            content: {
              TAG: /* Text */0,
              _0: Intl$Pos.t("Schedule")
            },
            action: {
              TAG: /* Callback */0,
              _0: (function (param) {
                  Curry._1(startCampaign, status);
                })
            }
          }) : null;
  }
  return React.createElement(Inline$Pos.make, {
              children: React.createElement(Menu$Pos.make, {
                    children: tmp,
                    variation: "more_round"
                  }),
              align: "end"
            });
}

var make = React.memo(PromotionEditActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
