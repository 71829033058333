// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Client from "@apollo/client";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var DuplicateProduct_SourceIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogDuplicationFormMutations-Pos.DuplicateProduct_SourceIdNotFound");

var DuplicateProduct_DestinationIdsMissing = /* @__PURE__ */Caml_exceptions.create("CatalogDuplicationFormMutations-Pos.DuplicateProduct_DestinationIdsMissing");

var DuplicateVariant_SourceIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogDuplicationFormMutations-Pos.DuplicateVariant_SourceIdNotFound");

var DuplicateVariant_DestinationIdsMissing = /* @__PURE__ */Caml_exceptions.create("CatalogDuplicationFormMutations-Pos.DuplicateVariant_DestinationIdsMissing");

var Raw = {};

var query = Client.gql(["mutation duplicateProduct($id: ID!, $input: InputDuplicateProduct!)  {\nduplicateProduct(id: $id, input: $input)  {\n__typename  \nid  \nname  \nshop  {\n__typename  \nname  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.duplicateProduct;
  var value$2 = value$1.shop;
  return {
          duplicateProduct: {
            __typename: value$1.__typename,
            id: value$1.id,
            name: value$1.name,
            shop: {
              __typename: value$2.__typename,
              name: value$2.name
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.duplicateProduct;
  var value$2 = value$1.shop;
  var value$3 = value$2.name;
  var value$4 = value$2.__typename;
  var shop = {
    __typename: value$4,
    name: value$3
  };
  var value$5 = value$1.name;
  var value$6 = value$1.id;
  var value$7 = value$1.__typename;
  var duplicateProduct = {
    __typename: value$7,
    id: value$6,
    name: value$5,
    shop: shop
  };
  return {
          duplicateProduct: duplicateProduct
        };
}

function serializeInputObjectInputDuplicateProductLinkTo(inp) {
  var a = inp.categoryId;
  return {
          shopId: inp.shopId,
          categoryId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputDuplicateProduct(inp) {
  var a = inp.linkTo;
  return {
          linkTo: Js_array.map(serializeInputObjectInputDuplicateProductLinkTo, a)
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputDuplicateProduct(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputDuplicateProduct(linkTo, param) {
  return {
          linkTo: linkTo
        };
}

function makeInputObjectInputDuplicateProductLinkTo(shopId, categoryId, param) {
  return {
          shopId: shopId,
          categoryId: categoryId
        };
}

var DuplicateProductMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputDuplicateProduct: serializeInputObjectInputDuplicateProduct,
  serializeInputObjectInputDuplicateProductLinkTo: serializeInputObjectInputDuplicateProductLinkTo,
  makeVariables: makeVariables,
  makeInputObjectInputDuplicateProduct: makeInputObjectInputDuplicateProduct,
  makeInputObjectInputDuplicateProductLinkTo: makeInputObjectInputDuplicateProductLinkTo
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var DuplicateProductMutation_useWithVariables = include.useWithVariables;

var DuplicateProductMutation = {
  DuplicateProductMutation_inner: DuplicateProductMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputDuplicateProduct: serializeInputObjectInputDuplicateProduct,
  serializeInputObjectInputDuplicateProductLinkTo: serializeInputObjectInputDuplicateProductLinkTo,
  makeVariables: makeVariables,
  makeInputObjectInputDuplicateProduct: makeInputObjectInputDuplicateProduct,
  makeInputObjectInputDuplicateProductLinkTo: makeInputObjectInputDuplicateProductLinkTo,
  use: use,
  useWithVariables: DuplicateProductMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation duplicateVariant($id: ID!, $input: InputDuplicateVariant!)  {\nduplicateVariant(id: $id, input: $input)  {\n__typename  \nid  \nname  \nshop  {\n__typename  \nname  \n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.duplicateVariant;
  var value$2 = value$1.shop;
  return {
          duplicateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            name: value$1.name,
            shop: {
              __typename: value$2.__typename,
              name: value$2.name
            }
          }
        };
}

function serialize$1(value) {
  var value$1 = value.duplicateVariant;
  var value$2 = value$1.shop;
  var value$3 = value$2.name;
  var value$4 = value$2.__typename;
  var shop = {
    __typename: value$4,
    name: value$3
  };
  var value$5 = value$1.name;
  var value$6 = value$1.id;
  var value$7 = value$1.__typename;
  var duplicateVariant = {
    __typename: value$7,
    id: value$6,
    name: value$5,
    shop: shop
  };
  return {
          duplicateVariant: duplicateVariant
        };
}

function serializeInputObjectInputDuplicateVariant(inp) {
  var a = inp.linkTo;
  return {
          linkTo: Js_array.map((function (b) {
                  return {
                          productId: b.productId
                        };
                }), a)
        };
}

function serializeInputObjectInputDuplicateVariantLinkTo(inp) {
  return {
          productId: inp.productId
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputDuplicateVariant(inp.input)
        };
}

function makeVariables$1(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputDuplicateVariant(linkTo, param) {
  return {
          linkTo: linkTo
        };
}

function makeInputObjectInputDuplicateVariantLinkTo(productId, param) {
  return {
          productId: productId
        };
}

var DuplicateVariantMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputDuplicateVariant: serializeInputObjectInputDuplicateVariant,
  serializeInputObjectInputDuplicateVariantLinkTo: serializeInputObjectInputDuplicateVariantLinkTo,
  makeVariables: makeVariables$1,
  makeInputObjectInputDuplicateVariant: makeInputObjectInputDuplicateVariant,
  makeInputObjectInputDuplicateVariantLinkTo: makeInputObjectInputDuplicateVariantLinkTo
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var DuplicateVariantMutation_useWithVariables = include$1.useWithVariables;

var DuplicateVariantMutation = {
  DuplicateVariantMutation_inner: DuplicateVariantMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputDuplicateVariant: serializeInputObjectInputDuplicateVariant,
  serializeInputObjectInputDuplicateVariantLinkTo: serializeInputObjectInputDuplicateVariantLinkTo,
  makeVariables: makeVariables$1,
  makeInputObjectInputDuplicateVariant: makeInputObjectInputDuplicateVariant,
  makeInputObjectInputDuplicateVariantLinkTo: makeInputObjectInputDuplicateVariantLinkTo,
  use: use$1,
  useWithVariables: DuplicateVariantMutation_useWithVariables
};

function useDuplicateProduct(param) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return function (state) {
                  var match = state.source;
                  var id;
                  if (match !== undefined) {
                    if (match.TAG === /* Product */0) {
                      id = match._0.id;
                    } else {
                      throw {
                            RE_EXN_ID: DuplicateProduct_SourceIdNotFound,
                            Error: new Error()
                          };
                    }
                  } else {
                    throw {
                          RE_EXN_ID: DuplicateProduct_SourceIdNotFound,
                          Error: new Error()
                        };
                  }
                  var linkTo = Belt_Array.keepMap(state.destinations, (function (destination) {
                          if (destination.TAG === /* Category */0) {
                            var match = destination._0;
                            var shopId = match.shopId;
                            if (shopId !== undefined) {
                              return [
                                      shopId,
                                      match.id
                                    ];
                            } else {
                              return ;
                            }
                          }
                          var shopId$1 = destination._0.shopId;
                          if (shopId$1 !== undefined) {
                            return [
                                    shopId$1,
                                    undefined
                                  ];
                          }
                          
                        }));
                  var input = {
                    linkTo: Belt_Array.map(linkTo, (function (param) {
                            return {
                                    shopId: param[0],
                                    categoryId: param[1]
                                  };
                          }))
                  };
                  if (linkTo.length !== state.destinations.length) {
                    throw {
                          RE_EXN_ID: DuplicateProduct_DestinationIdsMissing,
                          Error: new Error()
                        };
                  }
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: id,
                                      input: input
                                    })), undefined, (function (param) {
                                var match = param.duplicateProduct;
                                return Intl$Pos.template(linkTo.length > 1 ? Intl$Pos.t("The product \"{{referenceName}}\" from catalog {{shopName}} has been correctly added to the selected shops.") : Intl$Pos.t("The product \"{{referenceName}}\" from catalog {{shopName}} has been correctly added to your shop."), {
                                            referenceName: match.name,
                                            shopName: match.shop.name
                                          }, undefined);
                              }));
                };
              }), []);
}

function useDuplicateVariant(param) {
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return function (state) {
                  var match = state.source;
                  var id;
                  if (match !== undefined) {
                    if (match.TAG === /* Product */0) {
                      throw {
                            RE_EXN_ID: DuplicateVariant_SourceIdNotFound,
                            Error: new Error()
                          };
                    }
                    id = match._0.id;
                  } else {
                    throw {
                          RE_EXN_ID: DuplicateVariant_SourceIdNotFound,
                          Error: new Error()
                        };
                  }
                  var productIds = Belt_Array.keepMap(state.destinations, (function (x) {
                          if (x.TAG === /* Category */0) {
                            return ;
                          } else {
                            return x._0.id;
                          }
                        }));
                  var input = {
                    linkTo: Belt_Array.map(productIds, (function (productId) {
                            return {
                                    productId: productId
                                  };
                          }))
                  };
                  if (productIds.length !== state.destinations.length) {
                    throw {
                          RE_EXN_ID: DuplicateVariant_DestinationIdsMissing,
                          Error: new Error()
                        };
                  }
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: id,
                                      input: input
                                    })), undefined, (function (param) {
                                var match = param.duplicateVariant;
                                return Intl$Pos.template(productIds.length > 1 ? Intl$Pos.t("The variant \"{{referenceName}}\" from catalog {{shopName}} has been correctly added to the selected shops.") : Intl$Pos.t("The variant \"{{referenceName}}\" from catalog {{shopName}} has been correctly added to your shop."), {
                                            referenceName: match.name,
                                            shopName: match.shop.name
                                          }, undefined);
                              }));
                };
              }), []);
}

export {
  DuplicateProduct_SourceIdNotFound ,
  DuplicateProduct_DestinationIdsMissing ,
  DuplicateVariant_SourceIdNotFound ,
  DuplicateVariant_DestinationIdsMissing ,
  DuplicateProductMutation ,
  DuplicateVariantMutation ,
  useDuplicateProduct ,
  useDuplicateVariant ,
}
/* query Not a pure module */
