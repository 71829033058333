// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Client from "@apollo/client";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as StockTransferForm$Pos from "./StockTransferForm.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation createStockTransfer($input: InputCreateStockTransfer!, $productsInput: [InputCreateStockTransferProduct!]!)  {\ncreateStockTransfer(input: $input, productsInput: $productsInput)  {\n__typename  \nid  \nsenderShop  {\n__typename  \nid  \n}\n\nrecipientShop  {\n__typename  \nid  \n}\n\nproducts  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nquantity  \nsenderStock  \nrecipientStock  \nsenderVariant  {\n__typename  \nid  \n}\n\nrecipientVariant  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.createStockTransfer;
  var value$2 = value$1.senderShop;
  var value$3 = value$1.recipientShop;
  var value$4 = value$1.products;
  var value$5 = value$4.edges;
  return {
          createStockTransfer: {
            __typename: value$1.__typename,
            id: value$1.id,
            senderShop: {
              __typename: value$2.__typename,
              id: value$2.id
            },
            recipientShop: {
              __typename: value$3.__typename,
              id: value$3.id
            },
            products: {
              __typename: value$4.__typename,
              edges: Js_array.map((function (value) {
                      var value$1 = value.node;
                      var value$2 = value$1.senderVariant;
                      var value$3 = value$1.recipientVariant;
                      return {
                              __typename: value.__typename,
                              node: {
                                __typename: value$1.__typename,
                                quantity: value$1.quantity,
                                senderStock: value$1.senderStock,
                                recipientStock: value$1.recipientStock,
                                senderVariant: {
                                  __typename: value$2.__typename,
                                  id: value$2.id
                                },
                                recipientVariant: {
                                  __typename: value$3.__typename,
                                  id: value$3.id
                                }
                              }
                            };
                    }), value$5)
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.createStockTransfer;
  var value$2 = value$1.products;
  var value$3 = value$2.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.recipientVariant;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var recipientVariant = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.senderVariant;
          var value$6 = value$5.id;
          var value$7 = value$5.__typename;
          var senderVariant = {
            __typename: value$7,
            id: value$6
          };
          var value$8 = value$1.recipientStock;
          var value$9 = value$1.senderStock;
          var value$10 = value$1.quantity;
          var value$11 = value$1.__typename;
          var node = {
            __typename: value$11,
            quantity: value$10,
            senderStock: value$9,
            recipientStock: value$8,
            senderVariant: senderVariant,
            recipientVariant: recipientVariant
          };
          var value$12 = value.__typename;
          return {
                  __typename: value$12,
                  node: node
                };
        }), value$3);
  var value$4 = value$2.__typename;
  var products = {
    __typename: value$4,
    edges: edges
  };
  var value$5 = value$1.recipientShop;
  var value$6 = value$5.id;
  var value$7 = value$5.__typename;
  var recipientShop = {
    __typename: value$7,
    id: value$6
  };
  var value$8 = value$1.senderShop;
  var value$9 = value$8.id;
  var value$10 = value$8.__typename;
  var senderShop = {
    __typename: value$10,
    id: value$9
  };
  var value$11 = value$1.id;
  var value$12 = value$1.__typename;
  var createStockTransfer = {
    __typename: value$12,
    id: value$11,
    senderShop: senderShop,
    recipientShop: recipientShop,
    products: products
  };
  return {
          createStockTransfer: createStockTransfer
        };
}

function serializeInputObjectInputCreateStockTransfer(inp) {
  return {
          senderShopId: inp.senderShopId,
          recipientShopId: inp.recipientShopId
        };
}

function serializeInputObjectInputCreateStockTransferProduct(inp) {
  return {
          quantity: inp.quantity,
          senderVariantId: inp.senderVariantId,
          recipientVariantId: inp.recipientVariantId
        };
}

function serializeVariables(inp) {
  var a = inp.productsInput;
  return {
          input: serializeInputObjectInputCreateStockTransfer(inp.input),
          productsInput: Js_array.map(serializeInputObjectInputCreateStockTransferProduct, a)
        };
}

function makeVariables(input, productsInput, param) {
  return {
          input: input,
          productsInput: productsInput
        };
}

function makeInputObjectInputCreateStockTransfer(senderShopId, recipientShopId, param) {
  return {
          senderShopId: senderShopId,
          recipientShopId: recipientShopId
        };
}

function makeInputObjectInputCreateStockTransferProduct(quantity, senderVariantId, recipientVariantId, param) {
  return {
          quantity: quantity,
          senderVariantId: senderVariantId,
          recipientVariantId: recipientVariantId
        };
}

var CreateStockTransferMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateStockTransfer: serializeInputObjectInputCreateStockTransfer,
  serializeInputObjectInputCreateStockTransferProduct: serializeInputObjectInputCreateStockTransferProduct,
  makeVariables: makeVariables,
  makeInputObjectInputCreateStockTransfer: makeInputObjectInputCreateStockTransfer,
  makeInputObjectInputCreateStockTransferProduct: makeInputObjectInputCreateStockTransferProduct
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CreateStockTransferMutation_useWithVariables = include.useWithVariables;

var CreateStockTransferMutation = {
  CreateStockTransferMutation_inner: CreateStockTransferMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateStockTransfer: serializeInputObjectInputCreateStockTransfer,
  serializeInputObjectInputCreateStockTransferProduct: serializeInputObjectInputCreateStockTransferProduct,
  makeVariables: makeVariables,
  makeInputObjectInputCreateStockTransfer: makeInputObjectInputCreateStockTransfer,
  makeInputObjectInputCreateStockTransferProduct: makeInputObjectInputCreateStockTransferProduct,
  use: use,
  useWithVariables: CreateStockTransferMutation_useWithVariables
};

function makeMutationVariables(param) {
  return {
          input: {
            senderShopId: param.senderShopId,
            recipientShopId: param.recipientShopId
          },
          productsInput: Belt_Array.map(param.products, (function (product) {
                  return {
                          quantity: product.quantity,
                          senderVariantId: product.senderVariantId,
                          recipientVariantId: product.recipientVariantId
                        };
                }))
        };
}

function StockTransferFormActions(Props) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.createElement(StockTransferForm$Pos.SubmitButton.make, {
              variation: "primary",
              size: "medium",
              onSubmit: (function (param, values) {
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, makeMutationVariables(values))), undefined, (function (param) {
                                return param.createStockTransfer.id;
                              }));
                }),
              text: Intl$Pos.t("Finalize")
            });
}

var make = React.memo(StockTransferFormActions);

export {
  CreateStockTransferMutation ,
  makeMutationVariables ,
  make ,
}
/* query Not a pure module */
