// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Badge$Pos from "../feedback-indicators/Badge.bs.js";
import * as Field$Pos from "../layout-and-structure/Field.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as ToggleSwitch$Pos from "./ToggleSwitch.bs.js";

var styles = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, "flex", undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, "spaceBetween", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, "#25243a", undefined, FontSizes$Pos.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-1)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      badge: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(12)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function InputToggleSwitchField(Props) {
  var label = Props.label;
  var badge = Props.badge;
  var required = Props.required;
  var errorMessage = Props.errorMessage;
  var value = Props.value;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement(Field$Pos.make, Field$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(Style$Pos.arrayStyle([styles.view])), undefined, undefined, undefined), React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                              children: Field$Pos.makeLabel(label, required)
                                            })), undefined, undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined)), undefined, disabled, undefined, undefined, undefined, (function (param) {
                                Curry._1(onChange, !value);
                              }), undefined, undefined, undefined)), React.createElement(Inline$Pos.make, {
                          children: null
                        }, badge !== undefined ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Badge$Pos.make, {
                                            children: badge,
                                            variation: "information"
                                          })), Caml_option.some(styles.badge), undefined, undefined, undefined)) : null, React.createElement(ToggleSwitch$Pos.make, {
                              value: value,
                              onChange: onChange,
                              disabled: disabled
                            }))), undefined, undefined, undefined, errorMessage, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(InputToggleSwitchField);

export {
  make ,
}
/* styles Not a pure module */
