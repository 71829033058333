// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var UnknownStringValue = /* @__PURE__ */Caml_exceptions.create("StockActivityKind-Pos.UnknownStringValue");

var values = [
  "LOSS",
  "SALE",
  "REFUND",
  "DELIVERY",
  "DELIVERY_RECEIPT",
  "RESET",
  "RECEPTION",
  "CREDIT_MEMO",
  "INCOMING_TRANSFER",
  "OUTGOING_TRANSFER"
];

function toString(kind) {
  if (kind === "DELIVERY_RECEIPT") {
    return "DELIVERY_RECEIPT";
  } else if (kind === "RESET") {
    return "RESET";
  } else if (kind === "INCOMING_TRANSFER") {
    return "INCOMING_TRANSFER";
  } else if (kind === "DELIVERY") {
    return "DELIVERY";
  } else if (kind === "REFUND") {
    return "REFUND";
  } else if (kind === "LOSS") {
    return "LOSS";
  } else if (kind === "CREDIT_MEMO") {
    return "CREDIT_MEMO";
  } else if (kind === "SALE") {
    return "SALE";
  } else if (kind === "RECEPTION") {
    return "RECEPTION";
  } else {
    return "OUTGOING_TRANSFER";
  }
}

function fromStringExn(value) {
  switch (value) {
    case "CREDIT_MEMO" :
        return "CREDIT_MEMO";
    case "DELIVERY" :
        return "DELIVERY";
    case "DELIVERY_RECEIPT" :
        return "DELIVERY_RECEIPT";
    case "INCOMING_TRANSFER" :
        return "INCOMING_TRANSFER";
    case "LOSS" :
        return "LOSS";
    case "OUTGOING_TRANSFER" :
        return "OUTGOING_TRANSFER";
    case "RECEPTION" :
        return "RECEPTION";
    case "REFUND" :
        return "REFUND";
    case "RESET" :
        return "RESET";
    case "SALE" :
        return "SALE";
    default:
      throw {
            RE_EXN_ID: UnknownStringValue,
            Error: new Error()
          };
  }
}

function toLabel(kind) {
  if (kind === "DELIVERY_RECEIPT") {
    return Intl$Pos.t("Delivery receipt");
  } else if (kind === "RESET") {
    return Intl$Pos.t("Reseting");
  } else if (kind === "INCOMING_TRANSFER") {
    return Intl$Pos.t("Incoming transfer");
  } else if (kind === "DELIVERY") {
    return Intl$Pos.t("Delivery");
  } else if (kind === "REFUND") {
    return Intl$Pos.t("Refund");
  } else if (kind === "LOSS") {
    return Intl$Pos.t("Loss");
  } else if (kind === "CREDIT_MEMO") {
    return Intl$Pos.t("Credit note");
  } else if (kind === "SALE") {
    return Intl$Pos.t("Sale");
  } else if (kind === "RECEPTION") {
    return Intl$Pos.t("Reception");
  } else {
    return Intl$Pos.t("Outgoing transfer");
  }
}

function toColor(kind) {
  if (kind === "RESET") {
    return "#25243a";
  } else if (kind === "RECEPTION" || kind === "CREDIT_MEMO" || kind === "REFUND" || kind === "DELIVERY" || kind === "INCOMING_TRANSFER") {
    return "#1dbb7a";
  } else {
    return "#e61e5a";
  }
}

export {
  UnknownStringValue ,
  values ,
  toString ,
  fromStringExn ,
  toLabel ,
  toColor ,
}
/* Intl-Pos Not a pure module */
