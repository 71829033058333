// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Field$Pos from "../../resources/layout-and-structure/Field.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../../resources/theme/FontSizes.bs.js";
import * as TextInput$Pos from "../../primitives/TextInput.bs.js";
import * as Client from "@apollo/client";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as ButtonPhased$Pos from "../../resources/actions/ButtonPhased.bs.js";
import * as SearchListItem$Pos from "../../resources/selection-and-input/SearchListItem.bs.js";
import * as SearchListPopover$Pos from "../../resources/selection-and-input/SearchListPopover.bs.js";
import * as OverlayTriggerView$Pos from "../../resources/overlays/OverlayTriggerView.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var styles = StyleSheet$Pos.create({
      root: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-12)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined),
      itemPlaceholderText: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, "#797885", undefined, FontSizes$Pos.small, "italic", undefined, undefined, undefined, 0.125, 36, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(2)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      inputTriggerAppend: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "absolute", Caml_option.some(Style$Pos.dp(3)), undefined, Caml_option.some(Style$Pos.dp(21)), undefined, undefined, undefined)
    });

var Raw = {};

var query = Client.gql(["query CategoriesQuery($first: Int, $after: String, $search: String!, $filterByShopIds: InFilter)  {\ncategories(first: $first, after: $after, search: $search, filterBy: {archived: EXCLUDED, hasChildren: false, shopIds: $filterByShopIds})  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedName  \n}\n\n}\n\npageInfo  {\n__typename  \nhasNextPage  \nendCursor  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.categories;
  var value$2 = value$1.edges;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var value$5 = value$3.endCursor;
  return {
          categories: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedName: value$1.formattedName
                            }
                          };
                  }), value$2),
            pageInfo: {
              __typename: value$3.__typename,
              hasNextPage: !(value$4 == null) ? value$4 : undefined,
              endCursor: !(value$5 == null) ? value$5 : undefined
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.categories;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var endCursor = value$3 !== undefined ? value$3 : null;
  var value$4 = value$2.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$2.__typename;
  var pageInfo = {
    __typename: value$5,
    hasNextPage: hasNextPage,
    endCursor: endCursor
  };
  var value$6 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedName;
          var value$3 = value$1.id;
          var value$4 = value$1.__typename;
          var node = {
            __typename: value$4,
            id: value$3,
            formattedName: value$2
          };
          var value$5 = value.__typename;
          return {
                  __typename: value$5,
                  node: node
                };
        }), value$6);
  var value$7 = value$1.__typename;
  var categories = {
    __typename: value$7,
    edges: edges,
    pageInfo: pageInfo
  };
  return {
          categories: categories
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  var a$2 = inp.filterByShopIds;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          search: inp.search,
          filterByShopIds: a$2 !== undefined ? serializeInputObjectInFilter(a$2) : undefined
        };
}

function makeVariables(first, after, search, filterByShopIds, param) {
  return {
          first: first,
          after: after,
          search: search,
          filterByShopIds: filterByShopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

var CategoriesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInFilter: makeInputObjectInFilter
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var useLazy = include.useLazy;

var CategoriesQuery_refetchQueryDescription = include.refetchQueryDescription;

var CategoriesQuery_use = include.use;

var CategoriesQuery_useLazyWithVariables = include.useLazyWithVariables;

var CategoriesQuery = {
  CategoriesQuery_inner: CategoriesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInFilter: makeInputObjectInFilter,
  refetchQueryDescription: CategoriesQuery_refetchQueryDescription,
  use: CategoriesQuery_use,
  useLazy: useLazy,
  useLazyWithVariables: CategoriesQuery_useLazyWithVariables
};

function throttle(callback, limit) {
  var wait = {
    contents: false
  };
  if (!wait.contents) {
    Curry._1(callback, undefined);
    wait.contents = true;
    setTimeout((function (param) {
            wait.contents = false;
          }), limit);
    return ;
  }
  
}

function categoriesFromData(data) {
  return Belt_Array.map(data.categories.edges, (function (param) {
                var category = param.node;
                return {
                        id: category.id,
                        formattedName: category.formattedName
                      };
              }));
}

function CatalogCategoryPicker(Props) {
  var variationOpt = Props.variation;
  var compactOpt = Props.compact;
  var triggerTypeOpt = Props.triggerType;
  var placeholder = Props.placeholder;
  var value = Props.value;
  var shopId = Props.shopId;
  var erroredOpt = Props.errored;
  var onChange = Props.onChange;
  var variation = variationOpt !== undefined ? variationOpt : "normal";
  var compact = compactOpt !== undefined ? compactOpt : false;
  var triggerType = triggerTypeOpt !== undefined ? triggerTypeOpt : "cell";
  var errored = erroredOpt !== undefined ? erroredOpt : false;
  var match = Curry.app(useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match[1];
  var executeQuery = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$1[1];
  var searchQuery = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPopoverOpened = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setFocused = match$3[1];
  var focused = match$3[0];
  var match$4 = Hover$Pos.use(undefined, undefined);
  var hovered = match$4[1];
  var ref = match$4[0];
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var categoriesFilterByShopIds = shopId !== undefined ? ({
        _in: [shopId]
      }) : (
      activeShop !== undefined ? ({
            _in: [activeShop.id]
          }) : undefined
    );
  var queryStatusRef = React.useRef(undefined);
  var hasNextPageRef = React.useRef(false);
  var endCursorRef = React.useRef("");
  React.useEffect((function () {
          if (queryResults.TAG === /* Executed */0) {
            var match = queryResults._0;
            var match$1 = match.data;
            if (match$1 !== undefined) {
              var match$2 = match$1.categories.pageInfo;
              var hasNextPage = match$2.hasNextPage;
              if (hasNextPage !== undefined) {
                var endCursor = match$2.endCursor;
                if (endCursor !== undefined) {
                  queryStatusRef.current = match.networkStatus;
                  hasNextPageRef.current = hasNextPage;
                  endCursorRef.current = endCursor;
                }
                
              }
              
            }
            
          }
          
        }), [queryResults]);
  React.useEffect((function () {
          if (focused) {
            Curry._3(executeQuery, undefined, undefined, makeVariables(20, undefined, searchQuery, categoriesFilterByShopIds, undefined));
          }
          
        }), [
        searchQuery,
        focused
      ]);
  var fetchMore = React.useCallback((function (param) {
          var match = hasNextPageRef.current;
          if (queryResults.TAG !== /* Executed */0) {
            return ;
          }
          var match$1 = queryResults._0;
          if (match$1.data !== undefined && match) {
            Curry._5(match$1.fetchMore, undefined, undefined, makeVariables(20, endCursorRef.current, searchQuery, categoriesFilterByShopIds, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newCategories = fetchMoreResult.categories;
                    return {
                            categories: {
                              __typename: newCategories.__typename,
                              edges: Belt_Array.concat(prevResult.categories.edges, newCategories.edges),
                              pageInfo: newCategories.pageInfo
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [queryResults]);
  var onEndReached = function (offset) {
    var match = offset < 50;
    var match$1 = queryStatusRef.current;
    if (match && match$1 === 5) {
      return throttle(fetchMore, 850);
    }
    
  };
  var renderEndItem = React.useCallback((function (param) {
          var tmp;
          if (queryResults.TAG === /* Executed */0) {
            var match = queryResults._0;
            var match$1 = match.data;
            tmp = match.loading ? Intl$Pos.t("Loading...") : (
                match.error !== undefined ? Intl$Pos.t("Loading error") : (
                    match$1 !== undefined && match$1.categories.edges.length === 0 ? Intl$Pos.t("No result found") : null
                  )
              );
          } else {
            tmp = Intl$Pos.t("Loading...");
          }
          return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Text$Pos.make, Text$Pos.makeProps(tmp, Caml_option.some(styles.itemPlaceholderText), undefined, undefined, undefined))), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined));
        }), [queryResults]);
  var renderEndActions = React.useMemo((function () {
          if (queryResults.TAG !== /* Executed */0) {
            return ;
          }
          var match = queryResults._0.data;
          if (match === undefined) {
            return ;
          }
          var match$1 = match.categories.pageInfo.hasNextPage;
          if (match$1 !== undefined && !match$1) {
            return (function (param) {
                      return [React.createElement(SearchListItem$Pos.make, {
                                    name: Intl$Pos.t("Not classified"),
                                    onPress: (function (param) {
                                        Curry._1(setPopoverOpened, (function (param) {
                                                return false;
                                              }));
                                        Curry._1(onChange, /* NotClassifiedCategory */0);
                                      })
                                  })];
                    });
          }
          
        }), [queryResults]);
  var onToggle = function (opened) {
    Curry._1(setFocused, (function (param) {
            return opened;
          }));
    Curry._1(setPopoverOpened, (function (param) {
            return opened;
          }));
  };
  var items = React.useMemo((function () {
          if (queryResults.TAG !== /* Executed */0) {
            return ;
          }
          var data = queryResults._0.data;
          if (data !== undefined) {
            return categoriesFromData(data);
          }
          
        }), [queryResults]);
  var itemToValue = function (category) {
    return category.id;
  };
  var itemToOption = function (category) {
    return category.formattedName;
  };
  var tmp;
  if (triggerType === "input") {
    var tmp$1 = {
      readOnly: true,
      value: value
    };
    if (placeholder !== undefined) {
      tmp$1.placeholder = placeholder;
    }
    tmp = React.createElement(Field$Pos.make, Field$Pos.makeProps(React.createElement(OverlayTriggerView$Pos.make, {
                  children: React.createElement(TextInput$Pos.make, tmp$1),
                  preset: {
                    NAME: "inputField",
                    VAL: {
                      required: false
                    }
                  },
                  size: compact ? "compact" : "normal",
                  icon: focused ? "arrow_up_light" : "arrow_down_light",
                  hovered: hovered,
                  focused: focused
                }), Intl$Pos.t("Category"), undefined, undefined, undefined, undefined, undefined, undefined, ref, undefined));
  } else {
    tmp = React.createElement(ButtonPhased$Pos.make, ButtonPhased$Pos.makeProps(value, variation, hovered, compact, focused, errored, undefined, undefined, undefined, ref, undefined));
  }
  var tmp$2 = {
    children: tmp,
    expendableWidth: triggerType === "input",
    items: items,
    itemToValue: itemToValue,
    itemToOption: itemToOption,
    opened: match$2[0],
    onSearchQueryChange: (function (value) {
        Curry._1(setSearchQuery, (function (param) {
                return value;
              }));
      }),
    onChange: (function (value) {
        Curry._1(onChange, /* CommonCategory */{
              _0: value
            });
      }),
    onToggle: onToggle,
    onEndReached: onEndReached,
    renderEndItem: renderEndItem
  };
  if (renderEndActions !== undefined) {
    tmp$2.renderEndActions = Caml_option.valFromOption(renderEndActions);
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(SearchListPopover$Pos.make, tmp$2)), Caml_option.some(styles.root), undefined, undefined, undefined));
}

var make = React.memo(CatalogCategoryPicker);

var edgesPerFetch = 20;

export {
  styles ,
  CategoriesQuery ,
  throttle ,
  edgesPerFetch ,
  categoriesFromData ,
  make ,
}
/* styles Not a pure module */
