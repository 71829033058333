// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IpAddress$Pos from "../../primitives/IpAddress.bs.js";
import * as AuthRoutes$Pos from "./AuthRoutes.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as AuthLoginPage$Pos from "./AuthLoginPage.bs.js";
import * as AuthLogoutPage$Pos from "./AuthLogoutPage.bs.js";
import * as AuthSignupPage$Pos from "./AuthSignupPage.bs.js";
import * as AuthLoginResetPage$Pos from "./AuthLoginResetPage.bs.js";
import * as AuthImpersonationPage$Pos from "./AuthImpersonationPage.bs.js";
import * as AuthLoginRecoveryPage$Pos from "./AuthLoginRecoveryPage.bs.js";
import * as AuthSignupSuccessPage$Pos from "./AuthSignupSuccessPage.bs.js";
import * as AuthUsernameUpdatePage$Pos from "./AuthUsernameUpdatePage.bs.js";

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f8f8fb", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function AuthRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var appIndexRoute = Props.appIndexRoute;
  var tmp;
  var exit = 0;
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "impersonation" :
          var match = subUrlPath.tl;
          if (match && !match.tl) {
            tmp = React.createElement(AuthImpersonationPage$Pos.make, {
                  userId: match.hd,
                  authLogoutImpersonationFailureRoute: AuthRoutes$Pos.logoutImpersonationFailureRoute,
                  appIndexRoute: appIndexRoute
                });
          } else {
            exit = 1;
          }
          break;
      case "login" :
          var match$1 = subUrlPath.tl;
          if (match$1) {
            switch (match$1.hd) {
              case "from-website" :
                  if (match$1.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(AuthLoginPage$Pos.make, {
                          signInRequest: AuthLoginPage$Pos.signInRequest,
                          initialNotification: {
                            TAG: /* Info */3,
                            _0: Intl$Pos.template(Intl$Pos.t("You can access the old dashboard [dashboard.wino.fr]({{link}})"), {
                                  link: "https://dashboard.wino.fr"
                                }, undefined)
                          },
                          recoveryRoute: AuthRoutes$Pos.recoveryRoute
                        });
                  }
                  break;
              case "impersonation-failure" :
                  if (match$1.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(AuthLoginPage$Pos.make, {
                          signInRequest: AuthLoginPage$Pos.signInRequest,
                          initialNotification: {
                            TAG: /* Danger */1,
                            _0: Intl$Pos.t("Oops! It seems the impersonation has failed.")
                          },
                          recoveryRoute: AuthRoutes$Pos.recoveryRoute
                        });
                  }
                  break;
              case "password-reset" :
                  if (match$1.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(AuthLoginPage$Pos.make, {
                          signInRequest: AuthLoginPage$Pos.signInRequest,
                          initialNotification: {
                            TAG: /* Success */0,
                            _0: Intl$Pos.t("Your password has been reset successfully. Please log in")
                          },
                          recoveryRoute: AuthRoutes$Pos.recoveryRoute
                        });
                  }
                  break;
              case "recovery-email-sent" :
                  if (match$1.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(AuthLoginPage$Pos.make, {
                          signInRequest: AuthLoginPage$Pos.signInRequest,
                          initialNotification: {
                            TAG: /* Success */0,
                            _0: Intl$Pos.t("You should have received the recovery link by the next minutes.")
                          },
                          recoveryRoute: AuthRoutes$Pos.recoveryRoute
                        });
                  }
                  break;
              case "session-expired" :
                  if (match$1.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(AuthLoginPage$Pos.make, {
                          signInRequest: AuthLoginPage$Pos.signInRequest,
                          initialNotification: {
                            TAG: /* Info */3,
                            _0: Intl$Pos.t("Oops! It seems your session has expired.")
                          },
                          recoveryRoute: AuthRoutes$Pos.recoveryRoute
                        });
                  }
                  break;
              case "username-updated" :
                  var match$2 = match$1.tl;
                  if (match$2 && !match$2.tl) {
                    tmp = React.createElement(AuthLoginPage$Pos.make, {
                          signInRequest: AuthLoginPage$Pos.signInRequest,
                          initialNotification: {
                            TAG: /* Success */0,
                            _0: Intl$Pos.t("Your email update has been completed successfully.")
                          },
                          recoveryRoute: AuthRoutes$Pos.recoveryRoute,
                          initialUsername: match$2.hd
                        });
                  } else {
                    exit = 1;
                  }
                  break;
              default:
                exit = 1;
            }
          } else {
            tmp = React.createElement(AuthLoginPage$Pos.make, {
                  signInRequest: AuthLoginPage$Pos.signInRequest,
                  recoveryRoute: AuthRoutes$Pos.recoveryRoute
                });
          }
          break;
      case "logout" :
          var match$3 = subUrlPath.tl;
          if (match$3) {
            var exit$1 = 0;
            switch (match$3.hd) {
              case "impersonation-failure" :
              case "session-expired" :
                  exit$1 = 2;
                  break;
              default:
                exit = 1;
            }
            if (exit$1 === 2) {
              if (match$3.tl) {
                exit = 1;
              } else {
                tmp = React.createElement(AuthLogoutPage$Pos.make, {});
              }
            }
            
          } else {
            tmp = React.createElement(AuthLogoutPage$Pos.make, {});
          }
          break;
      case "recovery" :
          if (subUrlPath.tl) {
            exit = 1;
          } else {
            tmp = React.createElement(AuthLoginRecoveryPage$Pos.make, {
                  loginRecoveryEmailSentRoute: AuthRoutes$Pos.loginRecoveryEmailSentRoute,
                  passwordRecoveryRequest: AuthLoginRecoveryPage$Pos.passwordRecoveryRequest
                });
          }
          break;
      case "reset" :
          var match$4 = subUrlPath.tl;
          if (match$4) {
            var match$5 = match$4.tl;
            if (match$5) {
              var match$6 = match$5.tl;
              if (match$6 && !match$6.tl) {
                tmp = React.createElement(AuthLoginResetPage$Pos.make, {
                      userId: match$4.hd,
                      tokenId: match$5.hd,
                      token: match$6.hd,
                      successRoute: AuthRoutes$Pos.loginRecoverySuccessRoute,
                      resetPasswordRequest: AuthLoginResetPage$Pos.resetPasswordRequest
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "signup" :
          var match$7 = subUrlPath.tl;
          if (match$7) {
            if (match$7.hd === "success" && !match$7.tl) {
              tmp = React.createElement(AuthSignupSuccessPage$Pos.make, {});
            } else {
              exit = 1;
            }
          } else {
            tmp = React.createElement(AuthSignupPage$Pos.make, {
                  signupRequest: AuthSignupPage$Pos.signupRequest,
                  ipAddressRequest: IpAddress$Pos.request
                });
          }
          break;
      case "update-username" :
          var match$8 = subUrlPath.tl;
          if (match$8) {
            var match$9 = match$8.tl;
            if (match$9) {
              var match$10 = match$9.tl;
              if (match$10) {
                var match$11 = match$10.tl;
                if (match$11 && !match$11.tl) {
                  tmp = React.createElement(AuthUsernameUpdatePage$Pos.make, {
                        userId: match$8.hd,
                        tokenId: match$9.hd,
                        token: match$10.hd,
                        username: match$11.hd,
                        usernameUpdateRequest: AuthUsernameUpdatePage$Pos.usernameUpdateRequest
                      });
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Navigation$Pos.Redirect.make, {
          route: AuthRoutes$Pos.loginRoute
        });
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(tmp), Caml_option.some(styles.container), undefined, undefined, undefined));
}

var recoveryRoute = AuthRoutes$Pos.recoveryRoute;

var loginSessionFromWebsiteRoute = AuthRoutes$Pos.loginSessionFromWebsiteRoute;

var loginRecoveryEmailSentRoute = AuthRoutes$Pos.loginRecoveryEmailSentRoute;

var loginRecoverySuccessRoute = AuthRoutes$Pos.loginRecoverySuccessRoute;

var logoutImpersonationFailureRoute = AuthRoutes$Pos.logoutImpersonationFailureRoute;

var loginRoute = AuthRoutes$Pos.loginRoute;

var make = AuthRouter;

export {
  recoveryRoute ,
  loginSessionFromWebsiteRoute ,
  loginRecoveryEmailSentRoute ,
  loginRecoverySuccessRoute ,
  logoutImpersonationFailureRoute ,
  loginRoute ,
  styles ,
  make ,
}
/* styles Not a pure module */
