// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Accounting__Utils from "@wino/accounting/src/Accounting__Utils.bs.js";

function CartProductTaxTableCell(Props) {
  var tax = Props.tax;
  var taxAmount = Accounting__Utils.bigToFloat(Belt_Option.getExn(tax.amount));
  var formattedTaxAmount = Intl$Pos.currencyFormat("EUR", 3, 3, taxAmount);
  var formattedTaxRate = Intl$Pos.percentFormat(undefined, 2, tax.rate / 100);
  return React.createElement(Stack$Pos.make, {
              children: null,
              space: "xsmall"
            }, React.createElement(TextStyle$Pos.make, {
                  children: formattedTaxAmount
                }), React.createElement(TextStyle$Pos.make, {
                  children: Intl$Pos.t("VAT") + " " + formattedTaxRate,
                  variation: "normal",
                  size: "xxsmall"
                }));
}

var make = React.memo(CartProductTaxTableCell);

export {
  make ,
}
/* make Not a pure module */
