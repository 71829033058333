// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as SupplierSelect$Pos from "../Supplier/SupplierSelect.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation updateVariant_supplier($id: ID!, $input: InputUpdateVariant!)  {\nupdateVariant(id: $id, input: $input)  {\n__typename  \nid  \nsupplier  {\n__typename  \nid  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.supplier;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            supplier: !(value$2 == null) ? ({
                  __typename: value$2.__typename,
                  id: value$2.id
                }) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.supplier;
  var supplier;
  if (value$2 !== undefined) {
    var value$3 = value$2.id;
    var value$4 = value$2.__typename;
    supplier = {
      __typename: value$4,
      id: value$3
    };
  } else {
    supplier = null;
  }
  var value$5 = value$1.id;
  var value$6 = value$1.__typename;
  var updateVariant = {
    __typename: value$6,
    id: value$5,
    supplier: supplier
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.accountingCode;
  var a$16 = inp.supplierId;
  var a$17 = inp.minStockThreshold;
  var a$18 = inp.maxStockThreshold;
  var a$19 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          accountingCode: a$15 !== undefined ? a$15 : undefined,
          supplierId: a$16 !== undefined ? a$16 : undefined,
          minStockThreshold: a$17 !== undefined ? a$17 : undefined,
          maxStockThreshold: a$18 !== undefined ? a$18 : undefined,
          stockOrderTriggerThreshold: a$19 !== undefined ? a$19 : undefined
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, accountingCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          accountingCode: accountingCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

var SupplierIdMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var SupplierIdMutation_useWithVariables = include.useWithVariables;

var SupplierIdMutation = {
  SupplierIdMutation_inner: SupplierIdMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  use: use,
  useWithVariables: SupplierIdMutation_useWithVariables
};

function CatalogSupplierTableEditCell(Props) {
  var supplierId = Props.supplierId;
  var supplierName = Props.supplierName;
  var variantId = Props.variantId;
  var shopId = Props.shopId;
  var onDismiss = Props.onDismiss;
  var mutate = Curry.app(use, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ])[0];
  var onMutateSupplierRequested = React.useCallback((function (supplier) {
          var input = makeInputObjectInputUpdateVariant(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, supplier !== undefined ? supplier.id : null, undefined, undefined, undefined, undefined);
          Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                        id: variantId,
                        input: input
                      })), undefined, (function (param) {
                  return variantId;
                }));
        }), []);
  var tmp = {
    preset: {
      NAME: "inputField",
      VAL: {
        required: false
      }
    },
    defaultOpen: true,
    shopId: shopId,
    value: {
      id: supplierId,
      name: supplierName
    },
    onChange: onMutateSupplierRequested
  };
  if (onDismiss !== undefined) {
    tmp.onDismiss = Caml_option.valFromOption(onDismiss);
  }
  return React.createElement(SupplierSelect$Pos.make, tmp);
}

var make = React.memo(CatalogSupplierTableEditCell, (function (oldProps, newProps) {
        if (oldProps.supplierId === newProps.supplierId && oldProps.variantId === newProps.variantId) {
          return oldProps.shopId === newProps.shopId;
        } else {
          return false;
        }
      }));

export {
  SupplierIdMutation ,
  make ,
}
/* query Not a pure module */
