// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../../resources/overlays/Popover.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as OpeningButton$Pos from "../../resources/actions/OpeningButton.bs.js";
import * as TextIconButton$Pos from "../../resources/actions/TextIconButton.bs.js";
import * as InputNumberField$Pos from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as InputSegmentedControlsField$Pos from "../../resources/selection-and-input/InputSegmentedControlsField.bs.js";

function PromotionEditDiscountedProductDiscountTableCell(Props) {
  var editable = Props.editable;
  var disabled = Props.disabled;
  var discount = Props.discount;
  var bulkUnit = Props.bulkUnit;
  var onRequestUpdate = Props.onRequestUpdate;
  var onRequestReplicate = Props.onRequestReplicate;
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var triggerHovered = match$1[1];
  var discountKindRef = React.useRef(discount.kind);
  React.useEffect((function () {
          discountKindRef.current = discount.kind;
        }), [discount.kind]);
  var onDiscountKindChange = React.useCallback((function (kind) {
          var match = discount.amount > 100;
          Curry._1(onRequestUpdate, {
                id: discount.id,
                amount: kind === "PERCENT" && match ? 0 : discount.amount,
                kind: kind
              });
        }), [discount.amount]);
  var onDiscountAmountChange = React.useCallback((function (amount) {
          var match = discountKindRef.current;
          var match$1 = amount <= 100;
          if (match === "PERCENT" && !match$1) {
            return ;
          }
          Curry._1(onRequestUpdate, {
                id: discount.id,
                amount: amount,
                kind: discountKindRef.current
              });
        }), []);
  var kindToText = function (kind) {
    if (kind === "PERCENT") {
      return "%";
    } else {
      return "€";
    }
  };
  var popover_isOpen = popover.isOpen;
  var popover_close = function (param) {
    if (!triggerHovered) {
      return Curry._1(popover.close, undefined);
    }
    
  };
  var popover_toggle = popover.toggle;
  var popover$1 = {
    isOpen: popover_isOpen,
    close: popover_close,
    toggle: popover_toggle
  };
  var tmp;
  if (editable) {
    var match$2 = discount.kind;
    tmp = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(OpeningButton$Pos.make, OpeningButton$Pos.makeProps(undefined, React.createElement(TextStyle$Pos.make, {
                              children: match$2 === "PERCENT" ? Intl$Pos.percentFormat(undefined, undefined, discount.amount / 100) : Intl$Pos.currencyFormat("EUR", undefined, undefined, discount.amount),
                              variation: disabled ? "normal" : "neutral"
                            }), popoverAriaProps.triggerProps, popover_isOpen, disabled, (function (param) {
                            Curry._1(popover_toggle, undefined);
                          }), undefined, popoverTriggerRef, undefined))), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  } else {
    var match$3 = discount.kind;
    tmp = React.createElement(TextStyle$Pos.make, {
          children: match$3 === "PERCENT" ? Intl$Pos.percentFormat(undefined, undefined, discount.amount / 100) : Intl$Pos.currencyFormat("EUR", undefined, undefined, discount.amount)
        });
  }
  var tmp$1;
  if (popover_isOpen) {
    var match$4 = discount.kind;
    var tmp$2 = {
      value: discount.amount,
      minValue: 0,
      label: Intl$Pos.t("Discount amount"),
      appender: match$4 === "PERCENT" ? /* Percent */0 : (
          bulkUnit !== undefined ? ({
                TAG: /* Custom */1,
                _0: "€ / " + bulkUnit + ""
              }) : ({
                TAG: /* Currency */0,
                _0: "EUR"
              })
        ),
      onChange: onDiscountAmountChange
    };
    var tmp$3 = discount.kind === "PERCENT" ? 100 : undefined;
    if (tmp$3 !== undefined) {
      tmp$2.maxValue = tmp$3;
    }
    var tmp$4 = discount.kind === "PERCENT" ? 0 : undefined;
    if (tmp$4 !== undefined) {
      tmp$2.minPrecision = tmp$4;
    }
    tmp$1 = React.createElement(Popover$Pos.make, {
          children: React.createElement(Popover$Pos.Dialog.make, {
                children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Inline$Pos.make, {
                          children: null,
                          space: "large"
                        }, React.createElement(InputSegmentedControlsField$Pos.make, {
                              label: Intl$Pos.t("Discount type"),
                              value: discount.kind,
                              required: false,
                              onChange: onDiscountKindChange,
                              options: {
                                hd: "PERCENT",
                                tl: {
                                  hd: "CURRENCY",
                                  tl: /* [] */0
                                }
                              },
                              optionToText: kindToText
                            }), React.createElement(InputNumberField$Pos.make, tmp$2)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, TextIconButton$Pos.makeProps(Intl$Pos.t("Apply to all products"), undefined, undefined, Belt_Option.isNone(onRequestReplicate), "plus_light", undefined, (function (param) {
                                            if (onRequestReplicate !== undefined) {
                                              Curry._1(onRequestReplicate, undefined);
                                              return Curry._1(popover_close, undefined);
                                            }
                                            
                                          }), undefined, undefined, undefined))), undefined, undefined, "medium", "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined))),
                ariaProps: popoverAriaProps.overlayProps
              }),
          triggerRef: popoverTriggerRef,
          state: popover$1,
          placement: "bottom end",
          shouldUpdatePosition: false,
          modal: false
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, tmp, tmp$1);
}

var make = React.memo(PromotionEditDiscountedProductDiscountTableCell, (function (oldProps, newProps) {
        if (Caml_obj.equal(oldProps.discount, newProps.discount) && oldProps.editable === newProps.editable && oldProps.disabled === newProps.disabled && oldProps.bulkUnit === newProps.bulkUnit) {
          return oldProps.onRequestReplicate === newProps.onRequestReplicate;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
