// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "./Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../typography/Title.bs.js";
import * as Inline$Pos from "./Inline.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as GoBackButton$Pos from "../navigation/GoBackButton.bs.js";

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xxlarge)), undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.large)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      header: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      title: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      content: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function containerStyleFromParams(variation) {
  if (variation === "compact") {
    return Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(20)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(90)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

function Page(Props) {
  var children = Props.children;
  var variationOpt = Props.variation;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var renderTitleEndOpt = Props.renderTitleEnd;
  var renderActions = Props.renderActions;
  var renderHeaderActions = Props.renderHeaderActions;
  var variation = variationOpt !== undefined ? variationOpt : "standard";
  var renderTitleEnd = renderTitleEndOpt !== undefined ? renderTitleEndOpt : (function (param) {
        return null;
      });
  var match = Navigation$Pos.useGoBack(undefined);
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(Style$Pos.arrayStyle([
                          styles.container,
                          containerStyleFromParams(variation)
                        ])), undefined, undefined, undefined), variation === "standard" && match[0] ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(GoBackButton$Pos.make, {})), undefined, undefined, undefined, subtitle !== undefined ? "medium" : "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, title !== undefined ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.header), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                    children: React.createElement(Stack$Pos.make, {
                                          children: null,
                                          space: "xsmall"
                                        }, React.createElement(Inline$Pos.make, {
                                              children: null,
                                              space: "medium",
                                              alignY: "bottom",
                                              wrap: true
                                            }, React.createElement(Title$Pos.make, {
                                                  children: title,
                                                  level: 2
                                                }), Curry._1(renderTitleEnd, undefined)), subtitle !== undefined ? React.createElement(TextStyle$Pos.make, {
                                                children: subtitle,
                                                variation: "normal",
                                                size: "small"
                                              }) : null),
                                    space: "medium"
                                  })), Caml_option.some(styles.title), undefined, undefined, undefined)), renderActions !== undefined ? Curry._1(renderActions, undefined) : null) : null, renderHeaderActions !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(Curry._1(renderHeaderActions, undefined)), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(children), Caml_option.some(styles.content), undefined, undefined, undefined)));
}

var make = React.memo(Page);

export {
  make ,
}
/* styles Not a pure module */
