// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ListBox$Pos from "./ListBox.bs.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as TextLink$Pos from "../navigation/TextLink.bs.js";
import * as ReactAria$Pos from "../../externals/ReactAria.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as ReactStately from "react-stately";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as InputSearch$Pos from "./InputSearch.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as OverlayTriggerView$Pos from "../overlays/OverlayTriggerView.bs.js";
import * as ReactDomEventTarget$Pos from "../../primitives/ReactDomEventTarget.bs.js";

function Select$TriggerButton(Props) {
  var triggerRef = Props.triggerRef;
  var variation = Props.variation;
  var size = Props.size;
  var label = Props.label;
  var placeholder = Props.placeholder;
  var state = Props.state;
  var props = Props.props;
  var item = Props.item;
  var valueProps = Props.valueProps;
  var hovered = Props.hovered;
  var highlighted = Props.highlighted;
  var excludeFromTabOrder = Props.excludeFromTabOrder;
  var renderTriggerView = Props.renderTriggerView;
  var contentElement = React.createElement(ReactAria$Pos.Spread.make, {
        props: valueProps,
        children: item !== undefined ? React.createElement("span", undefined, item.label) : React.createElement(TextStyle$Pos.make, {
                children: placeholder,
                variation: "normal",
                size: size === "compact" ? "xsmall" : "normal"
              })
      });
  var disabled = Belt_Option.getWithDefault(props.isDisabled, false);
  var newrecord = Caml_obj.obj_dup(props);
  newrecord.onPressStart = (function (param) {
      
    });
  newrecord["aria-labelledby"] = Belt_Option.map(props["aria-labelledby"], (function (x) {
          return x.replace(Belt_Option.getWithDefault(props.id, ""), "");
        }));
  var active = state.isOpen;
  var focused = state.isFocused;
  var tmp;
  if (renderTriggerView !== undefined) {
    tmp = Curry._5(renderTriggerView, contentElement, item, hovered, active, focused);
  } else {
    var tmp$1 = {
      children: contentElement,
      preset: variation,
      size: size,
      hovered: hovered,
      active: active,
      focused: focused,
      highlighted: highlighted,
      disabled: disabled
    };
    if (label !== undefined) {
      tmp$1.label = label;
    }
    tmp = React.createElement(OverlayTriggerView$Pos.make, tmp$1);
  }
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(tmp, newrecord, undefined, excludeFromTabOrder, undefined, undefined, (function (param) {
                    Curry._1(state.toggle, undefined);
                  }), undefined, Caml_option.some(triggerRef), undefined));
}

var styles = StyleSheet$Pos.create({
      root: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, "#e7e7ee", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

var make = React.memo(function (Props) {
      var value = Props.value;
      var onChange = Props.onChange;
      var onRequestClear = Props.onRequestClear;
      return React.createElement("div", {
                  tabIndex: 0
                }, React.createElement(ReactAria.FocusScope, {
                      children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(InputSearch$Pos.make, {
                                        loading: false,
                                        onRequestClear: onRequestClear,
                                        bordered: false,
                                        placeholder: Intl$Pos.t("Search"),
                                        value: value,
                                        onChange: onChange
                                      })), Caml_option.some(styles.root), undefined, undefined, undefined)),
                      restoreFocus: false,
                      autoFocus: true
                    }));
    });

var style = {
  border: "1px solid #e7e7ee",
  padding: "" + String(Spaces$Pos.normal) + "px " + String(Spaces$Pos.xmedium) + "px"
};

var make$1 = React.memo(function (Props) {
      var text = Props.text;
      var route = Props.route;
      var onRequestClose = Props.onRequestClose;
      return React.createElement("div", {
                  style: style,
                  tabIndex: 0
                }, React.createElement(TextLink$Pos.make, {
                      text: text,
                      to: {
                        TAG: /* Route */0,
                        _0: route
                      },
                      openNewTab: true,
                      icon: "external_link",
                      onPress: (function (param) {
                          Curry._1(onRequestClose, undefined);
                        })
                    }));
    });

function getNextKeyFromState(state, key, direction) {
  var isDisabled = function (key) {
    return Belt_Array.some(Array.from(state.disabledKeys), (function (current) {
                  return current === key;
                }));
  };
  var nextKey = direction === "below" ? Belt_Option.orElse(Caml_option.nullable_to_opt(state.collection.getKeyAfter(key)), Caml_option.nullable_to_opt(state.collection.getFirstKey())) : Belt_Option.orElse(Caml_option.nullable_to_opt(state.collection.getKeyBefore(key)), Caml_option.nullable_to_opt(state.collection.getLastKey()));
  return Belt_Option.flatMap(nextKey, (function (key) {
                var nextItem = state.collection.getItem(key);
                return Belt_Option.flatMap((nextItem == null) ? undefined : Caml_option.some(nextItem), (function (param) {
                              var key = param.key;
                              var match = isDisabled(key);
                              if (param.type === "item" && !match) {
                                return key;
                              } else {
                                return getNextKeyFromState(state, key, direction);
                              }
                            }));
              }));
}

function use(state) {
  var stateRef = React.useRef(state);
  React.useEffect((function () {
          stateRef.current = state;
        }), [state]);
  var onKeyDown = React.useCallback((function ($$event) {
          var state = stateRef.current;
          var focusedKey = state.selectionManager.focusedKey;
          var match = $$event.key;
          switch (match) {
            case "ArrowDown" :
                if (state.isOpen) {
                  $$event.stopPropagation();
                  $$event.preventDefault();
                }
                return Belt_Option.forEach(getNextKeyFromState(state, focusedKey, "below"), (function (key) {
                              state.selectionManager.setFocusedKey(key);
                            }));
            case "ArrowUp" :
                if (state.isOpen) {
                  $$event.stopPropagation();
                  $$event.preventDefault();
                }
                return Belt_Option.forEach(getNextKeyFromState(state, focusedKey, "above"), (function (key) {
                              state.selectionManager.setFocusedKey(key);
                            }));
            case "Enter" :
                var selectedKey = state.selectedKey;
                if (!(selectedKey == null) && focusedKey === selectedKey) {
                  return Curry._1(state.close, undefined);
                } else {
                  return Curry._1(state.setSelectedKey, focusedKey);
                }
            default:
              return ;
          }
        }), []);
  React.useEffect((function () {
          var $$document$1 = document;
          if (state.isFocused) {
            $$document$1.addEventListener("keydown", onKeyDown);
          } else {
            $$document$1.removeEventListener("keydown", onKeyDown);
          }
          return (function (param) {
                    $$document$1.removeEventListener("keydown", onKeyDown);
                  });
        }), [state.isFocused]);
  return state;
}

function processItems(sections, filterByLabel) {
  var sectionsItems = Belt_Array.flatMap(sections, (function (section) {
          return section.items;
        }));
  var searchableSectionsItems = Belt_Array.keep(sectionsItems, (function (item) {
          return !Belt_Option.getWithDefault(item.sticky, false);
        }));
  var filteredSectionsItems = Belt_Array.keep(sectionsItems, (function (item) {
          if (Belt_Array.some(searchableSectionsItems, (function (current) {
                    return current === item;
                  }))) {
            return Curry._1(filterByLabel, item.label);
          } else {
            return true;
          }
        }));
  return {
          sectionsItems: sectionsItems,
          searchableSectionsItems: searchableSectionsItems,
          filteredSectionsItems: filteredSectionsItems
        };
}

var itemDescriptionStyle = {
  color: "#797885"
};

function Select(Props) {
  var label = Props.label;
  var placeholderOpt = Props.placeholder;
  var disabledOpt = Props.disabled;
  var triggerHighlightedOpt = Props.highlighted;
  var loadingOpt = Props.loading;
  var defaultOpenOpt = Props.defaultOpen;
  var searchable = Props.searchable;
  var sections = Props.sections;
  var preset = Props.preset;
  var sizeOpt = Props.size;
  var excludeFromTabOrderOpt = Props.excludeFromTabOrder;
  var footerLink = Props.footerLink;
  var renderTriggerView = Props.renderTriggerView;
  var renderItemContent = Props.renderItemContent;
  var value = Props.value;
  var onChange = Props.onChange;
  var onToggle = Props.onToggle;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : Intl$Pos.t("Select");
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var triggerHighlighted = triggerHighlightedOpt !== undefined ? triggerHighlightedOpt : false;
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var defaultOpen = defaultOpenOpt !== undefined ? defaultOpenOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  var excludeFromTabOrder = excludeFromTabOrderOpt !== undefined ? excludeFromTabOrderOpt : false;
  var match = ReactAria.useFilter({
        sensitivity: "base"
      });
  var contains = match.contains;
  var match$1 = React.useState(function () {
        return ListBox$Pos.defaultItemsPerScroll;
      });
  var setItemsLimit = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var setFilterValue = match$2[1];
  var filterValue = match$2[0];
  var match$3 = Hover$Pos.use(undefined, undefined);
  var triggerHovered = match$3[1];
  var triggerRef = match$3[0];
  var listBoxRef = React.useRef(null);
  var filterByLabel = function (label) {
    return Curry._2(contains, label, filterValue);
  };
  var match$4 = processItems(sections, filterByLabel);
  var filteredSectionsItems = match$4.filteredSectionsItems;
  var searchableSectionsItems = match$4.searchableSectionsItems;
  var sectionsItems = match$4.sectionsItems;
  var disabledKeys = Belt_Array.keepMap(sectionsItems, (function (item) {
          var match = item.disabled;
          if (match !== undefined && match) {
            return item.key;
          }
          
        }));
  var nodeElements = React.useMemo((function () {
          return Belt_Array.map(sections, (function (section) {
                        var defaultSectionTitle = Belt_Option.mapWithDefault(section.title, null, (function (title) {
                                return title;
                              }));
                        var filteredSectionsItems$1 = Belt_Array.keep(section.items, (function (item) {
                                return Belt_Array.some(filteredSectionsItems, (function (current) {
                                              return current === item;
                                            }));
                              }));
                        return React.createElement(ReactStately.Section, {
                                    children: Belt_Array.map(filteredSectionsItems$1, (function (item) {
                                            return React.createElement(ReactStately.Item, {
                                                        children: renderItemContent !== undefined ? Curry._1(renderItemContent, item) : React.createElement("span", undefined, item.label, Belt_Option.mapWithDefault(item.description, null, (function (desc) {
                                                                      return React.createElement("span", {
                                                                                  style: itemDescriptionStyle
                                                                                }, " " + desc);
                                                                    }))),
                                                        textValue: item.label,
                                                        key: item.key
                                                      });
                                          })),
                                    title: defaultSectionTitle
                                  });
                      }));
        }), [
        sections,
        searchableSectionsItems
      ]);
  var matchingItem = Belt_Array.getBy(sectionsItems, (function (item) {
          return Caml_obj.equal(item.value, value);
        }));
  var matchingKey = Belt_Option.map(matchingItem, (function (item) {
          return item.key;
        }));
  var selectedKey = Js_null_undefined.fromOption(matchingKey);
  var props_aria$label = Belt_Option.getWithDefault(label, "select");
  var props_placeholder = placeholder;
  var props_defaultOpen = defaultOpen;
  var props_isDisabled = disabled;
  var props_onSelectionChange = (function (key) {
      Belt_Option.forEach(Belt_Option.map(Belt_Option.flatMap((key == null) ? undefined : Caml_option.some(key), (function (key) {
                      return Belt_Array.getBy(sectionsItems, (function (item) {
                                    return item.key === key;
                                  }));
                    })), (function (item) {
                  return item.value;
                })), onChange);
    });
  var props = {
    children: nodeElements,
    disabledKeys: disabledKeys,
    "aria-label": props_aria$label,
    placeholder: props_placeholder,
    defaultOpen: props_defaultOpen,
    isDisabled: props_isDisabled,
    selectedKey: selectedKey,
    onSelectionChange: props_onSelectionChange
  };
  var state = ReactStately.useSelectState(props);
  var match$5 = ReactAria.useSelect(props, state, triggerRef);
  var state$1 = use(state);
  React.useEffect((function () {
          Curry._1(setFilterValue, (function (param) {
                  return "";
                }));
        }), [state$1.selectedKey]);
  React.useEffect((function () {
          if (!state$1.isFocused) {
            Curry._1(setFilterValue, (function (param) {
                    return "";
                  }));
            state$1.selectionManager.setFocusedKey(undefined);
          }
          
        }), [state$1.isFocused]);
  React.useEffect((function () {
          var firstRelevantItem = Belt_Array.getBy(searchableSectionsItems, (function (item) {
                  return Curry._2(contains, item.label, filterValue);
                }));
          if (firstRelevantItem !== undefined && filterValue !== "") {
            state$1.selectionManager.setFocusedKey(firstRelevantItem.key);
          }
          
        }), [filterValue]);
  ReactUpdateEffect$Pos.use1((function (param) {
          Belt_Option.forEach(onToggle, (function (fn) {
                  Curry._1(fn, state$1.isOpen);
                }));
        }), [state$1.isOpen]);
  React.useEffect((function () {
          var firstKey = Belt_Option.flatMap(Caml_option.nullable_to_opt(state$1.collection.getFirstKey()), (function (key) {
                  return Caml_option.nullable_to_opt(state$1.collection.getKeyAfter(key));
                }));
          var noItemSelected = (state$1.selectedKey == null);
          var firstItemSelected = firstKey === Caml_option.nullable_to_opt(state$1.selectedKey);
          if (state$1.isOpen && (noItemSelected || firstItemSelected)) {
            state$1.selectionManager.setFocusedKey(firstKey);
          }
          
        }), [state$1.isOpen]);
  var onScroll = React.useCallback((function ($$event) {
          var element = ReactDomEventTarget$Pos.toUnsafeDomEventTarget($$event.currentTarget);
          var scrollHeight = element.scrollHeight;
          var clientHeight = element.clientHeight;
          var scrollOffset = scrollHeight - (clientHeight << 1) | 0;
          if (element.scrollTop > scrollOffset) {
            return Curry._1(setItemsLimit, (function (itemsLimit) {
                          return itemsLimit + ListBox$Pos.defaultItemsPerScroll | 0;
                        }));
          }
          
        }), []);
  var onRequestToggle = state$1.toggle;
  var onRequestClose = React.useCallback((function (param) {
          if (!triggerHovered) {
            Curry._1(state$1.setFocused, false);
            return Curry._1(state$1.close, undefined);
          }
          
        }), [triggerHovered]);
  var opened = state$1.isOpen;
  var searchable$1 = Belt_Option.getWithDefault(searchable, sectionsItems.length > 5);
  var listBoxProps = ReactAria.mergeProps(match$5.menuProps, {
        shouldUseVirtualFocus: searchable$1
      });
  var selectedItem = Belt_Option.flatMap(Caml_option.nullable_to_opt(state$1.selectedKey), (function (key) {
          return Belt_Array.getBy(sectionsItems, (function (item) {
                        return item.key === key;
                      }));
        }));
  var tmp = {
    triggerRef: triggerRef,
    variation: preset,
    size: size,
    placeholder: placeholder,
    state: state$1,
    props: match$5.triggerProps,
    item: selectedItem,
    valueProps: match$5.valueProps,
    hovered: triggerHovered,
    highlighted: triggerHighlighted,
    excludeFromTabOrder: excludeFromTabOrder
  };
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (renderTriggerView !== undefined) {
    tmp.renderTriggerView = Caml_option.valFromOption(renderTriggerView);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Select$TriggerButton, tmp), opened ? React.createElement(Popover$Pos.make, {
                    children: null,
                    triggerRef: triggerRef,
                    state: {
                      isOpen: opened,
                      close: onRequestClose,
                      toggle: onRequestToggle
                    },
                    size: size,
                    placement: "bottom start",
                    layout: "triggerMinWidth",
                    modal: false
                  }, searchable$1 ? React.createElement(make, {
                          value: filterValue,
                          onChange: (function (value) {
                              Curry._1(setFilterValue, (function (param) {
                                      return value;
                                    }));
                            }),
                          onRequestClear: (function (param) {
                              Curry._1(setFilterValue, (function (param) {
                                      return "";
                                    }));
                            })
                        }) : null, React.createElement(ListBox$Pos.make, {
                        domRef: listBoxRef,
                        state: state$1,
                        props: listBoxProps,
                        size: size,
                        itemsLimit: match$1[0],
                        loading: loading,
                        onScroll: onScroll
                      }), footerLink !== undefined ? React.createElement(make$1, {
                          text: footerLink.text,
                          route: footerLink.route,
                          onRequestClose: onRequestClose
                        }) : null) : null);
}

var make$2 = Select;

export {
  processItems ,
  make$2 as make,
}
/* styles Not a pure module */
