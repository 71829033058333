// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ButtonPhased$Pos from "../../resources/actions/ButtonPhased.bs.js";
import * as ProductPriceTableCell$Pos from "../Product/ProductPriceTableCell.bs.js";
import * as PricePurchaseTableEditCell$Pos from "./PricePurchaseTableEditCell.bs.js";

function PricePurchaseTableEditCellWrapper(Props) {
  var value = Props.value;
  var formattedPurchasePrice = Props.formattedPurchasePrice;
  var bulkUnit = Props.bulkUnit;
  var minRetailPrice = Props.minRetailPrice;
  var disabledOpt = Props.disabled;
  var variantId = Props.variantId;
  var compactOpt = Props.compact;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var match = React.useState(function () {
        return formattedPurchasePrice;
      });
  var setFormattedValue = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFocused = match$1[1];
  var onFocus = function (param) {
    Curry._1(setFocused, (function (param) {
            return true;
          }));
  };
  var onBlur = function (param) {
    Curry._1(setFocused, (function (param) {
            return false;
          }));
  };
  var match$2 = Hover$Pos.use(undefined, undefined);
  var ref = match$2[0];
  var onChange = React.useCallback((function (value) {
          Curry._1(setFormattedValue, (function (param) {
                  return Intl$Pos.currencyFormat("EUR", 3, 3, value) + Belt_Option.mapWithDefault(bulkUnit, "", (function (unit) {
                                return "/" + unit;
                              }));
                }));
        }), [bulkUnit]);
  var onPressButton = function (param) {
    Curry._1(setFocused, (function (param) {
            return true;
          }));
  };
  var tmp;
  if (match$1[0]) {
    var tmp$1 = {
      value: value,
      bulkUnit: bulkUnit,
      variantId: variantId,
      autoFocused: true,
      onChange: onChange,
      onFocus: onFocus,
      onBlur: onBlur
    };
    if (minRetailPrice !== undefined) {
      tmp$1.minRetailPrice = minRetailPrice;
    }
    tmp = React.createElement(PricePurchaseTableEditCell$Pos.make, tmp$1);
  } else {
    tmp = React.createElement(ButtonPhased$Pos.make, ButtonPhased$Pos.makeProps(React.createElement(ProductPriceTableCell$Pos.make, {
                  value: match[0],
                  size: compact ? "xsmall" : "normal"
                }), undefined, match$2[1], undefined, undefined, undefined, disabled, onPressButton, undefined, ref, undefined));
  }
  return React.createElement(Inline$Pos.make, {
              children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(tmp), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-13)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, ref, undefined))
            });
}

var make = React.memo(PricePurchaseTableEditCellWrapper);

export {
  make ,
}
/* make Not a pure module */
