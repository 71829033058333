// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Field$Pos from "../layout-and-structure/Field.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as ReactAria$Pos from "../../externals/ReactAria.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as ReactStately from "react-stately";
import * as ReactDomElement$Pos from "../../primitives/ReactDomElement.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";

var buttonHeight = 40 / 2;

function borderColor(hovered, focused, errored) {
  if (focused && errored) {
    return "#b01745";
  } else if (!focused && errored) {
    return "#e61e5a";
  } else if (focused && !errored) {
    return "#a2a1b0";
  } else if (hovered && !focused && !errored) {
    return "#bdbdca";
  } else {
    return "#d7d7e0";
  }
}

function containerStyle(shrinkInput) {
  return Style$Pos.unsafeCss({
              display: "inline-grid",
              gridTemplateAreas: "\n      \"input appender increment\"\n      \"input appender decrement\"\n    ",
              gridTemplateColumns: "" + (
                shrinkInput ? "0" : "1"
              ) + "fr 0fr auto"
            });
}

function inputStyle(hovered, focused, disabled, stepper, errored, placeholder) {
  return {
          backgroundColor: disabled ? "#e7e7ee" : "#fefefe",
          borderColor: borderColor(hovered, focused, errored),
          borderStyle: "solid",
          borderWidth: String(1) + "px",
          color: placeholder || disabled ? Colors$Pos.placeholderTextColor : "#25243a",
          fontFamily: FontFaces$Pos.fontFamilyFromFontName("LibreFranklin"),
          fontSize: String(15) + "px",
          height: String(40) + "px",
          minWidth: String(60) + "px",
          paddingLeft: String(Spaces$Pos.normal) + "px",
          width: String(100) + "%",
          borderRadius: stepper ? String(0) + "px" : String(5) + "px",
          borderTopLeftRadius: String(5) + "px",
          borderBottomLeftRadius: String(5) + "px",
          flex: "1",
          boxSizing: "border-box",
          gridArea: "input"
        };
}

function appenderStyle(hovered, focused, disabled, stepper, errored) {
  return Style$Pos.unsafeCss({
              gridArea: "appender",
              display: "flex",
              minWidth: "max-content",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: String(-3) + "px",
              paddingRight: String(Spaces$Pos.normal - 4) + "px",
              paddingLeft: String(5) + "px",
              paddingTop: String(1) + "px",
              background: disabled ? "#e7e7ee" : "#fefefe",
              borderWidth: String(1) + "px",
              borderLeftWidth: "0",
              borderColor: borderColor(hovered, focused, errored),
              borderTopRightRadius: stepper ? String(0) + "px" : String(5) + "px",
              borderBottomRightRadius: stepper ? String(0) + "px" : String(5) + "px"
            });
}

function buttonStyle(hovered, focused, errored, pressed, disabled, mode) {
  return Object.assign({}, {
              backgroundColor: disabled || pressed ? "#f3f3f7" : "#fefefe",
              borderColor: borderColor(hovered, focused, errored),
              borderStyle: "solid",
              borderWidth: String(1) + "px",
              cursor: disabled ? "default" : "pointer",
              display: "flex",
              marginLeft: String(-1) + "px",
              width: String(18) + "px",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "border-box"
            }, mode === "increment" ? ({
                  borderBottomWidth: "0",
                  height: String(buttonHeight) + "px",
                  borderTopRightRadius: String(5) + "px",
                  gridArea: "increment"
                }) : ({
                  height: String(buttonHeight) + "px",
                  borderBottomRightRadius: String(5) + "px",
                  gridArea: "decrement"
                }));
}

function appenderCurrency(currency) {
  return {
          TAG: /* Currency */0,
          _0: currency
        };
}

function appenderCustom(custom) {
  return {
          TAG: /* Custom */1,
          _0: custom
        };
}

var make = React.memo(function (Props) {
      var props = Props.props;
      var mode = Props.mode;
      var derivedHovered = Props.hovered;
      var focused = Props.focused;
      var errored = Props.errored;
      var disabled = Props.disabled;
      var match = Hover$Pos.use(undefined, undefined);
      var hovered = match[1];
      var ref = match[0];
      var match$1 = React.useState(function () {
            return false;
          });
      var setPressed = match$1[1];
      var match$2 = ReactAria.useButton(ReactAria.mergeProps(props, {
                elementType: "div",
                isDisabled: disabled,
                onPressStart: (function (param) {
                    Curry._1(setPressed, (function (param) {
                            return true;
                          }));
                  }),
                onPressEnd: (function (param) {
                    Curry._1(setPressed, (function (param) {
                            return false;
                          }));
                  })
              }), ref);
      return React.createElement(ReactAria$Pos.Spread.make, {
                  props: match$2.buttonProps,
                  children: React.createElement("div", {
                        ref: ref,
                        style: buttonStyle(derivedHovered || hovered, focused, errored, match$1[0], disabled, mode)
                      }, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Icon$Pos.make, {
                                        name: mode === "increment" ? "arrow_up_light" : "arrow_down_light",
                                        fill: hovered ? (
                                            disabled ? (
                                                disabled ? "#bdbdca" : "#797885"
                                              ) : "#07061e"
                                          ) : (
                                            disabled ? "#bdbdca" : "#797885"
                                          ),
                                        size: 12
                                      })), undefined, undefined, undefined, undefined)))
                });
    });

function InputNumberField$OptionalValue(Props) {
  var value = Props.value;
  var minValue = Props.minValue;
  var maxValue = Props.maxValue;
  var step = Props.step;
  var minPrecision = Props.minPrecision;
  var precisionOpt = Props.precision;
  var label = Props.label;
  var infotip = Props.infotip;
  var errorMessage = Props.errorMessage;
  var placeholder = Props.placeholder;
  var appender = Props.appender;
  var shrinkInputOpt = Props.shrinkInput;
  var autoFocusedOpt = Props.autoFocused;
  var derivedFocusedOpt = Props.focused;
  var requiredOpt = Props.required;
  var disabledOpt = Props.disabled;
  var hideStepperOpt = Props.hideStepper;
  var useGroupingOpt = Props.useGrouping;
  var derivedOnChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var testLocalization = Props.testLocalization;
  var precision = precisionOpt !== undefined ? precisionOpt : 2;
  var shrinkInput = shrinkInputOpt !== undefined ? shrinkInputOpt : false;
  var autoFocused = autoFocusedOpt !== undefined ? autoFocusedOpt : false;
  var derivedFocused = derivedFocusedOpt !== undefined ? derivedFocusedOpt : false;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var hideStepper = hideStepperOpt !== undefined ? hideStepperOpt : false;
  var useGrouping = useGroupingOpt !== undefined ? useGroupingOpt : true;
  var match = React.useState(function () {
        if (autoFocused) {
          return true;
        } else {
          return derivedFocused;
        }
      });
  var setFocused = match[1];
  var focused = match[0];
  var inputRef = React.useRef(null);
  var match$1 = Hover$Pos.use(inputRef, undefined);
  var hovered = match$1[1];
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(setFocused, (function (param) {
                  return derivedFocused;
                }));
        }), [derivedFocused]);
  var formatOptions = React.useMemo((function () {
          if (focused && precision !== 0) {
            return {
                    useGrouping: useGrouping
                  };
          }
          return {
                  minimumFractionDigits: Belt_Option.getWithDefault(minPrecision, precision),
                  maximumFractionDigits: precision,
                  useGrouping: useGrouping
                };
        }), [
        focused,
        precision
      ]);
  var onChange = React.useCallback((function (value) {
          var handler = function (param) {
            Curry._1(derivedOnChange, Number.isFinite(value) ? value : undefined);
          };
          if (minValue !== undefined) {
            if (maxValue !== undefined) {
              if (value >= minValue && value <= maxValue) {
                return handler(undefined);
              } else {
                return ;
              }
            } else if (value >= minValue) {
              return handler(undefined);
            } else {
              return ;
            }
          } else if (maxValue !== undefined && value > maxValue) {
            return ;
          } else {
            return handler(undefined);
          }
        }), [
        derivedOnChange,
        minValue,
        maxValue
      ]);
  var onFocusChange = React.useCallback((function (value) {
          Curry._1(setFocused, (function (param) {
                  return value;
                }));
        }), []);
  var onKeyDown = React.useCallback((function ($$event) {
          var match = $$event.key;
          if (!focused) {
            return ;
          }
          if (match !== "Enter") {
            return ;
          }
          Belt_Option.map(ReactDomElement$Pos.fromRef(inputRef), (function (domElement) {
                  domElement.blur();
                }));
        }), [
        focused,
        value
      ]);
  var props_locale = Belt_Option.getWithDefault(testLocalization, Intl$Pos.locale);
  var props_value = value !== undefined ? value : NaN;
  var props_aria$label = Belt_Option.getWithDefault(label, Intl$Pos.t("Numeric input field"));
  var props_isDisabled = disabled;
  var props_autoFocus = autoFocused;
  var props_onChange = onChange;
  var props_onFocusChange = onFocusChange;
  var props_onKeyDown = onKeyDown;
  var props = {
    locale: props_locale,
    value: props_value,
    minValue: minValue,
    maxValue: maxValue,
    step: step,
    formatOptions: formatOptions,
    placeholder: placeholder,
    "aria-label": props_aria$label,
    isDisabled: props_isDisabled,
    autoFocus: props_autoFocus,
    onChange: props_onChange,
    onFocus: onFocus,
    onFocusChange: props_onFocusChange,
    onBlur: onBlur,
    onKeyDown: props_onKeyDown
  };
  var state = ReactStately.useNumberFieldState(props);
  var props$1 = ReactAria.useNumberField(props, state, inputRef);
  ReactUpdateEffect$Pos.use1((function (param) {
          if (focused && state.inputValue.includes(".")) {
            Curry._1(state.setInputValue, state.inputValue.replace(".", ","));
          }
          
        }), [state.inputValue]);
  ReactUpdateEffect$Pos.use1((function (param) {
          if (focused && state.numberValue !== value && !Number.isNaN(state.numberValue)) {
            Curry._1(onChange, state.numberValue);
          }
          
        }), [state.numberValue]);
  ReactUpdateEffect$Pos.use1((function (param) {
          if (!Number.isFinite(state.numberValue)) {
            Curry._1(derivedOnChange, undefined);
          }
          
        }), [state.numberValue]);
  var inputProps = React.useMemo((function () {
          return ReactAria.mergeProps(props$1.inputProps, {
                      placeholder: Belt_Option.getWithDefault(placeholder, "")
                    });
        }), [
        props$1.inputProps,
        placeholder
      ]);
  var inputStyle$1 = inputStyle(hovered, focused, disabled, !hideStepper, Belt_Option.isSome(errorMessage), value !== undefined ? (
          value !== 0 || focused ? Number.isNaN(value) : true
        ) : false);
  var value$1 = state.inputValue;
  var valueDecimal = value$1.includes(".") || value$1.includes(",");
  var valueLength = value$1.length - (
    valueDecimal ? 1 : 0
  );
  var inputStyle$2 = shrinkInput ? Object.assign({}, inputStyle$1, {
          minWidth: "calc(25px + " + String(valueLength) + "ch)"
        }) : inputStyle$1;
  var appenderStyle$1 = appenderStyle(hovered, focused, disabled, !hideStepper, Belt_Option.isSome(errorMessage));
  var tmp;
  if (appender !== undefined) {
    var tmp$1;
    tmp$1 = typeof appender === "number" ? "%" : (
        appender.TAG === /* Currency */0 ? Intl$Pos.toCurrencySymbol(appender._0) : appender._0
      );
    tmp = React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                      children: tmp$1,
                      size: "large",
                      opacity: 0.3
                    })), Caml_option.some(appenderStyle$1), undefined, undefined, undefined));
  } else {
    tmp = null;
  }
  return React.createElement(Field$Pos.make, Field$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(containerStyle(shrinkInput)), undefined, undefined, undefined), React.createElement(ReactAria$Pos.Spread.make, {
                          props: inputProps,
                          children: React.createElement("input", {
                                ref: inputRef,
                                style: inputStyle$2
                              })
                        }), tmp, hideStepper ? null : React.createElement(React.Fragment, undefined, React.createElement(make, {
                                props: props$1.incrementButtonProps,
                                mode: "increment",
                                hovered: hovered,
                                focused: focused,
                                errored: Belt_Option.isSome(errorMessage),
                                disabled: !state.canIncrement
                              }), React.createElement(make, {
                                props: props$1.decrementButtonProps,
                                mode: "decrement",
                                hovered: hovered,
                                focused: focused,
                                errored: Belt_Option.isSome(errorMessage),
                                disabled: !state.canDecrement
                              }))), label, undefined, infotip, errorMessage, undefined, required, undefined, undefined, undefined));
}

var make$1 = React.memo(InputNumberField$OptionalValue);

var OptionalValue = {
  make: make$1
};

function InputNumberField(Props) {
  var value = Props.value;
  var minValue = Props.minValue;
  var maxValue = Props.maxValue;
  var step = Props.step;
  var minPrecision = Props.minPrecision;
  var precisionOpt = Props.precision;
  var label = Props.label;
  var infotip = Props.infotip;
  var errorMessage = Props.errorMessage;
  var placeholder = Props.placeholder;
  var appender = Props.appender;
  var shrinkInputOpt = Props.shrinkInput;
  var autoFocusedOpt = Props.autoFocused;
  var focusedOpt = Props.focused;
  var requiredOpt = Props.required;
  var disabledOpt = Props.disabled;
  var hideStepperOpt = Props.hideStepper;
  var useGroupingOpt = Props.useGrouping;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var testLocalization = Props.testLocalization;
  var precision = precisionOpt !== undefined ? precisionOpt : 2;
  var shrinkInput = shrinkInputOpt !== undefined ? shrinkInputOpt : false;
  var autoFocused = autoFocusedOpt !== undefined ? autoFocusedOpt : false;
  var focused = focusedOpt !== undefined ? focusedOpt : false;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var hideStepper = hideStepperOpt !== undefined ? hideStepperOpt : false;
  var useGrouping = useGroupingOpt !== undefined ? useGroupingOpt : true;
  var onChange$1 = React.useCallback((function (nextValue) {
          if (nextValue !== undefined) {
            return Curry._1(onChange, nextValue);
          } else {
            return Curry._1(onChange, value);
          }
        }), [
        onChange,
        value
      ]);
  var tmp = {
    value: value,
    precision: precision,
    shrinkInput: shrinkInput,
    autoFocused: autoFocused,
    focused: focused,
    required: required,
    disabled: disabled,
    hideStepper: hideStepper,
    useGrouping: useGrouping,
    onChange: onChange$1
  };
  if (minValue !== undefined) {
    tmp.minValue = Caml_option.valFromOption(minValue);
  }
  if (maxValue !== undefined) {
    tmp.maxValue = Caml_option.valFromOption(maxValue);
  }
  if (step !== undefined) {
    tmp.step = Caml_option.valFromOption(step);
  }
  if (minPrecision !== undefined) {
    tmp.minPrecision = Caml_option.valFromOption(minPrecision);
  }
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (infotip !== undefined) {
    tmp.infotip = Caml_option.valFromOption(infotip);
  }
  if (errorMessage !== undefined) {
    tmp.errorMessage = Caml_option.valFromOption(errorMessage);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (appender !== undefined) {
    tmp.appender = Caml_option.valFromOption(appender);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (testLocalization !== undefined) {
    tmp.testLocalization = Caml_option.valFromOption(testLocalization);
  }
  return React.createElement(make$1, tmp);
}

var make$2 = React.memo(InputNumberField);

var appenderPercent = /* Percent */0;

export {
  appenderCurrency ,
  appenderPercent ,
  appenderCustom ,
  OptionalValue ,
  make$2 as make,
}
/* make Not a pure module */
