// Generated by ReScript, PLEASE EDIT WITH CARE


var Product = {};

var brandColor40 = "#c04695";

var brandColor50 = "#a01e72";

var brandColor55 = "#960F6F";

var brandColor60 = "#8a0262";

var placeholderTextColor = "#bdbdca";

export {
  brandColor40 ,
  brandColor50 ,
  brandColor55 ,
  brandColor60 ,
  Product ,
  placeholderTextColor ,
}
/* No side effect */
