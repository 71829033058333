// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as ButtonPhased$Pos from "../../resources/actions/ButtonPhased.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as CatalogSupplierTableEditCell$Pos from "./CatalogSupplierTableEditCell.bs.js";

function CatalogSupplierTableEditCellWrapper(Props) {
  var supplierId = Props.supplierId;
  var supplierName = Props.supplierName;
  var variantId = Props.variantId;
  var shopId = Props.shopId;
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var match$1 = Hover$Pos.use(undefined, undefined);
  var ref = match$1[0];
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(setFocused, (function (param) {
                  return false;
                }));
        }), [supplierId]);
  var tmp;
  if (match[0]) {
    tmp = React.createElement(CatalogSupplierTableEditCell$Pos.make, {
          supplierId: supplierId,
          supplierName: supplierName,
          variantId: variantId,
          shopId: shopId,
          onDismiss: (function (param) {
              Curry._1(setFocused, (function (param) {
                      return false;
                    }));
            })
        });
  } else {
    var tmp$1 = supplierName === "" ? React.createElement(TextStyle$Pos.make, {
            children: Intl$Pos.t("To be filled"),
            variation: "subdued"
          }) : React.createElement(TextStyle$Pos.make, {
            children: supplierName
          });
    tmp = React.createElement(ButtonPhased$Pos.make, ButtonPhased$Pos.makeProps(tmp$1, undefined, match$1[1], undefined, undefined, undefined, undefined, (function (param) {
                Curry._1(setFocused, (function (param) {
                        return true;
                      }));
              }), undefined, ref, undefined));
  }
  return React.createElement(Inline$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(tmp), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-13)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))), undefined, undefined, undefined, undefined, undefined, "xxlarge", undefined, undefined, undefined, ref, undefined))
            });
}

var make = React.memo(CatalogSupplierTableEditCellWrapper);

export {
  make ,
}
/* make Not a pure module */
