// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Stack$Pos from "../layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Tooltip$Pos from "./Tooltip.bs.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as ReactUpdateDebounced$Pos from "../../primitives/ReactUpdateDebounced.bs.js";

var styles = StyleSheet$Pos.create({
      wrapperTooltip: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#35334b", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(350)), undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.normal)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.normal)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textTooltip: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, "#fefefe", undefined, FontSizes$Pos.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function TooltipIcon(Props) {
  var children = Props.children;
  var variation = Props.variation;
  var arrowedOpt = Props.arrowed;
  var placementOpt = Props.placement;
  var altTriggerRef = Props.altTriggerRef;
  var offsetOpt = Props.offset;
  var crossOffsetOpt = Props.crossOffset;
  var arrowed = arrowedOpt !== undefined ? arrowedOpt : false;
  var placement = placementOpt !== undefined ? placementOpt : "bottom start";
  var offset = offsetOpt !== undefined ? offsetOpt : 6;
  var crossOffset = crossOffsetOpt !== undefined ? crossOffsetOpt : -3;
  var match = Hover$Pos.use(altTriggerRef, undefined);
  var debouncedHovered = ReactUpdateDebounced$Pos.use(match[1], 250);
  var opened = Belt_Option.map(altTriggerRef, (function (param) {
          return debouncedHovered;
        }));
  var overlayContent = React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                    children: React.createElement(Text$Pos.make, Text$Pos.makeProps(children, Caml_option.some(styles.textTooltip), undefined, undefined, undefined)),
                    space: "xsmall"
                  })), Caml_option.some(styles.wrapperTooltip), undefined, undefined, undefined));
  var tmp = {
    children: React.createElement(Icon$Pos.make, {
          name: variation === "alert" ? "alert_tip" : "info_tip",
          fill: variation === "alert" ? "#535165" : "#a2a1b0"
        }),
    arrowed: arrowed,
    placement: placement,
    offset: offset,
    crossOffset: crossOffset,
    content: {
      TAG: /* Component */1,
      _0: overlayContent
    },
    delay: 250,
    immediateClose: true
  };
  if (opened !== undefined) {
    tmp.opened = opened;
  }
  return React.createElement(Tooltip$Pos.make, tmp);
}

var make = TooltipIcon;

export {
  make ,
}
/* styles Not a pure module */
