// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as PromotionEditDiscountedProduct$Pos from "./PromotionEditDiscountedProduct.bs.js";
import * as LegacyProductReferenceTableCell$Pos from "../Product/LegacyProductReferenceTableCell.bs.js";

var Raw = {};

var query = Client.gql(["query variantsDistinctOnCku($search: String, $before: String, $after: String, $first: Int, $last: Int)  {\nvariantsDistinctOnCku(search: $search, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncku  \nformattedName  \nformattedDescription  \nstockKeepingUnit  \ncapacityUnit  \nbulk  \npurchasedPrice  \nvariantPrices(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nvalueIncludingTax  \nprice  {\n__typename  \nname  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          variantsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.stockKeepingUnit;
                    var value$3 = value$1.capacityUnit;
                    var value$4 = value$1.bulk;
                    var value$5 = value$1.purchasedPrice;
                    var value$6 = value$1.variantPrices;
                    var value$7 = value$6.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              cku: Scalar$Pos.CKU.parse(value$1.cku),
                              formattedName: value$1.formattedName,
                              formattedDescription: value$1.formattedDescription,
                              stockKeepingUnit: !(value$2 == null) ? value$2 : undefined,
                              capacityUnit: !(value$3 == null) ? value$3 : undefined,
                              bulk: !(value$4 == null) ? value$4 : undefined,
                              purchasedPrice: !(value$5 == null) ? value$5 : undefined,
                              variantPrices: {
                                __typename: value$6.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.price;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  price: !(value$2 == null) ? ({
                                                        __typename: value$2.__typename,
                                                        name: value$2.name
                                                      }) : undefined
                                                }
                                              };
                                      }), value$7)
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variantPrices;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.name;
                    var value$4 = value$2.__typename;
                    price = {
                      __typename: value$4,
                      name: value$3
                    };
                  } else {
                    price = null;
                  }
                  var value$5 = value$1.valueIncludingTax;
                  var value$6 = value$1.__typename;
                  var node = {
                    __typename: value$6,
                    valueIncludingTax: value$5,
                    price: price
                  };
                  var value$7 = value.__typename;
                  return {
                          __typename: value$7,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variantPrices = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.purchasedPrice;
          var purchasedPrice = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.bulk;
          var bulk = value$6 !== undefined ? value$6 : null;
          var value$7 = value$1.capacityUnit;
          var capacityUnit = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.formattedDescription;
          var value$10 = value$1.formattedName;
          var value$11 = value$1.cku;
          var value$12 = Scalar$Pos.CKU.serialize(value$11);
          var value$13 = value$1.id;
          var value$14 = value$1.__typename;
          var node = {
            __typename: value$14,
            id: value$13,
            cku: value$12,
            formattedName: value$10,
            formattedDescription: value$9,
            stockKeepingUnit: stockKeepingUnit,
            capacityUnit: capacityUnit,
            bulk: bulk,
            purchasedPrice: purchasedPrice,
            variantPrices: variantPrices
          };
          var value$15 = value.__typename;
          return {
                  __typename: value$15,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var variantsDistinctOnCku = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          variantsDistinctOnCku: variantsDistinctOnCku
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.before;
  var a$2 = inp.after;
  var a$3 = inp.first;
  var a$4 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          before: a$1 !== undefined ? a$1 : undefined,
          after: a$2 !== undefined ? a$2 : undefined,
          first: a$3 !== undefined ? a$3 : undefined,
          last: a$4 !== undefined ? a$4 : undefined
        };
}

function makeVariables(search, before, after, first, last, param) {
  return {
          search: search,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function keyExtractor(row) {
  return row.cku;
}

var Row = {
  keyExtractor: keyExtractor
};

function rowsFromData(data) {
  return Belt_Array.map(data.variantsDistinctOnCku.edges, (function (edge) {
                var description = edge.node.formattedDescription;
                var tmp = description === "" ? Intl$Pos.t("Description not filled in") : description;
                var match = edge.node.capacityUnit;
                var match$1 = Belt_Option.getWithDefault(edge.node.bulk, false);
                return {
                        id: edge.node.id,
                        cku: edge.node.cku,
                        name: edge.node.formattedName,
                        description: tmp,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        bulkUnit: match !== undefined && match$1 ? match : undefined,
                        purchasePrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        retailPrices: Belt_Array.keepMap(edge.node.variantPrices.edges, (function (param) {
                                var variantPrice = param.node;
                                var price = variantPrice.price;
                                if (price !== undefined) {
                                  return {
                                          priceName: price.name,
                                          valueIncludingTax: variantPrice.valueIncludingTax
                                        };
                                }
                                
                              }))
                      };
              }));
}

var columns = [{
    key: "reference",
    name: Intl$Pos.t("Product"),
    render: (function (param) {
        var product = param.data;
        var tmp = {
          variation: "secondary",
          name: product.name,
          description: product.description,
          disabled: param.disabled,
          openNewTab: true
        };
        if (product.stockKeepingUnit !== undefined) {
          tmp.stockKeepingUnit = product.stockKeepingUnit;
        }
        if (param.errorMessage !== undefined) {
          tmp.errorMessage = Caml_option.valFromOption(param.errorMessage);
        }
        return React.createElement(LegacyProductReferenceTableCell$Pos.make, tmp);
      })
  }];

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedPriceName = Props.selectedPriceName;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + Intl$Pos.t(Intl$Pos.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(TextStyle$Pos.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, Belt_Array.map(selectedProducts, (function (row) {
                    return PromotionEditDiscountedProduct$Pos.make(row.id, row.cku, row.name, row.description, row.stockKeepingUnit, row.purchasePrice, row.bulkUnit, Belt_Array.map(row.retailPrices, (function (param) {
                                      return [
                                              param.priceName,
                                              param.valueIncludingTax
                                            ];
                                    })), selectedPriceName);
                  })));
      };
      return React.createElement(Modal$Pos.make, {
                  children: children,
                  opened: opened,
                  title: Intl$Pos.t("Product append"),
                  variation: "secondary",
                  backgroundColor: "#fefefe",
                  renderStartText: renderStartText,
                  abortButtonText: Intl$Pos.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: Intl$Pos.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  make: make
};

function makeVariables$1(search, after, param) {
  return makeVariables(search, undefined, after, 10, undefined, undefined);
}

function PromotionEditDiscountedProductPickerModalTable(Props) {
  var opened = Props.opened;
  var selectedPriceName = Props.selectedPriceName;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var match = React.useState(function () {
        return [];
      });
  var setSelectedProducts = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$1[1];
  var searchQuery = match$1[0];
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        makeVariables$1(searchQuery, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers$Pos.useQueryResultToAsyncResultWithVariablesReloading1(query, searchQuery);
  var data = AsyncResult$Pos.mapOk(asyncResult, rowsFromData);
  React.useEffect((function () {
          if (!opened && searchQuery !== "") {
            Curry._1(setSearchQuery, (function (param) {
                    return "";
                  }));
          }
          
        }), [opened]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variantsDistinctOnCku.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(searchQuery, data$1.variantsDistinctOnCku.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variantsDistinctOnCku;
                    return {
                            variantsDistinctOnCku: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              edges: Belt_Array.concat(prevResult.variantsDistinctOnCku.edges, newVariants.edges)
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          Curry._1(setSelectedProducts, (function (prev) {
                  var rowsData;
                  if (typeof data === "number") {
                    rowsData = data === /* NotAsked */0 ? [] : [];
                  } else if (data.TAG === /* Reloading */0) {
                    var data$1 = data._0;
                    rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
                  } else {
                    var data$2 = data._0;
                    rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
                  }
                  if (selection) {
                    return Belt_Array.keepMap(selection._0, (function (selectedRowCku) {
                                  return Belt_Array.getBy(Belt_Array.concat(rowsData, prev), (function (row) {
                                                return row.cku === selectedRowCku;
                                              }));
                                }));
                  } else {
                    return prev;
                  }
                }));
        }), [
        data,
        disabledIds
      ]);
  return React.createElement(make, {
              children: React.createElement(AnimatedRender$Pos.make, {
                    children: React.createElement(TableView$Pos.make, {
                          data: data,
                          columns: columns,
                          keyExtractor: keyExtractor,
                          disabledRowsKeys: disabledIds,
                          selectionEnabled: true,
                          selectAllEnabled: false,
                          hideReloadingPlaceholder: true,
                          maxWidth: 580,
                          minHeight: 315,
                          maxHeight: 315,
                          searchPlaceholder: Intl$Pos.t("Search product"),
                          onSearchQueryChange: (function (value) {
                              Curry._1(setSearchQuery, (function (param) {
                                      return value;
                                    }));
                            }),
                          onLoadMore: onLoadMore,
                          onSelectChange: onSelectChange
                        }),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedPriceName: selectedPriceName,
              selectedProducts: match[0],
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$1 = React.memo(PromotionEditDiscountedProductPickerModalTable, (function (oldProps, newProps) {
        return oldProps.opened === newProps.opened;
      }));

export {
  Query ,
  Row ,
  rowsFromData ,
  columns ,
  PickerModal ,
  makeVariables$1 as makeVariables,
  make$1 as make,
}
/* query Not a pure module */
