// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as BillingAccountInvoicesTable$Pos from "../../modules/Billing/BillingAccountInvoicesTable.bs.js";

function SettingsBillingAccountListInvoicesPage(Props) {
  var invoicesRequest = Props.invoicesRequest;
  var scope = Auth$Pos.useScope(undefined);
  var activeShop;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    activeShop = shop !== undefined ? shop : scope.shops[0];
  } else {
    activeShop = scope._0;
  }
  return React.createElement(Page$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(BillingAccountInvoicesTable$Pos.make, {
                                invoicesRequest: invoicesRequest
                              })), undefined, undefined, "medium", undefined, undefined, undefined, true, undefined, undefined, undefined, undefined)),
              title: Intl$Pos.t("Invoice history"),
              subtitle: activeShop.name
            });
}

var make = React.memo(SettingsBillingAccountListInvoicesPage);

export {
  make ,
}
/* make Not a pure module */
