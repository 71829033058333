// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as PromotionEditDiscountedProduct$Pos from "./PromotionEditDiscountedProduct.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var MakeCreateCampaignsInputs_CreatorIdentifierEmpty = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.MakeCreateCampaignsInputs_CreatorIdentifierEmpty");

var MakeCreateCampaignsInputs_NameEmpty = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.MakeCreateCampaignsInputs_NameEmpty");

var MakeCreateCampaignsInputs_PeriodNotFound = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.MakeCreateCampaignsInputs_PeriodNotFound");

var MakeCreateCampaignsInputs_InvalidDiscountedProduct = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.MakeCreateCampaignsInputs_InvalidDiscountedProduct");

var MakeUpdateCampaignsInputs_NameEmpty = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.MakeUpdateCampaignsInputs_NameEmpty");

var MakeUpdateCampaignsInputs_PeriodNotFound = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.MakeUpdateCampaignsInputs_PeriodNotFound");

var MakeUpdateCampaignsInputs_InvalidDiscountedProduct = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.MakeUpdateCampaignsInputs_InvalidDiscountedProduct");

var CreateGlobalCampaignMutation_InvalidInputs = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.CreateGlobalCampaignMutation_InvalidInputs");

var CreateGlobalCampaignMutation_CreatorIdentifierNotFound = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.CreateGlobalCampaignMutation_CreatorIdentifierNotFound");

var UpdateGlobalCampaignMutation_InvalidInputs = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.UpdateGlobalCampaignMutation_InvalidInputs");

var StartCampaignMutation_InvalidStatus = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.StartCampaignMutation_InvalidStatus");

var StopCampaignMutation_InvalidStatus = /* @__PURE__ */Caml_exceptions.create("PromotionEditFormMutations-Pos.StopCampaignMutation_InvalidStatus");

var Raw = {};

var query = Client.gql(["mutation createPromotionCampaign($id: ID!, $cku: CKU!, $input: InputCreatePromotionCampaign!, $discountsInput: [InputPromotionCampaignDiscounts!]!)  {\ncreatePromotionCampaign(id: $id, cku: $cku, input: $input, promotionCampaignDiscountsInput: $discountsInput)  {\n__typename  \nid  \ncku  \nformattedStatus  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.createPromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var tmp;
  switch (value$2) {
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "DRAFT" :
        tmp = "DRAFT";
        break;
    case "EXPIRED" :
        tmp = "EXPIRED";
        break;
    case "NOT_PROGRAMMED" :
        tmp = "NOT_PROGRAMMED";
        break;
    case "ONGOING" :
        tmp = "ONGOING";
        break;
    case "PROGRAMMED" :
        tmp = "PROGRAMMED";
        break;
    case "STOPPED" :
        tmp = "STOPPED";
        break;
    default:
      throw {
            RE_EXN_ID: "Not_found",
            Error: new Error()
          };
  }
  return {
          createPromotionCampaign: {
            __typename: value$1.__typename,
            id: value$1.id,
            cku: Scalar$Pos.CKU.parse(value$1.cku),
            formattedStatus: tmp
          }
        };
}

function serialize(value) {
  var value$1 = value.createPromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = value$2 === "STOPPED" ? "STOPPED" : (
      value$2 === "ARCHIVED" ? "ARCHIVED" : (
          value$2 === "DRAFT" ? "DRAFT" : (
              value$2 === "NOT_PROGRAMMED" ? "NOT_PROGRAMMED" : (
                  value$2 === "ONGOING" ? "ONGOING" : (
                      value$2 === "EXPIRED" ? "EXPIRED" : "PROGRAMMED"
                    )
                )
            )
        )
    );
  var value$3 = value$1.cku;
  var value$4 = Scalar$Pos.CKU.serialize(value$3);
  var value$5 = value$1.id;
  var value$6 = value$1.__typename;
  var createPromotionCampaign = {
    __typename: value$6,
    id: value$5,
    cku: value$4,
    formattedStatus: formattedStatus
  };
  return {
          createPromotionCampaign: createPromotionCampaign
        };
}

function serializeInputObjectInputCreatePromotionCampaign(inp) {
  var a = inp.recurringDays;
  var a$1 = inp.recurringStartTime;
  var a$2 = inp.recurringEndTime;
  return {
          creatorIdentifier: inp.creatorIdentifier,
          name: inp.name,
          startDate: inp.startDate,
          endDate: inp.endDate,
          recurringDays: a !== undefined ? Js_array.map((function (b) {
                    if (b === "SUNDAY") {
                      return "SUNDAY";
                    } else if (b === "SATURDAY") {
                      return "SATURDAY";
                    } else if (b === "THURSDAY") {
                      return "THURSDAY";
                    } else if (b === "FRIDAY") {
                      return "FRIDAY";
                    } else if (b === "MONDAY") {
                      return "MONDAY";
                    } else if (b === "TUESDAY") {
                      return "TUESDAY";
                    } else {
                      return "WEDNESDAY";
                    }
                  }), a) : undefined,
          recurringStartTime: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          recurringEndTime: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          priceId: inp.priceId,
          shopId: inp.shopId
        };
}

function serializeInputObjectInputPromotionCampaignDiscounts(inp) {
  var a = inp.kind;
  return {
          variantCku: inp.variantCku,
          value: inp.value,
          kind: a === "PERCENT" ? "PERCENT" : "CURRENCY"
        };
}

function serializeVariables(inp) {
  var a = inp.discountsInput;
  return {
          id: inp.id,
          cku: inp.cku,
          input: serializeInputObjectInputCreatePromotionCampaign(inp.input),
          discountsInput: Js_array.map(serializeInputObjectInputPromotionCampaignDiscounts, a)
        };
}

function makeVariables(id, cku, input, discountsInput, param) {
  return {
          id: id,
          cku: cku,
          input: input,
          discountsInput: discountsInput
        };
}

function makeInputObjectInputCreatePromotionCampaign(creatorIdentifier, name, startDate, endDate, recurringDays, recurringStartTime, recurringEndTime, priceId, shopId, param) {
  return {
          creatorIdentifier: creatorIdentifier,
          name: name,
          startDate: startDate,
          endDate: endDate,
          recurringDays: recurringDays,
          recurringStartTime: recurringStartTime,
          recurringEndTime: recurringEndTime,
          priceId: priceId,
          shopId: shopId
        };
}

function makeInputObjectInputPromotionCampaignDiscounts(variantCku, value, kind, param) {
  return {
          variantCku: variantCku,
          value: value,
          kind: kind
        };
}

var CreateCampaignMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreatePromotionCampaign: serializeInputObjectInputCreatePromotionCampaign,
  serializeInputObjectInputPromotionCampaignDiscounts: serializeInputObjectInputPromotionCampaignDiscounts,
  makeVariables: makeVariables,
  makeInputObjectInputCreatePromotionCampaign: makeInputObjectInputCreatePromotionCampaign,
  makeInputObjectInputPromotionCampaignDiscounts: makeInputObjectInputPromotionCampaignDiscounts
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CreateCampaignMutation_useWithVariables = include.useWithVariables;

var CreateCampaignMutation = {
  CreateCampaignMutation_inner: CreateCampaignMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreatePromotionCampaign: serializeInputObjectInputCreatePromotionCampaign,
  serializeInputObjectInputPromotionCampaignDiscounts: serializeInputObjectInputPromotionCampaignDiscounts,
  makeVariables: makeVariables,
  makeInputObjectInputCreatePromotionCampaign: makeInputObjectInputCreatePromotionCampaign,
  makeInputObjectInputPromotionCampaignDiscounts: makeInputObjectInputPromotionCampaignDiscounts,
  use: use,
  useWithVariables: CreateCampaignMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation updatePromotionCampaign($id: ID!, $input: InputUpdatePromotionCampaign!, $discountsInput: [InputPromotionCampaignDiscounts!]!)  {\nupdatePromotionCampaign(id: $id, input: $input, promotionCampaignDiscountsInput: $discountsInput)  {\n__typename  \nid  \ncku  \nname  \nstartDate  \nendDate  \nformattedStatus  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.updatePromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var tmp;
  switch (value$2) {
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "DRAFT" :
        tmp = "DRAFT";
        break;
    case "EXPIRED" :
        tmp = "EXPIRED";
        break;
    case "NOT_PROGRAMMED" :
        tmp = "NOT_PROGRAMMED";
        break;
    case "ONGOING" :
        tmp = "ONGOING";
        break;
    case "PROGRAMMED" :
        tmp = "PROGRAMMED";
        break;
    case "STOPPED" :
        tmp = "STOPPED";
        break;
    default:
      throw {
            RE_EXN_ID: "Not_found",
            Error: new Error()
          };
  }
  return {
          updatePromotionCampaign: {
            __typename: value$1.__typename,
            id: value$1.id,
            cku: Scalar$Pos.CKU.parse(value$1.cku),
            name: value$1.name,
            startDate: Scalar$Pos.Datetime.parse(value$1.startDate),
            endDate: Scalar$Pos.Datetime.parse(value$1.endDate),
            formattedStatus: tmp
          }
        };
}

function serialize$1(value) {
  var value$1 = value.updatePromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = value$2 === "STOPPED" ? "STOPPED" : (
      value$2 === "ARCHIVED" ? "ARCHIVED" : (
          value$2 === "DRAFT" ? "DRAFT" : (
              value$2 === "NOT_PROGRAMMED" ? "NOT_PROGRAMMED" : (
                  value$2 === "ONGOING" ? "ONGOING" : (
                      value$2 === "EXPIRED" ? "EXPIRED" : "PROGRAMMED"
                    )
                )
            )
        )
    );
  var value$3 = value$1.endDate;
  var value$4 = Scalar$Pos.Datetime.serialize(value$3);
  var value$5 = value$1.startDate;
  var value$6 = Scalar$Pos.Datetime.serialize(value$5);
  var value$7 = value$1.name;
  var value$8 = value$1.cku;
  var value$9 = Scalar$Pos.CKU.serialize(value$8);
  var value$10 = value$1.id;
  var value$11 = value$1.__typename;
  var updatePromotionCampaign = {
    __typename: value$11,
    id: value$10,
    cku: value$9,
    name: value$7,
    startDate: value$6,
    endDate: value$4,
    formattedStatus: formattedStatus
  };
  return {
          updatePromotionCampaign: updatePromotionCampaign
        };
}

function serializeInputObjectInputPromotionCampaignDiscounts$1(inp) {
  var a = inp.kind;
  return {
          variantCku: inp.variantCku,
          value: inp.value,
          kind: a === "PERCENT" ? "PERCENT" : "CURRENCY"
        };
}

function serializeInputObjectInputUpdatePromotionCampaign(inp) {
  var a = inp.name;
  var a$1 = inp.startDate;
  var a$2 = inp.endDate;
  var a$3 = inp.recurringDays;
  var a$4 = inp.recurringStartTime;
  var a$5 = inp.recurringEndTime;
  return {
          name: a !== undefined ? a : undefined,
          startDate: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          endDate: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          recurringDays: a$3 !== undefined ? Js_array.map((function (b) {
                    if (b === "SUNDAY") {
                      return "SUNDAY";
                    } else if (b === "SATURDAY") {
                      return "SATURDAY";
                    } else if (b === "THURSDAY") {
                      return "THURSDAY";
                    } else if (b === "FRIDAY") {
                      return "FRIDAY";
                    } else if (b === "MONDAY") {
                      return "MONDAY";
                    } else if (b === "TUESDAY") {
                      return "TUESDAY";
                    } else {
                      return "WEDNESDAY";
                    }
                  }), a$3) : undefined,
          recurringStartTime: a$4 !== undefined ? Caml_option.valFromOption(a$4) : undefined,
          recurringEndTime: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.discountsInput;
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdatePromotionCampaign(inp.input),
          discountsInput: Js_array.map(serializeInputObjectInputPromotionCampaignDiscounts$1, a)
        };
}

function makeVariables$1(id, input, discountsInput, param) {
  return {
          id: id,
          input: input,
          discountsInput: discountsInput
        };
}

function makeInputObjectInputUpdatePromotionCampaign(name, startDate, endDate, recurringDays, recurringStartTime, recurringEndTime, param) {
  return {
          name: name,
          startDate: startDate,
          endDate: endDate,
          recurringDays: recurringDays,
          recurringStartTime: recurringStartTime,
          recurringEndTime: recurringEndTime
        };
}

function makeInputObjectInputPromotionCampaignDiscounts$1(variantCku, value, kind, param) {
  return {
          variantCku: variantCku,
          value: value,
          kind: kind
        };
}

var UpdateCampaignMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdatePromotionCampaign: serializeInputObjectInputUpdatePromotionCampaign,
  serializeInputObjectInputPromotionCampaignDiscounts: serializeInputObjectInputPromotionCampaignDiscounts$1,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdatePromotionCampaign: makeInputObjectInputUpdatePromotionCampaign,
  makeInputObjectInputPromotionCampaignDiscounts: makeInputObjectInputPromotionCampaignDiscounts$1
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var UpdateCampaignMutation_useWithVariables = include$1.useWithVariables;

var UpdateCampaignMutation = {
  UpdateCampaignMutation_inner: UpdateCampaignMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdatePromotionCampaign: serializeInputObjectInputUpdatePromotionCampaign,
  serializeInputObjectInputPromotionCampaignDiscounts: serializeInputObjectInputPromotionCampaignDiscounts$1,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdatePromotionCampaign: makeInputObjectInputUpdatePromotionCampaign,
  makeInputObjectInputPromotionCampaignDiscounts: makeInputObjectInputPromotionCampaignDiscounts$1,
  use: use$1,
  useWithVariables: UpdateCampaignMutation_useWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["mutation startPromotionCampaign($id: ID!)  {\nstartPromotionCampaign(id: $id)  {\n__typename  \nid  \ncku  \nformattedStatus  \n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.startPromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var tmp;
  switch (value$2) {
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "DRAFT" :
        tmp = "DRAFT";
        break;
    case "EXPIRED" :
        tmp = "EXPIRED";
        break;
    case "NOT_PROGRAMMED" :
        tmp = "NOT_PROGRAMMED";
        break;
    case "ONGOING" :
        tmp = "ONGOING";
        break;
    case "PROGRAMMED" :
        tmp = "PROGRAMMED";
        break;
    case "STOPPED" :
        tmp = "STOPPED";
        break;
    default:
      throw {
            RE_EXN_ID: "Not_found",
            Error: new Error()
          };
  }
  return {
          startPromotionCampaign: {
            __typename: value$1.__typename,
            id: value$1.id,
            cku: Scalar$Pos.CKU.parse(value$1.cku),
            formattedStatus: tmp
          }
        };
}

function serialize$2(value) {
  var value$1 = value.startPromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = value$2 === "STOPPED" ? "STOPPED" : (
      value$2 === "ARCHIVED" ? "ARCHIVED" : (
          value$2 === "DRAFT" ? "DRAFT" : (
              value$2 === "NOT_PROGRAMMED" ? "NOT_PROGRAMMED" : (
                  value$2 === "ONGOING" ? "ONGOING" : (
                      value$2 === "EXPIRED" ? "EXPIRED" : "PROGRAMMED"
                    )
                )
            )
        )
    );
  var value$3 = value$1.cku;
  var value$4 = Scalar$Pos.CKU.serialize(value$3);
  var value$5 = value$1.id;
  var value$6 = value$1.__typename;
  var startPromotionCampaign = {
    __typename: value$6,
    id: value$5,
    cku: value$4,
    formattedStatus: formattedStatus
  };
  return {
          startPromotionCampaign: startPromotionCampaign
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var StartCampaignMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var StartCampaignMutation_useWithVariables = include$2.useWithVariables;

var StartCampaignMutation = {
  StartCampaignMutation_inner: StartCampaignMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  use: use$2,
  useWithVariables: StartCampaignMutation_useWithVariables
};

var Raw$3 = {};

var query$3 = Client.gql(["mutation stopPromotionCampaign($id: ID!)  {\nstopPromotionCampaign(id: $id)  {\n__typename  \nid  \ncku  \nformattedStatus  \n}\n\n}\n"]);

function parse$3(value) {
  var value$1 = value.stopPromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var tmp;
  switch (value$2) {
    case "ARCHIVED" :
        tmp = "ARCHIVED";
        break;
    case "DRAFT" :
        tmp = "DRAFT";
        break;
    case "EXPIRED" :
        tmp = "EXPIRED";
        break;
    case "NOT_PROGRAMMED" :
        tmp = "NOT_PROGRAMMED";
        break;
    case "ONGOING" :
        tmp = "ONGOING";
        break;
    case "PROGRAMMED" :
        tmp = "PROGRAMMED";
        break;
    case "STOPPED" :
        tmp = "STOPPED";
        break;
    default:
      throw {
            RE_EXN_ID: "Not_found",
            Error: new Error()
          };
  }
  return {
          stopPromotionCampaign: {
            __typename: value$1.__typename,
            id: value$1.id,
            cku: Scalar$Pos.CKU.parse(value$1.cku),
            formattedStatus: tmp
          }
        };
}

function serialize$3(value) {
  var value$1 = value.stopPromotionCampaign;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = value$2 === "STOPPED" ? "STOPPED" : (
      value$2 === "ARCHIVED" ? "ARCHIVED" : (
          value$2 === "DRAFT" ? "DRAFT" : (
              value$2 === "NOT_PROGRAMMED" ? "NOT_PROGRAMMED" : (
                  value$2 === "ONGOING" ? "ONGOING" : (
                      value$2 === "EXPIRED" ? "EXPIRED" : "PROGRAMMED"
                    )
                )
            )
        )
    );
  var value$3 = value$1.cku;
  var value$4 = Scalar$Pos.CKU.serialize(value$3);
  var value$5 = value$1.id;
  var value$6 = value$1.__typename;
  var stopPromotionCampaign = {
    __typename: value$6,
    id: value$5,
    cku: value$4,
    formattedStatus: formattedStatus
  };
  return {
          stopPromotionCampaign: stopPromotionCampaign
        };
}

function serializeVariables$3(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$3(id, param) {
  return {
          id: id
        };
}

var StopCampaignMutation_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var include$3 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3
    });

var use$3 = include$3.use;

var StopCampaignMutation_useWithVariables = include$3.useWithVariables;

var StopCampaignMutation = {
  StopCampaignMutation_inner: StopCampaignMutation_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  use: use$3,
  useWithVariables: StopCampaignMutation_useWithVariables
};

function makeCreateCampaignsInputs(state, creatorIdentifier) {
  var match = state.period;
  var match$1;
  if (match !== undefined) {
    match$1 = [
      Scalar$Pos.Datetime.serialize(match[0]),
      Scalar$Pos.Datetime.serialize(match[1])
    ];
  } else {
    throw {
          RE_EXN_ID: MakeCreateCampaignsInputs_PeriodNotFound,
          Error: new Error()
        };
  }
  var endDate = match$1[1];
  var startDate = match$1[0];
  var rootCampaignsInputs = Belt_Array.keepMap(Belt_Array.reverse(state.rootCampaigns), (function (campaign) {
          var match = state.name;
          if (!campaign.selected) {
            return ;
          }
          if (creatorIdentifier === "") {
            throw {
                  RE_EXN_ID: MakeCreateCampaignsInputs_CreatorIdentifierEmpty,
                  Error: new Error()
                };
          }
          if (match !== "") {
            return [
                    campaign.id,
                    makeInputObjectInputCreatePromotionCampaign(creatorIdentifier, state.name, startDate, endDate, undefined, undefined, undefined, campaign.priceId, campaign.shopId, undefined)
                  ];
          }
          throw {
                RE_EXN_ID: MakeCreateCampaignsInputs_NameEmpty,
                Error: new Error()
              };
        }));
  var discountsInput = Belt_Array.map(Belt_Array.reverse(state.discountedProducts), (function (product) {
          var match = PromotionEditDiscountedProduct$Pos.isValid(product, state.priceName);
          if (match) {
            return {
                    variantCku: Scalar$Pos.CKU.serialize(product.cku),
                    value: product.discount.amount,
                    kind: product.discount.kind
                  };
          }
          throw {
                RE_EXN_ID: MakeCreateCampaignsInputs_InvalidDiscountedProduct,
                Error: new Error()
              };
        }));
  return [
          rootCampaignsInputs,
          discountsInput
        ];
}

function useCreateGlobalCampaign(param) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var result = match[1];
  var mutate = match[0];
  var authState = Auth$Pos.useState(undefined);
  return React.useCallback((function (param) {
                return function (state) {
                  var cku = Uuid.v4();
                  var creatorIdentifier;
                  if (typeof authState === "number") {
                    throw {
                          RE_EXN_ID: CreateGlobalCampaignMutation_CreatorIdentifierNotFound,
                          Error: new Error()
                        };
                  }
                  if (authState.TAG === /* Logging */0) {
                    throw {
                          RE_EXN_ID: CreateGlobalCampaignMutation_CreatorIdentifierNotFound,
                          Error: new Error()
                        };
                  }
                  creatorIdentifier = authState._0.user.id;
                  var match = makeCreateCampaignsInputs(state, creatorIdentifier);
                  var rootCampaignsInputs = match[0];
                  if (rootCampaignsInputs.length !== 0) {
                    var discountsInput = match[1];
                    if (discountsInput.length !== 0) {
                      return ApolloHelpers$Pos.mergeFutureResults(Belt_Array.map(rootCampaignsInputs, (function (param) {
                                        return Future.tapOk(Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, makeVariables(param[0], Scalar$Pos.CKU.serialize(cku), param[1], discountsInput, undefined))), undefined, (function (param) {
                                                          return param.createPromotionCampaign.cku;
                                                        })), (function (param) {
                                                      var match = result.client;
                                                      if (match !== undefined) {
                                                        Curry._1(match.rescript_resetStore, undefined);
                                                        return ;
                                                      }
                                                      
                                                    }));
                                      })));
                    }
                    throw {
                          RE_EXN_ID: CreateGlobalCampaignMutation_InvalidInputs,
                          Error: new Error()
                        };
                  }
                  throw {
                        RE_EXN_ID: CreateGlobalCampaignMutation_InvalidInputs,
                        Error: new Error()
                      };
                };
              }), []);
}

function makeUpdateCampaignsInputs(state) {
  var match = state.period;
  var match$1;
  if (match !== undefined) {
    match$1 = [
      Scalar$Pos.Datetime.serialize(match[0]),
      Scalar$Pos.Datetime.serialize(match[1])
    ];
  } else {
    throw {
          RE_EXN_ID: MakeUpdateCampaignsInputs_PeriodNotFound,
          Error: new Error()
        };
  }
  var endDate = match$1[1];
  var startDate = match$1[0];
  var rootCampaignsInputs = Belt_Array.keepMap(Belt_Array.reverse(state.rootCampaigns), (function (campaign) {
          if (!campaign.selected) {
            return ;
          }
          if (state.name !== "") {
            return [
                    campaign.id,
                    makeInputObjectInputUpdatePromotionCampaign(state.name, Caml_option.some(startDate), Caml_option.some(endDate), undefined, undefined, undefined, undefined)
                  ];
          }
          throw {
                RE_EXN_ID: MakeUpdateCampaignsInputs_NameEmpty,
                Error: new Error()
              };
        }));
  var discountsInput = Belt_Array.map(Belt_Array.reverse(state.discountedProducts), (function (product) {
          var match = PromotionEditDiscountedProduct$Pos.isValid(product, state.priceName);
          if (match) {
            return {
                    variantCku: Scalar$Pos.CKU.serialize(product.cku),
                    value: product.discount.amount,
                    kind: product.discount.kind
                  };
          }
          throw {
                RE_EXN_ID: MakeUpdateCampaignsInputs_InvalidDiscountedProduct,
                Error: new Error()
              };
        }));
  return [
          rootCampaignsInputs,
          discountsInput
        ];
}

function useUpdateGlobalCampaign(param) {
  var mutate = Curry.app(use$1, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ])[0];
  return React.useCallback((function (param) {
                return function (state) {
                  var match = makeUpdateCampaignsInputs(state);
                  var rootCampaignsInputs = match[0];
                  if (rootCampaignsInputs.length !== 0) {
                    var discountsInput = match[1];
                    if (discountsInput.length !== 0) {
                      return ApolloHelpers$Pos.mergeFutureResults(Belt_Array.map(rootCampaignsInputs, (function (param) {
                                        return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                                            id: param[0],
                                                            input: param[1],
                                                            discountsInput: discountsInput
                                                          })), undefined, (function (param) {
                                                      return param.updatePromotionCampaign.cku;
                                                    }));
                                      })));
                    }
                    throw {
                          RE_EXN_ID: UpdateGlobalCampaignMutation_InvalidInputs,
                          Error: new Error()
                        };
                  }
                  throw {
                        RE_EXN_ID: UpdateGlobalCampaignMutation_InvalidInputs,
                        Error: new Error()
                      };
                };
              }), []);
}

function useStartGlobalCampaign(param) {
  var mutate = Curry.app(use$2, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ])[0];
  return React.useCallback((function (param) {
                return function (state) {
                  return ApolloHelpers$Pos.mergeFutureResults(Belt_Array.keepMap(state.rootCampaigns, (function (campaign) {
                                    if (campaign.selected) {
                                      return Caml_option.some(Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                                              id: campaign.id
                                                            })), undefined, (function (param) {
                                                        return param.startPromotionCampaign.cku;
                                                      })));
                                    }
                                    
                                  })));
                };
              }), []);
}

function useStartCampaign(id) {
  var mutate = Curry.app(use$2, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ])[0];
  return React.useCallback((function (status) {
                if (status === "NOT_PROGRAMMED") {
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: id
                                    })), undefined, (function (param) {
                                return param.startPromotionCampaign.cku;
                              }));
                }
                throw {
                      RE_EXN_ID: StartCampaignMutation_InvalidStatus,
                      Error: new Error()
                    };
              }), []);
}

function useStopCampaign(id) {
  var mutate = Curry.app(use$3, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ])[0];
  return React.useCallback((function (status) {
                if (status === "ONGOING" || status === "PROGRAMMED") {
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: id
                                    })), undefined, (function (param) {
                                return param.stopPromotionCampaign.cku;
                              }));
                }
                throw {
                      RE_EXN_ID: StopCampaignMutation_InvalidStatus,
                      Error: new Error()
                    };
              }), []);
}

export {
  MakeCreateCampaignsInputs_CreatorIdentifierEmpty ,
  MakeCreateCampaignsInputs_NameEmpty ,
  MakeCreateCampaignsInputs_PeriodNotFound ,
  MakeCreateCampaignsInputs_InvalidDiscountedProduct ,
  MakeUpdateCampaignsInputs_NameEmpty ,
  MakeUpdateCampaignsInputs_PeriodNotFound ,
  MakeUpdateCampaignsInputs_InvalidDiscountedProduct ,
  CreateGlobalCampaignMutation_InvalidInputs ,
  CreateGlobalCampaignMutation_CreatorIdentifierNotFound ,
  UpdateGlobalCampaignMutation_InvalidInputs ,
  StartCampaignMutation_InvalidStatus ,
  StopCampaignMutation_InvalidStatus ,
  CreateCampaignMutation ,
  UpdateCampaignMutation ,
  StartCampaignMutation ,
  StopCampaignMutation ,
  makeCreateCampaignsInputs ,
  useCreateGlobalCampaign ,
  makeUpdateCampaignsInputs ,
  useUpdateGlobalCampaign ,
  useStartGlobalCampaign ,
  useStartCampaign ,
  useStopCampaign ,
}
/* query Not a pure module */
