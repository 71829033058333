// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as InlineText$Pos from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Auth__Hooks$Pos from "./Auth__Hooks.bs.js";
import * as OverlayTriggerView$Pos from "../../resources/overlays/OverlayTriggerView.bs.js";

function truncateWithMiddleEllipsis(str, maxLen) {
  if (str.length <= maxLen) {
    return str;
  }
  var len = str.length;
  var halfLen = (maxLen - 3 | 0) / 2 | 0;
  var startStr = str.slice(0, halfLen).trim();
  var endStr = str.slice(len - halfLen | 0, len).trim();
  return startStr + "..." + endStr;
}

function Auth__Elements$SelectShopFilter(Props) {
  var variationOpt = Props.variation;
  var value = Props.value;
  var disabledIdsOpt = Props.disabledIds;
  var hideLabelOpt = Props.hideLabel;
  var truncateItemLabelOpt = Props.truncateItemLabel;
  var onChange = Props.onChange;
  var variation = variationOpt !== undefined ? variationOpt : "primary";
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var hideLabel = hideLabelOpt !== undefined ? hideLabelOpt : false;
  var truncateItemLabel = truncateItemLabelOpt !== undefined ? truncateItemLabelOpt : false;
  var dispatch = Auth__Hooks$Pos.useDispatch(undefined);
  var shops = Auth__Hooks$Pos.useShops(undefined);
  var activeShop = Auth__Hooks$Pos.useActiveShop(undefined);
  var onChange$1 = function (nextShopId) {
    var nextShop = Belt_Option.flatMap(nextShopId, (function (shopId) {
            return Belt_Array.getBy(shops, (function (shop) {
                          return shop.id === shopId;
                        }));
          }));
    Belt_Option.forEach(onChange, (function (fn) {
            Curry._1(fn, nextShop);
          }));
    Curry._1(dispatch, {
          TAG: /* ActiveShopSet */2,
          _0: nextShop
        });
  };
  var shopItems = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name,
                  disabled: Belt_Array.some(disabledIds, (function (id) {
                          return id === shop.id;
                        }))
                };
        }));
  var sections = [
    {
      items: [{
          key: "default",
          label: Intl$Pos.template(Intl$Pos.t("All{{feminine}}"), undefined, undefined),
          sticky: true
        }]
    },
    {
      items: shopItems,
      title: Intl$Pos.t("Shops")
    }
  ];
  var value$1 = Belt_Option.map(Belt_Option.orElse(value, activeShop), (function (shop) {
          return shop.id;
        }));
  var label = !hideLabel ? Intl$Pos.t("Shop") : undefined;
  var preset = variation === "primary" ? "filter" : ({
        NAME: "inputField",
        VAL: {
          required: false
        }
      });
  var renderTriggerView = truncateItemLabel ? (function (param, item, hovered, active, focused) {
        var itemLabel = Belt_Option.mapWithDefault(item, Intl$Pos.t("Select a shop"), (function (item) {
                return item.label;
              }));
        var formattedLabel = truncateWithMiddleEllipsis(itemLabel, 17);
        var tmp;
        var exit = 0;
        if (item !== undefined && item.value === undefined) {
          tmp = React.createElement(InlineText$Pos.make, {
                children: null
              }, React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.t("Shop") + Intl$Pos.t(":") + " ",
                    variation: "normal"
                  }), React.createElement(TextStyle$Pos.make, {
                    children: itemLabel
                  }));
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp = React.createElement(TextStyle$Pos.make, {
                children: formattedLabel
              });
        }
        var tmp$1 = {
          children: tmp,
          preset: preset,
          hovered: hovered,
          active: active,
          focused: focused
        };
        if (label !== undefined) {
          tmp$1.label = label;
        }
        return React.createElement(OverlayTriggerView$Pos.make, tmp$1);
      }) : undefined;
  var tmp = {
    sections: sections,
    preset: preset,
    value: value$1,
    onChange: onChange$1
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  if (renderTriggerView !== undefined) {
    tmp.renderTriggerView = Caml_option.valFromOption(renderTriggerView);
  }
  return React.createElement(Select$Pos.make, tmp);
}

var SelectShopFilter = {
  make: Auth__Elements$SelectShopFilter
};

function Auth__Elements$SelectSingleShopFilter(Props) {
  var value = Props.value;
  var disabledIdsOpt = Props.disabledIds;
  var onChange = Props.onChange;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var dispatch = Auth__Hooks$Pos.useDispatch(undefined);
  var shops = Auth__Hooks$Pos.useShops(undefined);
  var onChange$1 = function (nextActiveShopId) {
    var nextActiveShop = Belt_Array.getBy(shops, (function (shop) {
            return shop.id === nextActiveShopId;
          }));
    if (nextActiveShop !== undefined) {
      Belt_Option.forEach(onChange, (function (fn) {
              Curry._1(fn, nextActiveShop);
            }));
    }
    Curry._1(dispatch, {
          TAG: /* ActiveShopSet */2,
          _0: nextActiveShop
        });
  };
  var value$1 = value.id;
  var shopItems = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name,
                  disabled: Belt_Array.some(disabledIds, (function (id) {
                          return id === shop.id;
                        }))
                };
        }));
  var sections = [{
      items: shopItems,
      title: Intl$Pos.t("Shops")
    }];
  return React.createElement(Select$Pos.make, {
              label: Intl$Pos.t("Shop"),
              sections: sections,
              preset: "filter",
              value: value$1,
              onChange: onChange$1
            });
}

var SelectSingleShopFilter = {
  make: Auth__Elements$SelectSingleShopFilter
};

function Auth__Elements$InputSelectSingleShopField(Props) {
  var value = Props.value;
  var required = Props.required;
  var disabledIdsOpt = Props.disabledIds;
  var onChange = Props.onChange;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var dispatch = Auth__Hooks$Pos.useDispatch(undefined);
  var shops = Auth__Hooks$Pos.useShops(undefined);
  var onChange$1 = function (nextActiveShopId) {
    var nextActiveShop = Belt_Array.getBy(shops, (function (shop) {
            return shop.id === nextActiveShopId;
          }));
    if (nextActiveShop !== undefined) {
      Belt_Option.forEach(onChange, (function (fn) {
              Curry._1(fn, nextActiveShop);
            }));
    }
    Curry._1(dispatch, {
          TAG: /* ActiveShopSet */2,
          _0: nextActiveShop
        });
  };
  var value$1 = value.id;
  var shopItems = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name,
                  disabled: Belt_Array.some(disabledIds, (function (id) {
                          return id === shop.id;
                        }))
                };
        }));
  var sections = [{
      items: shopItems,
      title: Intl$Pos.t("Shops")
    }];
  return React.createElement(Select$Pos.make, {
              label: Intl$Pos.t("Shop"),
              sections: sections,
              preset: {
                NAME: "inputField",
                VAL: {
                  required: required
                }
              },
              value: value$1,
              onChange: onChange$1
            });
}

var InputSelectSingleShopField = {
  make: Auth__Elements$InputSelectSingleShopField
};

var useDispatch = Auth__Hooks$Pos.useDispatch;

var useScope = Auth__Hooks$Pos.useScope;

var useShops = Auth__Hooks$Pos.useShops;

var useActiveShop = Auth__Hooks$Pos.useActiveShop;

export {
  useDispatch ,
  useScope ,
  useShops ,
  useActiveShop ,
  truncateWithMiddleEllipsis ,
  SelectShopFilter ,
  SelectSingleShopFilter ,
  InputSelectSingleShopField ,
}
/* react Not a pure module */
