// Generated by ReScript, PLEASE EDIT WITH CARE


function get(param) {
  return window.navigator.userAgent;
}

export {
  get ,
}
/* No side effect */
