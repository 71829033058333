// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as CatalogRoutes$Pos from "./CatalogRoutes.bs.js";
import * as CatalogListPage$Pos from "./CatalogListPage.bs.js";
import * as Notifier__Context$Pos from "../../bundles/Notifier/Notifier__Context.bs.js";
import * as CatalogLabelCreatePage$Pos from "./CatalogLabelCreatePage.bs.js";
import * as CatalogVariantByIdPage$Pos from "./CatalogVariantByIdPage.bs.js";
import * as CatalogProductByCkuPage$Pos from "./CatalogProductByCkuPage.bs.js";
import * as CatalogProductContainer$Pos from "./CatalogProductContainer.bs.js";
import * as CatalogVariantContainer$Pos from "./CatalogVariantContainer.bs.js";
import * as CatalogInventoryImportPage$Pos from "./CatalogInventoryImportPage.bs.js";
import * as CatalogDuplicationContainer$Pos from "./CatalogDuplicationContainer.bs.js";
import * as CatalogProductEditContainer$Pos from "./CatalogProductEditContainer.bs.js";
import * as CatalogVariantEditContainer$Pos from "./CatalogVariantEditContainer.bs.js";
import * as CatalogVariantOrderSupplyPage$Pos from "./CatalogVariantOrderSupplyPage.bs.js";
import * as CatalogVariantStockActivityPage$Pos from "./CatalogVariantStockActivityPage.bs.js";

function CatalogRouter(Props) {
  var match = Navigation$Pos.useUrl(undefined);
  var query = match.query;
  var match$1 = Auth$Pos.useState(undefined);
  var cavavinUserPermitted;
  if (typeof match$1 === "number" || match$1.TAG === /* Logging */0) {
    cavavinUserPermitted = false;
  } else {
    var match$2 = match$1._0;
    cavavinUserPermitted = Auth$Pos.hasCavavinPermission(match$2.user.username, match$2.shops);
  }
  var catalogLabelQueryStringRef = React.useRef(CatalogRoutes$Pos.CatalogLabelQueryString.decode(query));
  var match$3 = catalogLabelQueryStringRef.current;
  var fromPathnameLabelEditSettings = match$3.fromPathname;
  var variantIdfromLabelEditSettings = match$3.variantIdfromLabelEditSettings;
  React.useEffect((function () {
          catalogLabelQueryStringRef.current = CatalogRoutes$Pos.CatalogLabelQueryString.decode(query);
        }), [match.pathname]);
  var match$4 = Belt_List.fromArray(match.path);
  var tmp;
  var exit = 0;
  if (match$4) {
    var exit$1 = 0;
    if (match$4.hd === "catalog") {
      var match$5 = match$4.tl;
      if (match$5) {
        var cku = match$5.hd;
        var exit$2 = 0;
        switch (cku) {
          case "create" :
              var match$6 = match$5.tl;
              if (match$6) {
                if (match$6.hd === "redirect") {
                  var match$7 = match$6.tl;
                  if (match$7 && !match$7.tl) {
                    tmp = React.createElement(CatalogProductByCkuPage$Pos.make, {
                          cku: match$7.hd,
                          redirectToCreateVariant: true
                        });
                  } else {
                    exit = 1;
                  }
                } else {
                  exit$2 = 3;
                }
              } else {
                tmp = React.createElement(CatalogVariantEditContainer$Pos.make, {
                      mode: /* Create */0
                    });
              }
              break;
          case "inventory" :
              var match$8 = match$5.tl;
              if (match$8 && match$8.hd === "import") {
                if (match$8.tl) {
                  exit = 1;
                } else {
                  tmp = React.createElement(CatalogInventoryImportPage$Pos.make, {});
                }
              } else {
                exit$2 = 3;
              }
              break;
          case "labels" :
              var match$9 = match$5.tl;
              if (match$9 && match$9.hd === "create") {
                var match$10 = match$9.tl;
                if (match$10) {
                  if (match$10.hd === "settings" && !match$10.tl) {
                    tmp = React.createElement(CatalogLabelCreatePage$Pos.make, {
                          settingsOnly: fromPathnameLabelEditSettings !== undefined && variantIdfromLabelEditSettings !== undefined ? ({
                                fromPathname: fromPathnameLabelEditSettings,
                                variantIdFromCatalogRedirection: variantIdfromLabelEditSettings
                              }) : undefined
                        });
                  } else {
                    exit = 1;
                  }
                } else {
                  tmp = React.createElement(CatalogLabelCreatePage$Pos.make, {});
                }
              } else {
                exit$2 = 3;
              }
              break;
          case "product" :
              var match$11 = match$5.tl;
              if (match$11) {
                var cku$1 = match$11.hd;
                var exit$3 = 0;
                if (cku$1 === "create" && !match$11.tl) {
                  tmp = React.createElement(CatalogProductEditContainer$Pos.make, {
                        mode: /* Create */0
                      });
                } else {
                  exit$3 = 4;
                }
                if (exit$3 === 4) {
                  var match$12 = match$11.tl;
                  if (match$12) {
                    if (match$12.hd === "edit") {
                      if (match$12.tl) {
                        exit = 1;
                      } else {
                        tmp = React.createElement(CatalogProductEditContainer$Pos.make, {
                              cku: cku$1,
                              mode: /* Edit */1
                            });
                      }
                    } else {
                      switch (cku$1) {
                        case "duplication" :
                            var match$13 = match$11.tl;
                            if (match$13.tl) {
                              exit = 1;
                            } else {
                              tmp = React.createElement(CatalogDuplicationContainer$Pos.make, {
                                    cku: match$13.hd,
                                    mode: /* Product */0
                                  });
                            }
                            break;
                        case "redirect" :
                            var match$14 = match$11.tl;
                            if (match$14.tl) {
                              exit = 1;
                            } else {
                              tmp = React.createElement(CatalogProductByCkuPage$Pos.make, {
                                    cku: match$14.hd
                                  });
                            }
                            break;
                        default:
                          exit$2 = 3;
                      }
                    }
                  } else {
                    tmp = React.createElement(CatalogProductContainer$Pos.make, {
                          cku: cku$1
                        });
                  }
                }
                
              } else {
                exit$2 = 3;
              }
              break;
          default:
            exit$2 = 3;
        }
        if (exit$2 === 3) {
          var match$15 = match$5.tl;
          if (match$15) {
            switch (match$15.hd) {
              case "create" :
                  if (match$15.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(CatalogVariantEditContainer$Pos.make, {
                          cku: cku,
                          mode: /* Create */0
                        });
                  }
                  break;
              case "edit" :
                  if (match$15.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(CatalogVariantEditContainer$Pos.make, {
                          cku: cku,
                          mode: /* Edit */1
                        });
                  }
                  break;
              default:
                if (cku === "redirect") {
                  var match$16 = match$5.tl;
                  if (match$16.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(CatalogVariantByIdPage$Pos.make, {
                          id: match$16.hd
                        });
                  }
                } else {
                  var match$17 = match$5.tl;
                  switch (match$17.hd) {
                    case "ordersupplies" :
                        if (match$17.tl) {
                          exit = 1;
                        } else {
                          tmp = React.createElement(CatalogVariantOrderSupplyPage$Pos.make, {
                                cku: cku
                              });
                        }
                        break;
                    case "stockactivities" :
                        if (match$17.tl) {
                          exit = 1;
                        } else {
                          tmp = React.createElement(CatalogVariantStockActivityPage$Pos.make, {
                                cku: cku
                              });
                        }
                        break;
                    default:
                      if (cku === "duplication") {
                        var match$18 = match$5.tl;
                        if (match$18.tl) {
                          exit = 1;
                        } else {
                          tmp = React.createElement(CatalogDuplicationContainer$Pos.make, {
                                cku: match$18.hd,
                                mode: /* Variant */1
                              });
                        }
                      } else {
                        exit = 1;
                      }
                  }
                }
            }
          } else {
            tmp = React.createElement(CatalogVariantContainer$Pos.make, {
                  cku: cku,
                  fromLabelEditSettingsRedirected: Belt_Option.isSome(fromPathnameLabelEditSettings)
                });
          }
        }
        
      } else {
        exit$1 = 2;
      }
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (match$4.tl) {
        exit = 1;
      } else {
        tmp = React.createElement(CatalogListPage$Pos.make, {
              cavavinUserPermitted: cavavinUserPermitted,
              variantIdfromLabelEditSettings: variantIdfromLabelEditSettings
            });
      }
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Navigation$Pos.Redirect.make, {
          route: CatalogRoutes$Pos.baseRoute
        });
  }
  return React.createElement(Notifier__Context$Pos.Provider.make, Notifier__Context$Pos.Provider.makeProps(Notifier$Pos.createContext(undefined), tmp, undefined));
}

var make = CatalogRouter;

export {
  make ,
}
/* react Not a pure module */
