// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as IpAddress$Pos from "../../primitives/IpAddress.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as BillingAccount$Pos from "../../modules/Billing/BillingAccount.bs.js";
import * as SettingsRoutes$Pos from "./SettingsRoutes.bs.js";
import * as SettingsUserPage$Pos from "./SettingsUserPage.bs.js";
import * as SettingsShopsPage$Pos from "./SettingsShopsPage.bs.js";
import * as SettingsLabelPrinterPage$Pos from "./SettingsLabelPrinterPage.bs.js";
import * as SettingsBillingAccountEditPage$Pos from "./SettingsBillingAccountEditPage.bs.js";
import * as SettingsBillingAccountShowPage$Pos from "./SettingsBillingAccountShowPage.bs.js";
import * as SettingsBillingAccountListInvoicesPage$Pos from "./SettingsBillingAccountListInvoicesPage.bs.js";

function SettingsRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var appIndexRoute = Props.appIndexRoute;
  var reloadAlertBar = Props.reloadAlertBar;
  var url = Navigation$Pos.useUrl(undefined);
  var scope = Auth$Pos.useScope(undefined);
  var shop;
  if (scope.TAG === /* Organisation */0) {
    var shop$1 = scope.activeShop;
    shop = shop$1 !== undefined ? shop$1 : scope.shops[0];
  } else {
    shop = scope._0;
  }
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "billing-account" :
          var match = subUrlPath.tl;
          if (!match) {
            return React.createElement(Navigation$Pos.Redirect.make, {
                        route: SettingsRoutes$Pos.billingAccountShowRoute(shop.id, undefined)
                      });
          }
          var shopId = match.hd;
          var exit = 0;
          switch (shopId) {
            case "edit" :
                if (match.tl) {
                  exit = 2;
                } else {
                  var decodeUrlResult = SettingsRoutes$Pos.decodeEditBillingAccountQueryString(url.query);
                  if (decodeUrlResult.TAG !== /* Ok */0) {
                    return React.createElement(Navigation$Pos.Redirect.make, {
                                route: appIndexRoute
                              });
                  }
                  var match$1 = decodeUrlResult._0;
                  return React.createElement(SettingsBillingAccountEditPage$Pos.make, {
                              activeShopId: match$1.activeShopId,
                              corporateName: match$1.corporateName,
                              shopName: match$1.shopName,
                              email: match$1.email,
                              phone: match$1.phone,
                              vatNumber: match$1.vatNumber,
                              billingAddress: match$1.billingAddress,
                              shippingAddress: match$1.shippingAddress,
                              requestBillingAccountUpdate: SettingsBillingAccountEditPage$Pos.requestBillingAccountUpdate
                            });
                }
                break;
            case "invoices" :
                if (!match.tl) {
                  return React.createElement(SettingsBillingAccountListInvoicesPage$Pos.make, {
                              invoicesRequest: BillingAccount$Pos.invoicesRequest
                            });
                }
                exit = 2;
                break;
            case "payment-failed" :
                if (!match.tl) {
                  return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                              shopId: shop.id,
                              confirmSepaModalOpened: false,
                              editPaymentMethodModalOpened: false,
                              billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                              billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                              sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                              updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                              invoicesRequest: BillingAccount$Pos.invoicesRequest,
                              subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                              confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                              ipAddressRequest: IpAddress$Pos.request,
                              reloadAlertBar: reloadAlertBar,
                              notification: {
                                TAG: /* Danger */1,
                                _0: Intl$Pos.t("We were unable to complete your payment due to a technical error. Please try again or contact us for assistance.")
                              }
                            });
                }
                exit = 2;
                break;
            case "payment-succeeded" :
                if (!match.tl) {
                  return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                              shopId: shop.id,
                              confirmSepaModalOpened: false,
                              editPaymentMethodModalOpened: false,
                              billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                              billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                              sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                              updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                              invoicesRequest: BillingAccount$Pos.invoicesRequest,
                              subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                              confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                              ipAddressRequest: IpAddress$Pos.request,
                              reloadAlertBar: reloadAlertBar,
                              notification: {
                                TAG: /* Success */0,
                                _0: Intl$Pos.t("Your payment has been successfully processed. You can now fully enjoy our services.")
                              }
                            });
                }
                exit = 2;
                break;
            default:
              exit = 2;
          }
          if (exit === 2) {
            var match$2 = match.tl;
            if (!match$2) {
              return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                          shopId: shopId,
                          confirmSepaModalOpened: false,
                          editPaymentMethodModalOpened: false,
                          billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                          billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                          sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                          updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                          invoicesRequest: BillingAccount$Pos.invoicesRequest,
                          subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                          confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                          ipAddressRequest: IpAddress$Pos.request,
                          reloadAlertBar: reloadAlertBar
                        });
            }
            if (!match$2.tl) {
              var modal = SettingsRoutes$Pos.BillingAccountShowPageModal.fromString(match$2.hd);
              if (modal !== undefined) {
                return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                            shopId: shopId,
                            confirmSepaModalOpened: SettingsRoutes$Pos.BillingAccountShowPageModal.isConfirmMandate(modal),
                            editPaymentMethodModalOpened: SettingsRoutes$Pos.BillingAccountShowPageModal.isEditPaymentMethod(modal),
                            billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                            billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                            sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                            updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                            invoicesRequest: BillingAccount$Pos.invoicesRequest,
                            subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                            confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                            ipAddressRequest: IpAddress$Pos.request,
                            reloadAlertBar: reloadAlertBar
                          });
              } else {
                return React.createElement(Navigation$Pos.Redirect.make, {
                            route: SettingsRoutes$Pos.billingAccountShowRoute(shopId, undefined)
                          });
              }
            }
            
          }
          break;
      case "label-printer" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsLabelPrinterPage$Pos.make, {});
          }
          break;
      case "shops" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsShopsPage$Pos.make, {
                        updateShopRequest: SettingsShopsPage$Pos.UpdateShopRequest.make
                      });
          }
          break;
      case "user" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsUserPage$Pos.make, {
                        updateEmailRequest: SettingsUserPage$Pos.updateEmailRequest,
                        updatePasswordRequest: SettingsUserPage$Pos.updatePasswordRequest,
                        updateUserNamesRequest: SettingsUserPage$Pos.updateUserNamesRequest
                      });
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: appIndexRoute
            });
}

var make = SettingsRouter;

export {
  make ,
}
/* react Not a pure module */
