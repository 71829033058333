// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as SupplierRouter$Pos from "../../app/Supplier/SupplierRouter.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query SuppliersQuery($filterBy: InputSuppliersQueryFilter, $after: String, $first: Int)  {\nsuppliers(filterBy: $filterBy, after: $after, first: $first)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncompanyName  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.suppliers;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          suppliers: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              companyName: value$1.companyName
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.suppliers;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.companyName;
          var value$3 = value$1.id;
          var value$4 = value$1.__typename;
          var node = {
            __typename: value$4,
            id: value$3,
            companyName: value$2
          };
          var value$5 = value.__typename;
          return {
                  __typename: value$5,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var suppliers = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          suppliers: suppliers
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputSuppliersQueryFilter(inp) {
  var a = inp.archived;
  var a$1 = inp.shopIds;
  return {
          archived: a !== undefined ? (
              a === "ONLY" ? "ONLY" : (
                  a === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          shopIds: a$1 !== undefined ? serializeInputObjectInFilter(a$1) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  var a$1 = inp.after;
  var a$2 = inp.first;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputSuppliersQueryFilter(a) : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          first: a$2 !== undefined ? a$2 : undefined
        };
}

function makeVariables(filterBy, after, first, param) {
  return {
          filterBy: filterBy,
          after: after,
          first: first
        };
}

function makeInputObjectInputSuppliersQueryFilter(archived, shopIds, param) {
  return {
          archived: archived,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined,
          after: undefined,
          first: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputSuppliersQueryFilter: serializeInputObjectInputSuppliersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputSuppliersQueryFilter: makeInputObjectInputSuppliersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputSuppliersQueryFilter: serializeInputObjectInputSuppliersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputSuppliersQueryFilter: makeInputObjectInputSuppliersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function paginateSuppliersQuery(query, cursor, dataOpt, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  return Future.flatMapOk(Future.mapError(FuturePromise.fromPromise(Curry._1(query, cursor)), undefined, (function (param) {
                    
                  })), undefined, (function (response) {
                if (response.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                }
                var match = response._0;
                var match$1 = match.data.suppliers;
                var match$2 = match$1.pageInfo;
                var match$3 = match$2.hasNextPage;
                if (match$3 !== undefined && match$3) {
                  if (match.error !== undefined) {
                    return Future.value({
                                TAG: /* Error */1,
                                _0: undefined
                              });
                  } else {
                    return paginateSuppliersQuery(query, match$2.endCursor, Belt_Array.concat(data, match$1.edges), undefined);
                  }
                }
                if (match.error !== undefined) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                } else {
                  return Future.value({
                              TAG: /* Ok */0,
                              _0: Belt_Array.concat(data, match$1.edges)
                            });
                }
              }));
}

function useFetchSuppliers(shopId, skip) {
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var setSuppliers = match[1];
  var query$1 = function (after) {
    return Curry._6(apolloClient.rescript_query, {
                query: query,
                Raw: Raw,
                parse: parse,
                serialize: serialize,
                serializeVariables: serializeVariables
              }, undefined, undefined, /* NetworkOnly */2, undefined, {
                filterBy: {
                  archived: "EXCLUDED",
                  shopIds: shopId !== undefined ? ({
                        _in: [shopId]
                      }) : undefined
                },
                after: after,
                first: 50
              });
  };
  var mapResultAndSetSuppliers = function (result) {
    Future.tapOk(Future.mapOk(result, undefined, (function (result) {
                return Belt_Array.map(result, (function (edge) {
                              return {
                                      id: edge.node.id,
                                      name: edge.node.companyName
                                    };
                            }));
              })), (function (suppliers) {
            Curry._1(setSuppliers, (function (param) {
                    return {
                            TAG: /* Done */1,
                            _0: suppliers
                          };
                  }));
          }));
  };
  React.useEffect((function () {
          Curry._1(setSuppliers, (function (param) {
                  return /* Loading */1;
                }));
          if (Belt_Option.isSome(shopId)) {
            mapResultAndSetSuppliers(paginateSuppliersQuery(query$1, undefined, undefined, undefined));
          }
          
        }), []);
  React.useEffect((function () {
          if (!skip && Belt_Option.isSome(shopId)) {
            Curry._1(setSuppliers, (function (value) {
                    if (typeof value === "number" || value.TAG !== /* Done */1) {
                      return /* Loading */1;
                    } else {
                      return {
                              TAG: /* Reloading */0,
                              _0: value._0
                            };
                    }
                  }));
            mapResultAndSetSuppliers(paginateSuppliersQuery(query$1, undefined, undefined, undefined));
          }
          
        }), [
        skip,
        shopId
      ]);
  return match[0];
}

function SupplierSelect(Props) {
  var size = Props.size;
  var label = Props.label;
  var preset = Props.preset;
  var defaultOpen = Props.defaultOpen;
  var showDefaultItemOpt = Props.showDefaultItem;
  var hideOverlayFooterOpt = Props.hideOverlayFooter;
  var shopId = Props.shopId;
  var value = Props.value;
  var onChange = Props.onChange;
  var onDismiss = Props.onDismiss;
  var showDefaultItem = showDefaultItemOpt !== undefined ? showDefaultItemOpt : false;
  var hideOverlayFooter = hideOverlayFooterOpt !== undefined ? hideOverlayFooterOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setOpened = match[1];
  var suppliersResult = useFetchSuppliers(shopId, !match[0]);
  var items = typeof suppliersResult === "number" ? Belt_Option.mapWithDefault(value, [], (function (supplier) {
            return [{
                      key: supplier.id,
                      value: supplier,
                      label: supplier.name
                    }];
          })) : Belt_Array.map(suppliersResult._0, (function (supplier) {
            return {
                    key: supplier.id,
                    value: supplier,
                    label: supplier.name
                  };
          }));
  var defaultItem_label = Intl$Pos.t("All");
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var sections = showDefaultItem ? [
      {
        items: [defaultItem]
      },
      {
        items: items,
        title: Intl$Pos.t("Suppliers")
      }
    ] : [{
        items: items,
        title: Intl$Pos.t("Suppliers")
      }];
  var loading = suppliersResult === /* Loading */1;
  React.useEffect((function () {
          if (value !== undefined && typeof suppliersResult !== "number" && suppliersResult.TAG === /* Done */1) {
            if (Belt_Array.every(suppliersResult._0, (function (current) {
                      return current.id !== value.id;
                    }))) {
              Curry._1(onChange, undefined);
            }
            
          }
          
        }), [suppliersResult]);
  var onToggle = React.useCallback((function (opened) {
          Curry._1(setOpened, (function (param) {
                  return opened;
                }));
          if (opened || onDismiss === undefined) {
            return ;
          } else {
            return Curry._1(onDismiss, undefined);
          }
        }), []);
  var footerLink = !hideOverlayFooter ? ({
        text: Intl$Pos.t("Create supplier"),
        route: SupplierRouter$Pos.newRoute
      }) : undefined;
  var tmp = {
    placeholder: Intl$Pos.t("Select a supplier"),
    disabled: Belt_Option.isNone(shopId),
    loading: loading,
    sections: sections,
    preset: preset,
    value: value,
    onChange: onChange,
    onToggle: onToggle
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  if (defaultOpen !== undefined) {
    tmp.defaultOpen = defaultOpen;
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  if (footerLink !== undefined) {
    tmp.footerLink = Caml_option.valFromOption(footerLink);
  }
  return React.createElement(Tooltip$Pos.make, {
              children: React.createElement(Select$Pos.make, tmp),
              arrowed: false,
              placement: "bottom start",
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Please select a shop beforehand.")
              },
              disabled: Belt_Option.isSome(shopId)
            });
}

var make = React.memo(SupplierSelect);

export {
  Query ,
  paginateSuppliersQuery ,
  useFetchSuppliers ,
  make ,
}
/* query Not a pure module */
