// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Future from "rescript-future/src/Future.bs.js";
import * as Env$Pos from "../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Json$Pos from "../primitives/Json.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../core/Request.bs.js";
import * as FiscalYearOpeningMonth$Pos from "./FiscalYearOpeningMonth.bs.js";

function fillWithStandard(taxRate, taxId) {
  if (taxRate < 5.5) {
    if (taxRate === 0) {
      return {
              taxId: taxId,
              isaComptaCode: "Y",
              productsSold: [
                "70700000",
                "Ventes marchandises TVA 0%"
              ],
              deductibleTax: undefined
            };
    }
    if (taxRate === 2.1) {
      return {
              taxId: taxId,
              isaComptaCode: "V3",
              productsSold: [
                "70780000",
                "Ventes marchandises TVA 2.1%"
              ],
              deductibleTax: [
                "4457180000",
                "TVA collectée 2.1%"
              ]
            };
    }
    
  } else {
    if (taxRate === 5.5) {
      return {
              taxId: taxId,
              isaComptaCode: "V2",
              productsSold: [
                "70700000",
                "Ventes marchandises TVA 5.5%"
              ],
              deductibleTax: [
                "4457100000",
                "TVA collectée 5.5%"
              ]
            };
    }
    if (taxRate === 10) {
      return {
              taxId: taxId,
              isaComptaCode: "V6",
              productsSold: [
                "70730100",
                "Ventes marchandises TVA 10%"
              ],
              deductibleTax: [
                "4457130100",
                "TVA collectée 10%"
              ]
            };
    }
    if (taxRate === 20) {
      return {
              taxId: taxId,
              isaComptaCode: "V5",
              productsSold: [
                "70720100",
                "Ventes marchandises TVA 20%"
              ],
              deductibleTax: [
                "4457120100",
                "TVA collectée 20%"
              ]
            };
    }
    
  }
  return {
          taxId: taxId,
          isaComptaCode: undefined,
          productsSold: [
            "",
            ""
          ],
          deductibleTax: [
            "",
            ""
          ]
        };
}

function decodeFromJson(json) {
  var dict = Json$Pos.decodeDict(json);
  if (dict === undefined) {
    return ;
  }
  var dict$1 = Caml_option.valFromOption(dict);
  var taxId = Belt_Option.flatMap(Js_dict.get(dict$1, "taxId"), Json$Pos.decodeString);
  var isaComptaCode = Belt_Option.flatMap(Js_dict.get(dict$1, "isaComptaCode"), Json$Pos.decodeString);
  var productsSold = Belt_Option.flatMap(Belt_Option.flatMap(Js_dict.get(dict$1, "productsSold"), Json$Pos.decodeDict), (function (value) {
          var accountNumber = Belt_Option.flatMap(Js_dict.get(value, "accountNumber"), Json$Pos.decodeString);
          var label = Belt_Option.flatMap(Js_dict.get(value, "label"), Json$Pos.decodeString);
          if (accountNumber !== undefined && label !== undefined) {
            return [
                    accountNumber,
                    label
                  ];
          }
          
        }));
  var deductibleTax = Belt_Option.flatMap(Belt_Option.flatMap(Js_dict.get(dict$1, "deductibleTax"), Json$Pos.decodeDict), (function (value) {
          var accountNumber = Belt_Option.flatMap(Js_dict.get(value, "accountNumber"), Json$Pos.decodeString);
          var label = Belt_Option.flatMap(Js_dict.get(value, "label"), Json$Pos.decodeString);
          if (accountNumber !== undefined && label !== undefined) {
            return [
                    accountNumber,
                    label
                  ];
          }
          
        }));
  if (taxId !== undefined && productsSold !== undefined) {
    return {
            taxId: taxId,
            isaComptaCode: isaComptaCode,
            productsSold: productsSold,
            deductibleTax: deductibleTax
          };
  }
  
}

function encodeToJson(param) {
  var deductibleTax = param.deductibleTax;
  var productsSold = param.productsSold;
  var isaComptaCode = param.isaComptaCode;
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "taxId",
                    Json$Pos.encodeString(param.taxId)
                  ],
                  [
                    "isaComptaCode",
                    isaComptaCode !== undefined ? Json$Pos.encodeString(isaComptaCode) : Json$Pos.encodedNull
                  ],
                  [
                    "productsSold",
                    Json$Pos.encodeDict(Js_dict.fromArray([
                              [
                                "accountNumber",
                                Json$Pos.encodeString(productsSold[0])
                              ],
                              [
                                "label",
                                Json$Pos.encodeString(productsSold[1])
                              ]
                            ]))
                  ],
                  [
                    "deductibleTax",
                    deductibleTax !== undefined ? Json$Pos.encodeDict(Js_dict.fromArray([
                                [
                                  "accountNumber",
                                  Json$Pos.encodeString(deductibleTax[0])
                                ],
                                [
                                  "label",
                                  Json$Pos.encodeString(deductibleTax[1])
                                ]
                              ])) : Json$Pos.encodedNull
                  ]
                ]));
}

var TaxAccount = {
  fillWithStandard: fillWithStandard,
  decodeFromJson: decodeFromJson,
  encodeToJson: encodeToJson
};

function endpoint(shopId) {
  return Env$Pos.gatewayUrl(undefined) + "/accounting-export-configurations/" + shopId;
}

function decodeInvalidRequestFailure(error) {
  if (error.kind === "NotFoundAccountingExportConfiguration") {
    return /* NotFoundAccountingExportConfigurationFailure */0;
  } else {
    return /* UnknownServerFailure */1;
  }
}

function decodeRequestError(requestError) {
  var tmp;
  if (typeof requestError === "number" || requestError.TAG !== /* InvalidRequestFailures */2) {
    tmp = {
      TAG: /* Error */1,
      _0: undefined
    };
  } else {
    var firstFailure = Belt_Option.map(Belt_Array.get(requestError._0, 0), decodeInvalidRequestFailure);
    tmp = firstFailure !== undefined && !firstFailure ? ({
          TAG: /* Ok */0,
          _0: undefined
        }) : ({
          TAG: /* Error */1,
          _0: undefined
        });
  }
  return Future.value(tmp);
}

function decodeResponse(response) {
  return Belt_Option.flatMap(Json$Pos.decodeDict(response), (function (dict) {
                var isaComptaAccountNumber = Json$Pos.decodeDictFieldString(dict, "isaComptaAccountNumber");
                var fiscalYearOpeningMonth = Belt_Option.flatMap(Js_dict.get(dict, "fiscalYearOpeningMonth"), FiscalYearOpeningMonth$Pos.decodeFromJson);
                var taxesAccounts = Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, "accountingExportTaxAccounts"), Json$Pos.decodeArray), (function (value) {
                        return Belt_Array.keepMap(value, decodeFromJson);
                      }));
                var shopId = Json$Pos.decodeDictFieldString(dict, "shopId");
                var breakdownOfConsumerSalesByCashRegisterDailyReport = Json$Pos.decodeDictFieldBoolean(dict, "breakdownOfConsumerSalesByCashRegisterDailyReport");
                if (shopId !== undefined && fiscalYearOpeningMonth !== undefined && taxesAccounts !== undefined && breakdownOfConsumerSalesByCashRegisterDailyReport !== undefined && taxesAccounts.length !== 0) {
                  return {
                          shopId: shopId,
                          isaComptaAccountNumber: isaComptaAccountNumber,
                          fiscalYearOpeningMonth: fiscalYearOpeningMonth,
                          taxesAccounts: taxesAccounts,
                          breakdownOfConsumerSalesByCashRegisterDailyReport: breakdownOfConsumerSalesByCashRegisterDailyReport
                        };
                }
                
              }));
}

function request(shopId) {
  return Future.flatMapError(Future.mapOk(Request$Pos.make(undefined, undefined, undefined, undefined, endpoint(shopId)), undefined, decodeResponse), undefined, decodeRequestError);
}

export {
  TaxAccount ,
  endpoint ,
  decodeInvalidRequestFailure ,
  decodeRequestError ,
  decodeResponse ,
  request ,
}
/* Json-Pos Not a pure module */
